@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

$header-height: $unit * 6;

.header {
  position: relative;
}

.body {
  flex-grow: 1;
  z-index: 1;
}

.bodyInner {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.background {
  @include content-background;

  display: flex;
  flex-direction: column;
  height: 100%;

  &.sidebarOpen {
    overflow: hidden;

    .bodyInner {
      height: calc(100vh - #{$header-height});
    }

    .header {
      z-index: 0;
    }
  }

  &:not(.sidebarOpen) {
    .header {
      z-index: $depth-dialogs + 1;
    }

    .body {
      position: relative;
    }

    .bodyInner {
      position: absolute;
    }

    @media (max-width: $breakpoint-tablet) {
      .header {
        z-index: 2;
      }
    }
  }
}
