@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: auto;

  @media screen and (min-width: $breakpoint-mobile) {
    min-width: $unit * 45;
    width: 100%;
  }

  @media screen and (min-width: $breakpoint-web) {
    width: 100%;
  }
}

@include dark-theme {
  .container {
    @include content-background;
  }
}

.branchDescription {
  @include dynamic-padding(top right left);
}

.content {
  max-width: 776px;
  width: 100%;
  margin: 0 auto;
}
