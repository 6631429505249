@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.copy {
  margin: 0 0 $unit * 2;
}

.prompt {
  font-weight: $font-medium;
}

.reason {
  margin-bottom: $unit * 1.5;
}

.checkbox {
  align-self: center;
  margin: 0;
}

.checkboxLabel {
  font-weight: $font-regular;
  margin-left: $unit * 1.5;
  padding: 0;
}

.designTool {
  display: inline-block;
  min-width: $unit * 24;
  margin-left: $unit * 3;
}

.waitlistWrapper {
  @include secondary-content-background;

  padding: $unit * 2;
  border-radius: $unit * 0.5;
  margin-left: $unit;
  margin-bottom: $unit * 2;
}

.waitlistEmail {
  margin-top: $unit * 1.5;
}

.supportCopy {
  margin-top: $unit * 3;
}

.error {
  @include font-s;
  @include error-text-color;
}
