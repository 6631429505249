@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";

.headingTitle {
  @include brand-onboarding-heading;

  margin: 0;
  margin-bottom: 10px;
}

.heading {
  text-align: center;
  margin-bottom: 10px;

  img {
    -webkit-user-drag: none;
  }
}

.hideLogo {
  img {
    display: block;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    img {
      display: none;
    }
  }
}
