@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/theme";
@import "../../shared/mixins";

.toast {
  @include font-m;

  display: flex;
  flex-direction: column;
  position: relative;
  color: $white;
  font-weight: $font-medium;
  background: $gray-70;
  border-radius: 6px;
  overflow: hidden;
  padding: $unit * 1.5 $unit * 2;
  min-width: 200px;
  min-height: $unit * 6;
  max-width: 50vw;
  max-height: 50vh;
}

.text {
  padding: 0 $unit * 0.5;

  a {
    @include link-inverse;

    text-decoration: underline;
    margin-left: $unit * 1.5;
  }
}

.subtext {
  @include font-s;

  color: $gray-30;
  margin: 0;
}

.separator {
  height: $unit * 3;
  margin: 0 $unit * 1.5;
  border-left: 1px solid $white-a15;
}

.icon {
  width: $unit * 3;
  height: $unit * 3;
  margin-left: -$unit * 0.5;
}

.progress {
  margin-top: $unit * 1.5;
  width: 100%;
}

.progress[value] {
  &::-webkit-progress-bar {
    background: $gray-80;
  }
}

@include dark-theme {
  .progress[value] {
    &::-webkit-progress-bar {
      background: $gray-20;
    }
  }

  .subtext {
    color: $gray-60;
  }
}

/* these rules are required instead of using eg Button.tint because toasts
   are displayed on an inverted background (dark in light mode, light in dark). */
.close,
.action {
  color: $blurple-45;
  box-shadow: none !important;
  background: transparent !important;
  margin: 0 (-$unit * 0.5);

  &:hover {
    color: $blurple-40 !important;
  }

  &:active {
    color: $blurple-30 !important;
  }

  svg {
    fill: $blurple-45;
  }
}

@include dark-theme {
  .toast {
    color: $gray-70;
    background: $white;
  }

  .separator {
    border-left-color: $black-a10;
  }

  .close,
  .action {
    color: $blurple-60 !important;
    background: transparent;

    &:hover {
      color: $blurple-70 !important;
    }

    svg {
      fill: $blurple-60;
    }
  }
}
