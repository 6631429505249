@import "../shared/vars";
@import "../shared/mixins";

.container {
  border-radius: $unit * 0.75;
  overflow: hidden;
  padding: $unit * 0.5 0;
  width: max-content;
}

.colorSwatchContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}

.icon {
  height: $unit * 2;
  width: $unit * 2;
  flex: 1;
  display: flex;
  transform: rotate(90deg);
  margin-left: 1px;
}

.item {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text {
  margin-left: $unit * 0.75;
}
