@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

.loadedContainer,
.scrollable {
  @include content-background;
}

.scrollable {
  flex-grow: 1;
}

.wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  &:not(.mobile) {
    .scrollable {
      @include scrollable;

      padding-top: $vpadding;
      padding-bottom: 10vh;
    }
  }
}

.list {
  @include dynamic-padding(top left right bottom);

  width: 100%;
  overflow: initial;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 32px;
    margin: 0 auto;
  }
}

.listLarge {
  max-width: 776px + ($unit * 16);
}

.centerSmallLoading {
  flex-direction: row;
}

.loadingContainer {
  min-height: $unit * 3;
}

.isReloading {
  padding-bottom: 0;
}
