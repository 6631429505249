@import "../shared/vars";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";

.description {
  @include font-s;
  @include ellipsis;
  @include secondary-text-color;

  text-align: right;
  margin-left: $unit * 1.5;

  .parentName {
    text-decoration: underline;

    @include secondary-text-color;

    &:hover {
      color: darken($gray-60, 10%);
    }
  }

  span {
    display: inline;
  }
}

@include dark-theme {
  .parentName {
    &:hover {
      color: lighten($gray-30, 10%);
    }
  }
}
