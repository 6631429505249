@import "../../components/shared/colors";
@import "../../components/shared/vars";
@import "../../components/shared/typography";
@import "../../components/shared/theme";

.key {
  @include font-xs;

  color: $gray-60;
  font-weight: $font-medium;
  line-height: $unit * 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $unit * 3;
  min-width: $unit * 3;
  margin-right: $unit;
  padding: 0 $unit;
  background: linear-gradient($gray-10, $white);
  border-radius: 3px;
  box-shadow:
    inset 0 1px $white,
    0 0 0 1px $gray-20,
    0 1px 0 1px $gray-30;

  &:last-of-type {
    margin-right: 0;
  }
}

.keyIcon {
  width: $unit * 1.5;
}

@include dark-theme {
  .key {
    color: $gray-40;
    background: linear-gradient(darken($gray-70, 10%), $gray-70);
    box-shadow:
      inset 0 1px $gray-70,
      0 0 0 1px $black,
      0 1px 0 1px $black;
  }
}
