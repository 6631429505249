@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.item {
  @include content-background;
  @include inner-keyboard-focus;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  outline: none;
  margin: $unit 0;
}

.clickable {
  &:hover {
    cursor: pointer;

    .name {
      text-decoration: underline;
    }
  }
}

.name {
  font-weight: $font-medium;
  margin-right: $unit;
}

.center {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  width: 0;
  align-self: flex-start;
  flex-direction: column;
  margin-left: $unit;
}

.username,
.joinedOn,
.meta {
  @include font-s;
  @include ellipsis;
  @include secondary-text-color;
}

.meta {
  display: none;
}

.responsiveItem {
  overflow: hidden;

  .center {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: $unit;
    overflow: hidden;
  }

  .nameWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .meta {
    display: inline;
  }

  .joinedOn,
  .username {
    display: none;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    .center {
      align-self: center;
    }

    .username {
      order: 2;
      width: 100%;
      display: inline;
    }

    .nameWrap {
      width: auto;
    }

    .joinedOn {
      display: inline;
      text-align: right;
      margin-right: $unit * 2;
      margin-bottom: -$unit * 2;
      flex-grow: 1;
    }

    .meta {
      display: none;
    }
  }
}
