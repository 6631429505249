@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.controlsHelperText {
  @include secondary-text-color;

  text-align: center;
  margin-top: $unit * 1.5;
}

// Form styles

.message {
  font-size: 16px;
  margin: ($unit * 2) 0;
  text-align: center;
}

.email {
  margin-bottom: $unit * 3;
}

.formError {
  text-align: center;
}

.footer {
  @include secondary-text-color;

  margin-top: $unit * 2;
  text-align: center;
}
