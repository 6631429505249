@import "../shared/vars";
@import "../shared/mixins";

.flyover {
  box-shadow: none !important; // To override Flyover's dark theme box-shadow
  width: $unit * 25;

  &::after {
    display: none;
  }
}

.flyoverTopOffset {
  top: $unit * 4.5;
}

.wrapper {
  display: inline-flex;
}

.body {
  background-color: $blurple-60;
  border-radius: 9px;
  width: 100%;
  padding: $unit $unit * 1.5;
  position: relative;
  text-align: left;
}

.text {
  @include font-s;

  color: $white;
  margin-bottom: $unit * 0.5;
}

.toggle {
  display: inline-flex;
}

.caret {
  position: absolute;
  top: $unit * -2 - 1px; // that -1px looks much nicer.
  left: 0;
  height: 24px;
  width: 24px;
  background: $blurple-60;
  clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
}

.caretTop {
  top: unset;
  bottom: -$unit * 2 - 1px;
  clip-path: polygon(50% 50%, 0 0, 100% 0);
}
