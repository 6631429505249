@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";

.status {
  @include font-xs;
  @include ellipsis;

  background: $green-50;
  line-height: 14px;
  min-width: $unit * 1.5;
  color: white;
  font-weight: $font-medium;
  padding: 1px 6px;
  height: $unit * 2;
  border-radius: $unit;
  display: inline-flex;
  align-items: center;
  cursor: default;
  outline: none;

  &.notLatest {
    background: $orange-50;
  }
}
