@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.content {
  width: 100%;
  height: 100%;

  @include content-background;
}

.topBar {
  @include content-background;
  @include inset-border-bottom;
  @include quick-transition(box-shadow);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $unit * 1.5;
  width: 100%;
  z-index: 10;
}

.topBarHeight {
  height: 49px;
}

.editableHeaderPlaceholder {
  height: 88px;
}

.topBar.docked {
  box-shadow: none;
  z-index: 1;

  &.public {
    background: transparent;
  }
}

.topBarMiddle.hidden {
  opacity: 0;
  pointer-events: none;
}

.hidden {
  display: none;
}

.topBarLeft,
.topBarRight,
.topBarMiddle {
  @include quick-transition(opacity);

  opacity: 1;
  flex: 1;
  display: flex;
  align-items: center;
}

.topBar.public {
  flex-shrink: 0;
  top: 0;
  right: 0;
  left: 0;
  position: sticky;
  width: 100vw;
  padding: 0 $unit * 2;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 $unit * 8;
  }
}

.topBarMiddle {
  @include display-m;
  @include ellipsis;

  display: block;
  text-align: center;
}

.topBarRight {
  justify-content: flex-end;
}

.separator {
  display: inline-block;
  width: $unit * 3;
  height: 24px;
  border-left: 1px solid $black-a10;
  margin-left: $unit * 3;
  margin-right: 1px;
}

.metaSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin-top: $unit * 2; // 1 $unit less since the title/description above it has padding-bottom 8px
  margin-bottom: $unit * 3;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.action {
  margin-left: $unit * 1.5;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mainContent {
  @include content-background;

  display: flex;
  width: 100%;
  flex-direction: column;

  .innerContent {
    padding: 0 $unit * 8 $unit * 2;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-mobile) {
      padding-left: $unit * 2;
      padding-right: $unit * 2;
    }
  }
}

.loading {
  height: 50vh;
}

.publicContent {
  .innerContent {
    padding-top: 0;
  }
}

.description {
  @include quick-transition(background-color, 200ms);

  max-width: 676px;
  padding: $unit $unit * 1.5;
  padding-bottom: $unit * 2;
  margin-left: -$unit * 1.5;
  margin-right: -$unit * 1.5;
  border-radius: $unit;

  &:hover,
  &:focus {
    @include secondary-content-background;

    outline: 0;
  }

  &.noHoverState:hover,
  &.noHoverState:focus {
    background-color: inherit;
  }
}

.layersContainer {
  flex: 1;
  z-index: 0;
  margin-top: $unit * 2;

  @include dynamic-padding(bottom);
}

.zoom {
  position: fixed;
  bottom: $vpadding;
  right: $hpadding;
  margin-left: auto;
  width: 144px;
  flex-shrink: 0;
  z-index: 100;
}

.emptyStateContainer {
  @include secondary-content-background;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto !important;
  border-radius: $unit;
  margin-top: $unit * 4;
  padding: $unit * 3.5 0 $unit * 6;
}

.emptyStateButton {
  margin-top: $unit * 2;
}

.headerTitle {
  cursor: text;
  padding: $unit * 0.5 $unit * 1.5;
  margin-left: -$unit * 1.5;
  margin-right: -$unit * 1.5;
  margin-top: 0;
  margin-bottom: $unit * 0.5;
  display: inline-block;
  width: auto;
  min-width: 676px;
}

.headerInput {
  @include quick-transition(background-color, 200ms);
  @include display-xxxl;

  box-shadow: none !important;
  background-color: transparent;
  border-radius: $unit;
  margin-bottom: 0;

  &:focus-within,
  &:hover {
    @include secondary-content-background;
  }
}

.headerInputWrapper {
  margin: 0;
  margin-bottom: $unit * 0.5;
}

.descriptionInputWrapper {
  margin: 0;
}

.descriptionInput {
  @include secondary-content-background;
  @include quick-transition(background-color, 200ms);

  width: auto;
  height: auto;
  cursor: text;
  max-width: 676px;
  line-height: 20px;
  box-shadow: none !important;
  resize: none;
  padding: $unit $unit * 1.5;
  margin-left: -$unit * 1.5;
  margin-right: -$unit * 1.5;
  margin-top: 0;
  border-radius: $unit;
}

.emptyDescriptionInput {
  background-color: transparent;

  &:hover {
    @include secondary-content-background;
  }

  &:focus-within {
    @include secondary-content-background;
  }
}

@media (max-width: $breakpoint-mobile) {
  .headerTitle {
    min-width: 100%;
    box-sizing: content-box;
  }
}
