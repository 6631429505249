@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

// TODO remove bg definitions

.wrapper {
  @include content-background;

  width: 100%;
  min-height: 0;
  position: relative;

  &:not(.mobile) {
    .scrollable {
      @include scrollable;
    }
  }
}

.scrollable {
  flex-grow: 1;
}

.list {
  @include dynamic-padding(top left right bottom);

  overflow: initial;
  width: 100%;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 32px 32px;
    margin: $unit * 3 auto 0 auto;
  }
}

.spinner {
  @include content-background;

  height: $unit * 10;
}

.listLarge {
  max-width: 776px + ($unit * 16);
}
