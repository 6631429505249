@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";

.input {
  margin-bottom: 0;
}

.content {
  padding: $unit * 4;
  // breakpoint for react-modal
  @media screen and (min-width: 640px) {
    max-width: 420px;
  }
}

.label {
  font-weight: $font-medium;
}

.introNote {
  @include font-s;

  color: $gray-50;
  margin-top: 0;
}

.consentItemHeader {
  @include font-ml;

  font-weight: $font-medium;
  margin-bottom: $unit * 3;
}

.note {
  font-weight: $font-regular;
  margin-left: $unit;
}

.inputContainer {
  padding-left: $unit;
}
