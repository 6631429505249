@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.description {
  @include text-color;

  @media screen and (min-width: $breakpoint-mobile) {
    flex-direction: row;
    align-items: center;
  }
}

.connected {
  @include font-xs;

  color: $green-50;
  font-weight: $font-medium;
  margin-top: $unit * 0.5;

  @media screen and (min-width: $breakpoint-mobile) {
    margin-top: 0;
  }
}

.button,
.arrow,
.connected,
.headingWrap {
  margin-left: $unit * 1.5;
}

.icon {
  align-self: flex-start;
  flex-shrink: 0;
}

.arrow {
  margin-right: -$unit;
}
