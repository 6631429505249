@import "../../shared/colors";
@import "../../shared/theme";

.inputFocus {
  @include box-shadow-focus;
}

@include dark-theme {
  .inputFocus {
    @include box-shadow-focus;
  }
}
