@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";

.asset {
  @include border;
  @include content-background;

  margin: $unit;
  height: 60px;
  min-width: 60px;
  border-radius: 3px;
}

.errorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 2px;
}

.image {
  flex: 0 0 auto;
  height: 100%;
}

.hasHover {
  &:hover {
    cursor: pointer;
  }
}
