@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

$nameSpacing: $unit * 2 0 $unit * 0.5;

.thumbnailLink {
  @include outer-keyboard-focus;

  text-decoration: none;
  padding: $unit * 1.5;
  display: block;
  border-radius: $unit * 2;

  &:hover {
    @include cell-hover-background;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.name {
  @include text-color;
  @include multiline-ellipsis(2);

  font-size: $unit * 2;
  line-height: 1.25;
  font-weight: $font-medium;
  margin-bottom: $unit * 0.5;
}

.authorAvatar {
  height: $unit * 2;
  width: $unit * 2;
}

.branchName {
  @include font-m;
  @include secondary-text-color;
  @include ellipsis;

  margin: 0 0 ($unit * 0.5) 0;
}

.thumbnailContainer {
  display: flex;
  flex-direction: column;
}

.thumbnailImageWrapper {
  position: relative;
}

.imageSection,
.placeholderImage {
  @include cell-hover-background;
}

.floatingLoadingText {
  position: absolute;
  color: $gray-50;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  top: 50%;
}

.previewImage {
  padding-bottom: 75%;
  overflow: hidden;

  &::after {
    @include inset-border;

    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
  }
}

@include dark-theme {
  .previewImage::after {
    box-shadow: 0 0 0 1px $black;
  }
}

.borderRadius {
  border-radius: 6px;
}

.previewImage.borderRadius::after {
  border-radius: 6px;
}

.defaultPreview {
  background-size: contain;
}

.bigPreview {
  background-size: cover;
  background-position: top;
}

.tallPreview {
  background-size: cover;
  background-position: top;
}

.widePreview {
  background-size: contain;
  background-position: center;
}

.thumbnailLink.placeholderLink {
  position: relative;

  &:hover {
    background-color: inherit;
  }
}

.createCollectionPlaceholder {
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  text-align: left;
  width: 100%;

  &:hover {
    @include cell-hover-background;
  }

  &:focus {
    outline: none;
  }
}

.createCollectionPlaceholderImage {
  @include inset-border;
}

.createCollectionPlus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  height: $unit * 12;
  width: $unit * 12;
}

.placeholderImage.previewImage {
  &::after {
    display: none;
  }
}

.withShimmer {
  @include shimmerBackground;
}

.name.placeholderName {
  width: 75%;
  padding: 0;
  margin: $nameSpacing;
  height: $unit * 2;
  margin-bottom: $unit;
}

.placeholderAuthor {
  width: 50%;
  height: $unit * 2;
  margin-bottom: $unit * 0.25;
}

.footerSection {
  padding-top: $unit * 2;
}

.mobile {
  &.thumbnailLink {
    padding: $unit * 1.5 $unit * 2;
    border-radius: 0;
  }

  .thumbnailContainer {
    flex-direction: row;
    align-items: center;
  }

  .imageSection {
    width: 96px;
    flex: 0 0 auto;
  }

  .footerSection {
    overflow: hidden;
    padding-top: 0;
    padding-left: $unit * 2;

    .name,
    .branchName {
      @include ellipsis;

      display: block;
    }
  }
}

.collectionThumbnailWrapper {
  position: relative;
}

.collectionThumbnailActions {
  position: absolute;
  top: $unit * 3;
  right: $unit * 3;
  height: 1px;
  width: 1px;
}
