@import "../shared/theme";
@import "../shared/colors";

.container {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;

  &:focus {
    outline: none;
  }

  img {
    display: block;
    -webkit-user-drag: none;

    // inset shadow acts as a border that can only
    // be seen on layers with a transparent background
    @include inset-border;
  }

  &.smallerThanFullsize {
    img {
      cursor: zoom-in;
    }
  }

  &.largerThanFullsize {
    img {
      cursor: zoom-out;
    }
  }

  &.fullsize {
    img {
      -webkit-user-drag: initial;
      will-change: transform;
    }

    &.largerThanContainer {
      img {
        cursor: zoom-out;
      }
    }
  }

  &.largerThanContainer {
    &.grabbing {
      img {
        cursor: grab;
      }
    }

    &.dragging {
      img {
        cursor: grabbing;
      }
    }
  }
}

.largerThanFullsize {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
}

.positioner {
  width: 1px;
  height: 1px;
  position: relative;
  will-change: transform;
}
