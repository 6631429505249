@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.innerList {
  @include content-background;
}

.offlineListBackground {
  @include secondary-content-background;
}

.layerHeading {
  padding-left: $unit * 2;
  padding-top: $unit * 2;
  flex-shrink: 0;
}

.layerHeadingBranchCommit {
  @include dynamic-padding(left);

  padding-top: $unit * 2;
  flex-shrink: 0;
}

.commit {
  @include inner-keyboard-focus;

  flex-shrink: 0;
  transform: translateZ(0); // ensure scrolling does not repaint
}

.branchCommit {
  @include dynamic-padding(right left);

  padding-bottom: 0;
  padding-top: 0;
  margin: 0;
  flex-shrink: 0;
}

.list {
  min-height: 100%;
  flex-grow: 1;
  padding-bottom: $unit * 3;
}

.sidebarTopHeading {
  padding-left: $unit * 2;
  padding-top: $unit * 3;
}

.sidebarTopHeadingBranchCommit {
  @include dynamic-padding(left);

  padding-top: $unit * 3;
  flex-shrink: 0;
}

.error {
  padding: $unit * 1.5 $unit * 2;
  text-align: center;
}

.note {
  @include secondary-text-color;

  margin-top: $unit * 1.5;
}
