@import "../shared/vars";
@import "../shared/theme";

.propertyRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin: 0 $unit * 2;
  padding: $unit 0;

  & + & {
    @include border-top;
  }

  &.grouped {
    border-top: none;
    padding: $unit * 0.5 0;
  }
}
