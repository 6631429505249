@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";

.pillTab {
  @include font-xs;
  @include secondary-text-color;
  @include outer-keyboard-focus;

  display: flex;
  align-items: center;
  margin: 0 2px;
  padding: $unit * 0.5 $unit;
  border-radius: $unit * 0.5;
  font-weight: $font-medium;
  text-decoration: none;

  &:hover {
    @include secondary-content-background;
    @include secondary-text-color;
  }
}

.active {
  pointer-events: none;

  &,
  &:hover {
    background-color: $blurple-60;
    color: $white;
  }

  &.secondary,
  &.secondary:hover {
    @include text-color;
    @include cell-hover-background;
  }
}

.icon {
  margin-right: $unit * 0.5;
  margin-top: -$unit;
  margin-bottom: -$unit;
  margin-left: -$unit * 0.5;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
