@import "../../shared/mixins";
@import "../../shared/theme";
@import "../../shared/vars";

.files {
  padding: $unit $unit * 2;
}

.file {
  margin: 0 $unit * -0.5;
}

.noFiles {
  padding: $unit * 5 $unit * 2 $unit * 8;
}

.button {
  margin-top: $unit;
}

.mobile {
  &.files {
    padding: 0;
  }

  .file {
    margin: 0;
  }

  .noFiles {
    margin: 0;
    padding: $unit * 2 $unit * 2 $unit * 4;
  }
}
