@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.banner {
  color: $white;
  background: $gray-80;
  padding: $unit * 2;
}

.icon {
  margin-right: $unit * 0.5;
}

.copy {
  padding-top: $unit * 0.25;
}

.projectName {
  font-weight: $font-medium;
}

.learnMore {
  @include link-inverse;

  white-space: nowrap;
}

@include dark-theme {
  .banner {
    color: $gray-70;
    background: $gray-10;
  }
}
