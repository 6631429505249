@import "../shared/vars";
@import "../shared/mixins";

.tree {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fileExtension {
  user-select: none;
}

.styleItem {
  padding-left: $unit * 0.5;
}

.hiddenDisclosure {
  display: none;
}

.dynamic {
  @include dynamic-padding(left right);

  left: -($unit * 3.5);
  width: calc(100% + #{$unit * 3.5});
}
