@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.content {
  @include text-color;

  display: flex;
  flex: 1;
  min-width: 0;
  min-height: $unit * 6;

  &.light {
    color: $white;

    .meta {
      color: $white;
      opacity: 0.5;
    }
  }

  &.large {
    display: block;
  }
}

.textOverflow {
  @include ellipsis;
}

.main {
  margin-right: $unit;
  min-width: 0;
}

.heading {
  display: flex;
  align-items: center;
}

.name {
  @include ellipsis;
  @include font-m;

  font-weight: $font-medium;
}

.statusBadge {
  float: left;
  margin-right: $unit;
}

.meta {
  @include ellipsis;
  @include font-s;
  @include secondary-text-color;

  display: flex;
}

.layerStack {
  display: flex;
  flex-direction: row;

  &.large {
    padding-top: $unit;
  }
}

.layerStackThumbnail {
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: $unit * 6;
  height: $unit * 6;
  background-color: $gray-10;
  overflow: hidden;

  & + & {
    width: 12px;
    margin-left: 2px;
  }

  &.large,
  & + &.large {
    width: 25%;
  }
}

.previewImage {
  min-width: $unit * 6;
  min-height: $unit * 6;
}
