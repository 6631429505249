@import "../shared/vars";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/colors";
@import "../shared/theme";

.content {
  @include font-m;

  display: block;
  padding: 0 ($unit * 6) ($unit * 6);
  box-sizing: content-box;
  flex-grow: 1;

  p {
    line-height: 1.4em;
    margin-bottom: 1.4em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  em {
    font-weight: $font-medium;
  }

  @media (max-width: $breakpoint-mobile) {
    max-width: 100%;
  }
}

.alternativePadding {
  padding: 0;
}

.actions {
  @include border-top;

  display: flex;
  position: relative;
  padding: $unit * 3 $unit * 4;
  flex-shrink: 0;
  justify-content: flex-end;

  button {
    flex-grow: 1;
  }

  button:nth-child(2) {
    margin-left: $unit * 2;
  }
}

.naturalButtonWidth button {
  flex-grow: 0;
}

.fullScreenForm {
  max-width: 100%;
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.fullScreenActions {
  @include content-background;
  @include border-top;

  height: $unit * 10;
  padding: $vpadding $hpadding;
  width: 100%;
  justify-content: flex-end;
  flex-shrink: 0;

  button {
    flex-grow: unset;
  }
}

.heading {
  padding-bottom: ($unit * 2);
}

.alternativePaddingOnHeading {
  padding: 0 ($unit * 4);
}
