@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";
@import "../shared/vars";

.color {
  border-radius: $unit;
}

.absolute {
  position: absolute;
}

.horizontal {
  height: $unit * 0.5;
  right: $unit * 2;
  left: $unit * 2;
  top: $unit * 1.5;
}

.vertical {
  height: 100%;
  width: $unit * 0.5;
  flex-shrink: 0;
  margin-bottom: -2px;
}

.squircle {
  width: 14px;
  height: 14px;
  border-radius: 5px;
}

.light {
  box-shadow: 0 0 0 1px $black-a10;
}

.selected {
  box-shadow: 0 0 0 1px $white;

  &.light {
    box-shadow:
      inset 0 0 0 1px $black-a10,
      0 0 0 1px $white;
  }
}

@include dark-theme {
  .dark {
    box-shadow: 0 0 0 1px $white-a15;
  }

  .light {
    box-shadow: none;
  }

  .selected {
    box-shadow: 0 0 0 1px $white;

    &.light {
      box-shadow:
        inset 0 0 0 1px $black-a10,
        0 0 0 1px $white;
    }
  }
}

.placeholder {
  @include shimmerBackground;
}
