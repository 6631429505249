@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";

.labeledCheckbox {
  margin: $unit * 1.5 0;
  margin-top: $unit;
}

.checkbox {
  margin: 0;
}

.modalLabel {
  @include text-color;

  order: 1;
  flex-grow: 1;
  font-weight: $font-medium;
}

.preventUpgrade {
  @include font-s;
}

.copyLink {
  @include link;

  text-decoration: none;
}

.seatDescription,
.roleDescription {
  @include font-m;
}

.confirmationLabel {
  @include font-m;
}

.confirmationValue {
  @include font-m;

  font-weight: $font-medium;
}

.visuallyHidden {
  visibility: hidden;
}

.modalContent {
  padding: $unit $unit * 6;
  padding-bottom: $unit * 3;
}

.modalFooter {
  @include border-top;

  min-height: $unit * 8;
  padding: 0 $unit * 6;
}

.modalButton {
  height: $unit * 4;
}

.dropDown {
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.buttonSpacer {
  display: inline-block;
  height: 1px;
  width: $unit;
}

.permissionsModalHeaderText {
  border-bottom: none;
}

.permissionsModalHeader {
  font-size: $unit * 4;
  font-weight: $font-bold;
  margin-top: $unit * 3.25;
  line-height: normal;
}

.capitalize {
  text-transform: capitalize;
}

@media screen and (min-width: $breakpoint-mobile) {
  .confirmationDialog {
    max-width: 400px;
  }
}

.textLink {
  color: $gray-50;
  text-decoration: underline;

  &:hover {
    color: $gray-60;
  }
}

.error {
  @include font-s;

  color: $red-50;
}

@include dark-theme {
  .dropDown {
    border-radius: 9px;
    border: 1px solid #686869;
  }
}
