@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";

.custom_dark {
  box-shadow: 0 0 0 1px $black-a10;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
  }
}

@include dark-theme {
  .custom_dark {
    @include input;

    box-shadow: 0 0 0 1px $white-a25;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $white;
    }

    &:focus {
      background: $white-a15;
    }

    &:disabled {
      background: $gray-80;
    }

    option {
      @include content-background;
    }
  }
}
