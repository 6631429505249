@import "../shared/vars";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.file {
  @include outer-keyboard-focus;

  border-radius: 3px;
  display: inline-flex;
  flex: 1;
  text-decoration: none;
  cursor: default;
  padding: $unit * 0.5;
  line-height: 20px;
  user-select: none;
  outline: 0;

  .content {
    flex-shrink: 1;
    overflow: hidden;
  }
}

.goto {
  margin: 0 $unit;
}

.small {
  padding-right: 0;

  .goto {
    margin: 0;
  }
}

.wrapper {
  display: flex;
  border-radius: $unit * 0.5;
  width: 100%;

  &:hover {
    @include cell-hover-background;
  }
}

@include dark-theme {
  .dark {
    &:hover {
      background: $black-a25;
    }
  }
}

.name {
  @include text-color;
}

.meta {
  @include font-s;
  @include ellipsis;
  @include secondary-text-color;

  margin: 0;
}

.selected {
  .wrapper {
    background: $secondary-selected;
  }
}

.selected.focused {
  .wrapper {
    background: $selected;
    color: $white;
  }

  .name,
  .meta {
    color: $white;
  }
}

.mobile {
  @include border-bottom;

  &:hover {
    @include cell-hover-background;
  }
}

.placeholder {
  display: inline-flex;
  padding: $unit * 0.5;

  &:hover {
    background: none;
    cursor: default;
  }
}
