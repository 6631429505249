@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.sidebarSection {
  display: flex;
  flex-direction: column;
  min-height: 0;

  &.flex {
    flex: 1;
  }

  & + & {
    @include inset-border-top;
  }
}

.label {
  @include font-s;

  font-weight: $font-medium;
  text-transform: uppercase;
}

.disclosure {
  margin-left: -$unit;
  margin-right: $unit * 0.5;

  .collapsed & {
    transform: rotate(-90deg);
  }
}

.children {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  transition:
    max-height 250ms ease-in-out,
    opacity 250ms ease-out;

  .collapsed & {
    overflow: hidden;
    max-height: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  height: $unit * 6;
  padding: 0 $unit * 2;
}
