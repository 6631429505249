@import "../../shared/mixins";
@import "../../shared/vars";
@import "../../shared/colors";
@import "../../shared/typography";
@import "../../shared/theme";

.bottom {
  padding: 10px $unit * 2 $unit * 1.5;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  height: $unit * 6;

  @media (min-width: $breakpoint-mobile) {
    padding: $unit * 1.5 $unit * 4;
  }
}

.searchInput {
  width: $unit * 24.5;
  margin-left: auto;
}

.changed .searchInput {
  margin-left: $unit * 2;
}
