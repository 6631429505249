@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";

.label {
  display: none;
}

.note {
  font-weight: $font-regular;
  margin-top: 2px;
}

.toggleButton {
  margin-right: $unit;
}

.deleteAccount {
  padding: $unit * 1.5 $unit * 2;
}

.deleteButton {
  color: $gray-50;
  font-size: $unit * 1.625;
  font-weight: $font-medium;
  text-decoration: underline;

  &:hover {
    color: $gray-60;
  }
}

.notificationLabel {
  font-weight: $font-medium;
}

.orgList {
  padding-bottom: 0 !important;
  max-width: $max-form-width;
  margin-right: auto;
  width: 100%;
}

.orgMenu {
  margin-left: 0;
  margin-top: $unit * 2;
}

@media screen and (min-width: $breakpoint-mobile) {
  .label {
    display: block;
  }

  .deleteAccount {
    padding: 0 $unit * 4;
  }

  .orgMenu {
    margin-left: $unit * 4;
    margin-right: $unit * 4;
  }
}

a.manageEmailsLink {
  @include link;
}

.token {
  flex-grow: 1;
  margin-bottom: 0;
}

.copyButtonWrapper {
  align-self: flex-end;
  margin-bottom: 0;
  margin-left: $unit;
}
