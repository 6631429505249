@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";

.layerDetails {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: $unit * 0.5;

  &.layerDetailsNameLoading {
    @include shimmerBackground;
  }
}

.layerDetailsWrapper {
  @include border-bottom;

  padding: $unit;
}

.layerDetailsRight {
  flex: 1;
  margin-left: $unit * 0.5;
  margin-top: $unit * 0.5;
}

.layerDetailsName {
  @include font-m;
  @include quick-transition(color, 200ms);

  font-weight: $font-medium;
  word-break: break-word;
  hyphens: auto;

  &.layerNameChanged {
    @include link-color;

    font-weight: $font-medium;
  }
}

.layerDetailsType {
  @include font-s;
  @include secondary-text-color;
}

.goToMasterButton {
  cursor: pointer;

  .goToMasterButtonIcon {
    align-items: center;
    margin-right: $unit;
    align-self: center;
  }

  &:hover,
  &:focus {
    @include cell-hover-background;
  }
}

.colorGrid {
  display: flex;
  flex-wrap: wrap;
  margin: $unit * -0.5; // for .colorGridItem
}

.colorGridItem {
  margin: $unit * 0.5;
}

.contentPropertyRow {
  padding: 0;
}

.code {
  @include font-s;
  @include font-mono;
  @include text-color;

  counter-reset: code;
  outline: none;
  white-space: pre-line;
}

.codeLine {
  $gutter-width: $unit * 5;
  $wrap-indent: $unit;

  text-indent: -$wrap-indent;
  padding-left: $gutter-width + $wrap-indent;
  padding-right: $unit;

  &::before {
    @include secondary-text-color;

    user-select: none;
    display: inline-block;
    width: $gutter-width;
    margin-left: -$gutter-width;
    text-align: center;
    text-indent: 0; // unset
    counter-increment: code;
    content: counter(code);
  }
}

.codeValue {
  @include purple-text-color;
}

.gradientStopRow {
  $color: $gray-40;
  $size: $unit * 2;
  $dotSize: 6px;
  $thickness: $unit * 0.25;

  position: relative;

  &::before {
    @include content-background;
    @include placeholder-text-color(border-color);

    display: block;
    transform: translateX(-50%);
    position: absolute;
    left: $size * 0.5;
    top: $size * 0.5;
    width: $dotSize;
    height: $dotSize;
    border-width: $thickness;
    border-style: solid;
    border-radius: 50%;
    content: "";
  }

  & + &::after {
    @include placeholder-text-color(background-color);

    display: block;
    pointer-events: none;
    transform: translateX(-50%);
    position: absolute;
    top: -$size * 0.5 - $thickness;
    left: $size * 0.5;
    width: $thickness;
    height: $size + $thickness;
    content: "";
  }
}

.footer {
  @include inset-border-top;

  min-height: $unit * 6;
  padding: 0 $unit * 1.5;
}

.errorText {
  @include ellipsis;
  @include font-m;
  @include error-text-color;

  flex: 1;
  padding-right: $unit;
}
