@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.downloadButton {
  background: $gray-10;
  text-transform: none;
  border-radius: $unit;
  display: flex;
  flex-direction: column;
  border: $default-border;
  color: $black;
  text-decoration: none;
  padding: $unit * 3;
  margin-top: $unit * 2.5;
}

.button {
  margin-top: $unit * 2;
}

.contentContainer {
  margin-top: $unit * 3;
}

.webHeader {
  margin-bottom: $unit;
}

.message {
  text-align: center;
}

.appText {
  padding-top: $unit;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: $unit;
  }
}

.orText {
  @include font-s;

  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-50;
  font-weight: $font-medium;
  margin: $unit * 2 0;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 1px;
    background: $gray-30;
    flex-grow: 1;
  }

  &::before {
    margin-right: $unit;
  }

  &::after {
    margin-left: $unit;
  }
}

@include dark-theme {
  .orText {
    &::before,
    &::after {
      background: $gray-70;
    }
  }
}

.buttonInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: $unit * 1.5;
  text-align: center;
}

.label {
  @include font-m;

  font-weight: $font-medium;
}
