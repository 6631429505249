@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.componentWrapper {
  margin-bottom: $unit * 2;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.blockContainer {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.inlineContainer {
  display: inline-flex;
  align-items: center;
}

.label {
  @include text-color;

  order: 1;
  flex-grow: 1;
  font-weight: $font-medium;
}

.sublabel {
  @include font-s;
  @include secondary-text-color;

  font-weight: $font-regular;
}

.inputWrapper {
  @include breakWord;

  position: relative;
  width: 100%;
  order: 3;
}

.componentWrapper.visuallyhidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.helpers {
  @include font-s;
  @include secondary-text-color;

  order: 2;

  a {
    @include secondary-text-color;

    &:hover {
      @include text-color;
    }
  }
}

.helper {
  margin-left: $unit * 2;
}

.label,
.helpers {
  padding: 7px 0;
}

/* styles for the two-column block input design */
.responsiveContainer {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.responsiveInput {
  margin-top: $unit * 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.responsiveLabel,
.responsiveHelpers {
  padding: 10px 0;
}

.responsiveError {
  padding-bottom: 0;
}

/* styles for the two-column inline input design */

.responsiveInlineWrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.responsiveInlineLabel {
  @include breakWord;

  margin-right: $unit * 2;
  line-height: $unit * 2;
}

.responsiveInlineHelpers {
  padding-bottom: 0;
  padding-top: 0;

  .helper {
    margin-left: 0;
    margin-right: $unit * 2;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .responsiveLabel {
    padding: 0;
  }

  .responsiveHelpers {
    width: 50%;
    text-align: right;
    flex-grow: 2;
    padding: 0;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .responsiveInput,
  .responsiveInlineHelpers {
    width: 50%;
    min-width: 50%;
    padding-left: $unit * 2;
    margin-top: 0;
    padding-top: 0;
  }

  .responsiveInlineWrap {
    flex-grow: 1;
  }

  .responsiveLabel {
    flex-grow: 1;
    padding: 0;
    line-height: $unit * 2;
    align-self: flex-start;
  }

  .responsiveError {
    width: 50%;
    margin-left: auto;
    padding-left: $unit * 2;
  }

  .responsiveContainer {
    flex-wrap: nowrap;
  }

  .responsiveHelpers .helper {
    white-space: nowrap;
  }
}
