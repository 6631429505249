@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

$empty-index: 5;
$zoom-index: 4;
$headers-index: 3;

.list {
  position: relative;
  height: 100%;
  flex-grow: 1;
}

.innerList {
  @include content-background;

  transform: none !important;
}

.selecting {
  user-select: none;
}

.emptyContent {
  @include content-background;
  @include border-top;
  @include secondary-text-color;

  min-height: $unit * 13;
  height: $unit * 13;
}

.emptyContentRow {
  z-index: $empty-index;
}

.noFooterEmptyContent {
  height: auto !important;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.noHeaderEmptyContent {
  .emptyContent {
    border: none;
  }
}

.header {
  @include border-bottom;

  transform: none;
}

.fileHeader {
  @include border-top;

  max-width: none;
}

.sticky {
  @include content-background;

  width: auto !important;
  position: sticky;
  overflow: hidden;
  top: -1px;
  z-index: $headers-index;
  margin-bottom: $unit * 2;
}

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hoverable {
  &::before {
    @include quick-transition(background);

    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    z-index: -1;
  }

  &.sectionHeader::before {
    top: 2px;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    &::before {
      right: $unit * 2;
      left: $unit * 2;
    }

    &.sectionHeader::before {
      top: 0;
      border-top-right-radius: $unit * 1.5;
      border-top-left-radius: $unit * 1.5;
    }

    &.sectionCollapsed::before {
      border-bottom-right-radius: $unit * 1.5;
      border-bottom-left-radius: $unit * 1.5;
    }

    &.lastSectionRow::before {
      bottom: $unit;
      border-bottom-right-radius: $unit * 1.5;
      border-bottom-left-radius: $unit * 1.5;
    }
  }
}

.sectionHeader:hover {
  cursor: pointer;

  &::before {
    @include cell-hover-background;
  }

  & ~ .hoverable {
    &::before {
      @include cell-hover-background;
    }
  }
}

.dependencyRow {
  padding: 0 $unit * 2;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 $unit * 4;
  }

  .item {
    @include border-left;
    @include border-right;
  }

  &.firstSectionRow {
    .item {
      @include border-top;

      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      &::after {
        display: none;
      }
    }
  }

  &.lastSectionRow {
    .item {
      @include border-bottom;

      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &::before {
      bottom: -$unit * 2;
    }
  }
}

.arrowIcon {
  margin-left: $unit * 0.5;
  margin-right: $unit * 0.5;
  margin-top: -2px;
  margin-bottom: -2px;
}

.editIcon {
  display: inline;

  svg {
    margin-top: -$unit * 0.5;
    margin-bottom: -6px;
  }
}

.strikethrough {
  text-decoration: line-through;
}

.emphasis {
  font-weight: $font-medium;
}

.fileName {
  display: inline;
}

.zoom {
  display: flex;
  position: fixed;
  bottom: $unit * 2;
  right: $unit * 2;
  z-index: $zoom-index;

  @media screen and (min-width: $breakpoint-mobile) {
    position: absolute;
    bottom: $unit * 3;
    right: $unit * 4;
  }
}

.zoomInput {
  display: none;
  margin-left: $unit;

  @media screen and (min-width: $breakpoint-mobile) {
    display: flex;
  }
}

.scrollToTop {
  @include modal-shadow;
  @include content-background;
  @include quick-transition(opacity);

  border-radius: $unit * 0.5;
  padding: $unit * 0.5;
  height: $unit * 4;
  opacity: 1;
  will-change: opacity;
}

.scrollToTopHidden {
  opacity: 0;
  pointer-events: none;
}

@include dark-theme {
  .scrollToTop {
    @include modal-outline;
  }
}
