@import "../shared/animations";
@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";

.colorSwatch {
  display: flex;

  &.clickable {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
}

.color,
.transparency {
  border-radius: 3px;
}

.color {
  position: relative;
  flex: 1;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px $black-a10;
  cursor: pointer;

  &.fadeIn {
    @include fade-in;

    animation-duration: 250ms;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: $black-a10;
    content: "";
  }

  .clickable:focus &::after,
  .clickable &:hover::after {
    opacity: 1;
  }
}

@include dark-theme {
  .colorSwatch {
    .color {
      box-shadow:
        inset 0 0 0 1px $white-a15,
        0 0 0 1px $black;

      &::after {
        background-color: $white-a15;
      }
    }
  }
}

.themeConfigSystem {
  position: relative;
  background-image: url('data:image/svg+xml;utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23EDEEEF" x="0" y="0" width="8" height="8"></rect><rect fill="%23EDEEEF" x="8" y="8" width="8" height="8"></rect><rect fill="%23FFFFFF" x="0" y="8" width="8" height="8"></rect><rect fill="%23FFFFFF" x="8" y="0" width="8" height="8"></rect></g></svg>') !important;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('data:image/svg+xml;utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23222324" x="0" y="0" width="8" height="8"></rect><rect fill="%23222324" x="8" y="8" width="8" height="8"></rect><rect fill="%232D2E2F" x="0" y="8" width="8" height="8"></rect><rect fill="%232D2E2F" x="8" y="0" width="8" height="8"></rect></g></svg>');
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    border-radius: 3px;
  }
}

.themeConfigLight {
  background-image: url('data:image/svg+xml;utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23EDEEEF" x="0" y="0" width="8" height="8"></rect><rect fill="%23EDEEEF" x="8" y="8" width="8" height="8"></rect><rect fill="%23FFFFFF" x="0" y="8" width="8" height="8"></rect><rect fill="%23FFFFFF" x="8" y="0" width="8" height="8"></rect></g></svg>') !important; // For components/Transparency
}

.themeConfigDark {
  background-image: url('data:image/svg+xml;utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23222324" x="0" y="0" width="8" height="8"></rect><rect fill="%23222324" x="8" y="8" width="8" height="8"></rect><rect fill="%232D2E2F" x="0" y="8" width="8" height="8"></rect><rect fill="%232D2E2F" x="8" y="0" width="8" height="8"></rect></g></svg>') !important; // For components/Transparency
}

.forceAutoSolid {
  background-image: url('data:image/svg+xml;utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23F6F7F7" x="0" y="0" width="8" height="8"></rect><rect fill="%23F6F7F7" x="8" y="8" width="8" height="8"></rect><rect fill="%23F6F7F7" x="0" y="8" width="8" height="8"></rect><rect fill="%23F6F7F7" x="8" y="0" width="8" height="8"></rect></g></svg>') !important;

  &::before {
    background-image: url('data:image/svg+xml;utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23000000" x="0" y="0" width="8" height="8"></rect><rect fill="%23000000" x="8" y="8" width="8" height="8"></rect><rect fill="%23000000" x="0" y="8" width="8" height="8"></rect><rect fill="%23000000" x="8" y="0" width="8" height="8"></rect></g></svg>');
  }
}

.forceLightSolid {
  background: $gray-10 !important; // for components/Transparency
  background-image: none !important; // for components/Transparency
}

.forceDarkSolid {
  background: $black !important; // for components/Transparency
  background-image: none !important; // for components/Transparency
}
