@import "./colors";
@import "./theme";

@mixin font {
  font-family:
    "emoji",
    "GT-America",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;

  // Hack that targets only Safari and removes emoji from the front of the font
  // stack as this typeface causes text alignment to be lower in only this
  // browser.
  // https://stackoverflow.com/questions/2860745/css-hack-for-safari-only

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      font-family:
        "GT-America",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Helvetica",
        "Arial",
        sans-serif;
    }
  }
}

@mixin font-serif {
  font-family: "GT-Super", "Georgia", serif;
}

@mixin font-mono {
  font-family: "American Mono", "Menlo", "Consolas", monospace;
}

@mixin display-xxxl {
  @include font;

  font-size: 32px;
  line-height: 40px;
  font-weight: $font-bold;
}

@mixin display-xxl {
  @include font;

  font-size: 28px;
  line-height: 34px;
  font-weight: $font-bold;
}

@mixin display-xl {
  @include font;

  font-size: 20px;
  line-height: 25px;
  font-weight: $font-bold;
}

@mixin display-l {
  @include font;

  font-size: 16px;
  line-height: 20px;
  font-weight: $font-medium;
}

@mixin display-m {
  @include font;

  font-size: 14px;
  line-height: 20px;
  font-weight: $font-medium;
}

@mixin display-s {
  @include font;

  font-size: 13px;
  line-height: 18px;
  font-weight: $font-medium;
  text-transform: uppercase;
}

@mixin display-xs {
  @include font;

  font-size: 11px;
  line-height: 16px;
  font-weight: $font-medium;
  text-transform: uppercase;
}

@mixin font-xl {
  @include font;

  font-size: 24px;
  line-height: 33.6px;
}

@mixin font-l {
  @include font;

  font-size: 18px;
  line-height: 25.2px;
}

@mixin font-ml {
  @include font;

  font-size: 16px;
  line-height: 22.4px;
}

@mixin font-m {
  @include font;

  font-size: 14px;
  line-height: 19.6px;
}

@mixin font-s {
  @include font;

  font-size: 13px;
  line-height: 18px;
}

@mixin font-xs {
  @include font;

  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

@mixin link {
  color: $blurple-60;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: darken($blurple-60, 25%);
  }
}

@mixin brand-onboarding-heading {
  @include text-color;

  font-weight: 500;
  font-size: 36px;
  letter-spacing: 0.41px;
  line-height: 44px;

  @media (max-width: $breakpoint-mobile) {
    font-size: 28px;
    letter-spacing: 0.32px;
    line-height: 44px;
  }
}
