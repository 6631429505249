@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/theme";
@import "../../shared/colors";

.role {
  display: flex;
  flex-basis: 0;
  padding-top: 0;
  margin-bottom: $unit;
}

.roleInput {
  align-self: flex-start;
  margin: 3px 0.5ex;
}

.details {
  margin-left: $unit;

  h3 {
    @include font-m;

    font-weight: $font-medium;
    margin: 0;
    padding-bottom: $unit * 0.5;
  }

  p {
    margin: 0 0 $unit * 0.5;
  }
}

.disabled {
  h3,
  p {
    @include secondary-text-color;
  }
}

.darkerNote {
  @include text-color;
}

.nextButton {
  margin-left: $unit;
}

.textLink {
  @include font-m;

  font-weight: $font-medium;
  color: $blurple-60;
  text-decoration: none;

  &:hover {
    color: $blurple-80;
  }
}
