@import "../shared/vars";
@import "../shared/typography";

.errorNotification {
  margin-bottom: $unit * 1.5;
}

.colorSelector {
  margin-top: $unit * 2;
  margin-bottom: $unit * 3;
}
