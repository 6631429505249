@import "../shared/vars";
@import "../shared/mixins";

.fourohfour {
  margin: 0 auto $unit * 2;
  width: 128px;
  height: 128px;
  background: url("./images/fof.svg") no-repeat -128px 0;
  background-size: 640px 256px;
  animation: expressions 10s linear infinite;
}

@keyframes expressions {
  0%,
  9.999% {
    background-position: -128px 0;
  }

  10%,
  11.999% {
    background-position: 0 0;
  }

  12%,
  21.999% {
    background-position: -384px 0;
  }

  22%,
  23.999% {
    background-position: 0 0;
  }

  24%,
  33.999% {
    background-position: -512px 0;
  }

  34%,
  35.999% {
    background-position: 0 0;
  }

  36%,
  45.999% {
    background-position: -256px 0;
  }

  46%,
  49.999% {
    background-position: -128px 0;
  }

  50%,
  59.999% {
    background-position: -128px -128px;
  }

  60%,
  61.999% {
    background-position: 0 -128px;
  }

  62%,
  71.999% {
    background-position: -384px -128px;
  }

  72%,
  73.999% {
    background-position: 0 -128px;
  }

  74%,
  83.999% {
    background-position: -512px -128px;
  }

  84%,
  85.999% {
    background-position: 0 -128px;
  }

  86%,
  95.999% {
    background-position: -256px -128px;
  }

  96%,
  100% {
    background-position: -128px -128px;
  }
}
