@import "../shared/animations";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.layerCanvas {
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
}

@include dark-theme {
  .layerCanvas {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      box-shadow: 0 0 0 1px $black inset;
    }
  }
}

.layerMissing {
  @include secondary-text-color;
}

.annotationMessage {
  @include fade-in;
  @include floating;
  @include font-s;

  position: absolute;
  bottom: $unit * 2;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px $unit * 1.5;
  background: $blurple-60;
  color: $white;
  border-radius: $unit * 2;
  white-space: nowrap;
  font-weight: $font-medium;
  width: 266px;
  text-align: center;

  &::before {
    border-radius: $unit * 2;
  }
}
