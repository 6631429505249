@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.checkboxWrapper {
  margin-bottom: 0;
  padding-left: $unit * 2.5;
}

.groupWrapper {
  padding-left: 0;
}

.checkboxLabel {
  align-items: center;
  align-self: center;
  display: flex;
  font-weight: normal;
  line-height: $unit * 4;
  margin: 0;
  padding: 0;
}

.groupWrapper .checkboxLabel {
  font-weight: $font-medium;
}

.checkbox {
  margin: ($unit * 1.25) $unit ($unit * 1.25) 0;
}

.readOnly .checkbox {
  display: none;
}

.readOnly > hr:first-of-type {
  display: none;
}

.eventName {
  white-space: nowrap;
}

.eventMeta {
  @include ellipsis;

  display: none;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: $unit;
  text-align: right;
}

.eventType {
  @include font-s;
  @include font-mono;
  @include secondary-text-color;
}

.searchBar {
  margin: ($unit * 2) 0;
}

@media (min-width: $breakpoint-mobile) {
  .eventMeta {
    display: block;
  }
}
