@import "../shared/colors";
@import "../shared/vars";

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  background: $gray-10;
  padding: $unit * 4;
  min-height: $unit * 44;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: $unit * 2;
  display: flex;
}
