@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/animations";

.wrapper {
  margin-bottom: $unit;

  &:last-child {
    margin-bottom: 0;
  }
}

.text {
  @include input;

  padding-top: $unit - 1;
  padding-bottom: $unit - 1;
  line-height: 18px;
  display: block;

  // For some reason, iOS makes an input larger than the line-height
  // we specified (~27px). Let's decrease the padding to make up for
  // it, and set a min-height to make it an even number.
  @media (max-width: $breakpoint-mobile) {
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: $unit * 4;
  }
}

.withIcon {
  padding-left: $unit * 3;
}

.withCount {
  padding-right: $unit * 5;
}

.withShowHelper {
  padding-right: $unit * 3;
}

.counter {
  @include fade-in;
  @include font-xs;
  @include secondary-text-color;

  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  line-height: $unit * 4;
  padding: 0 $unit * 1.5;
  border-radius: 4px;
}

.icon {
  position: absolute !important;
  bottom: 50%;
  left: 0;
  transform: translateY(50%);
}

.inputError {
  @include input-error;
}

.inputWithContextTag {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}

.innerContextTag {
  @include font-s;

  color: $gray-50;
}
