@import "../shared/mixins";
@import "../shared/vars";

.divider {
  border: 0;

  @include border-bottom;

  margin: $unit * 0.5 auto;
}
