@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.checkboxWrapper {
  margin-top: $unit * 2;

  & + & {
    margin-bottom: $unit * 2;
  }
}

.checkbox {
  margin: $unit * 0.5 0;
}

.note {
  @include text-color;

  padding-top: 0;
  margin-top: 0;
  margin-left: $unit;
  font-weight: $font-regular;

  .link {
    @include text-color;

    &:hover {
      color: darken($gray-70, 10%);
    }
  }
}

@include dark-theme {
  .link:hover {
    color: darken($white, 10%);
  }

  :global {
    .grecaptcha-badge {
      box-shadow: 0 0 0 1px $white-a15 !important;
    }
  }
}
