@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.empty {
  color: $gray-50;

  &:not(.disabled) {
    &:hover {
      color: $gray-50 !important;
    }
  }
}

.select {
  background: $white;
  font-weight: $font-regular;
  text-transform: none;
  height: $unit * 4;

  &.disabled {
    @include input-background-disabled;
    @include placeholder-text-color;
  }

  span {
    font-size: 14px !important;
  }
}

.icon {
  margin: 0 0 0 auto !important;
  height: $unit * 3 !important;
  width: $unit * 3 !important;
}

.inputWrapper {
  width: 100%;
  position: relative;
  margin-top: -$unit * 2;
  margin-bottom: $unit * 2;

  @media (min-width: $breakpoint-mobile) {
    margin-top: 0;
  }
}

.responsiveInputWrapper {
  margin-bottom: 0;
}

.nameInput {
  margin-bottom: 0;
}

.nameHelper {
  @include font-s;

  color: $gray-50;
  line-height: $unit * 2;
  margin-top: $unit * 1.5;
}

.responsiveNameHelper {
  @media (min-width: $breakpoint-mobile) {
    margin-top: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    min-height: $unit * 4;
    white-space: pre-wrap;
  }
}

@include dark-theme {
  .select.disabled:disabled {
    @include input-background-disabled;
    @include box-shadow;
  }

  .empty {
    color: $gray-50;

    &:not(.disabled) {
      &:hover {
        color: $white !important;
      }
    }
  }
}
