@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.thumbnailMoving {
  z-index: $depth-notifications;
}

.guestMode {
  .preview::before {
    position: absolute;
    top: -$unit;
    right: -$unit;
    left: -$unit;
    bottom: $unit * 5;
    border-top-right-radius: $unit;
    border-top-left-radius: $unit;
    content: "";
    background-color: $black-a10;
    background-image: url("./images/play.svg");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 5;
    opacity: 0;
  }

  &:hover,
  &:active {
    .preview::before {
      opacity: 1;
    }
  }
}

.editable {
  -webkit-user-select: none;
  user-select: none;
  cursor: move !important; /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;

  a,
  a:hover,
  .preview {
    cursor: move !important; /* fallback if grab cursor is unsupported */
    cursor: grab !important;
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;
  }

  .actions {
    padding-left: $unit * 2;
    padding-right: $unit * 2;
  }
}

.container {
  width: 100%;
  height: 100%;
}

.actions {
  @include secondary-content-background;
  @include text-color;

  display: flex;
  box-shadow: 0 -1px 0 $black-a10;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  height: $unit * 6;
  z-index: 100;
  margin: -$unit;
  cursor: default;
  border-bottom-left-radius: $unit;
  border-bottom-right-radius: $unit;
}

.actionButton {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 50%;
  height: 100%;
  border-radius: 0;

  &:first-child {
    border-bottom-left-radius: $unit;
  }

  &:last-child {
    border-bottom-right-radius: $unit;
  }
}

.actionButton + .actionButton {
  @include border-left;

  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

@include dark-theme {
  .actions {
    box-shadow: 0 -1px 0 $black;
  }

  .actionButton {
    background: transparent;
    border-color: $black;
  }
}

.switch {
  width: 100%;

  label {
    font-weight: $font-regular;
  }

  span {
    width: 100%;
  }
}

.removeButton,
.topRightButtons {
  position: absolute;
  top: $unit;
  z-index: 1;
}

.removeButton {
  left: $unit;
}

.presentButton {
  margin-left: $unit * 1.5;
}

.topRightButtons {
  right: $unit;
  display: flex;
  align-items: center;
}

.empty {
  @include secondary-text-color;
}
