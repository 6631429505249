@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.header {
  padding: $unit * 1.5 $unit * 2;
  border: none;
  flex-wrap: wrap;
}

.title {
  @include secondary-text-color;

  width: 100%;
  margin: 0;
  padding: 2px 0;
}

.backButton {
  margin-bottom: $unit * 2;
  margin-left: -10px;
}

.close {
  position: absolute;
  top: $unit * 1.5;
  right: $unit * 1.5;
}

@media screen and (min-width: $breakpoint-tablet) {
  .close {
    display: none;
  }

  .header {
    padding: $unit * 3 $unit * 4 $unit;
  }

  .title {
    padding: 2px $unit * 0.5;
    width: auto;
  }

  .backButton {
    margin-left: -6px;
  }
}
