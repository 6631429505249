@import "../../components/shared/colors";
@import "../../components/shared/vars";
@import "../../components/shared/typography";
@import "../../components/shared/theme";

.column {
  margin-right: $unit * 4;
  flex-shrink: 0;
  flex-grow: 1;

  &:last-of-type {
    margin-right: 0;
  }
}

.group {
  @include border-bottom;

  padding-bottom: $unit * 2.25;
  margin-bottom: $unit * 1.25;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.title {
  @include display-s;
  @include text-color;

  display: block;
  margin-bottom: $unit * 1.5;
}

.shortcut {
  display: flex;
  justify-content: space-between;
  margin-bottom: $unit * 2;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.action {
  @include font-m;
  @include text-color;

  line-height: $unit * 3;
}

.keys {
  display: flex;
}
