@import "../shared/typography";
@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";

.manageSeats {
  width: 400px;
  max-width: 100%;
}

.lineWrap {
  display: flex;
  justify-content: space-between;
  margin: $unit * 3 0;
}

.input {
  width: 50px;
  text-align: right;
}

.error {
  @include font-s;

  color: $red-50;
}

.errorBox {
  width: 100%;
  min-height: 118px;
  background: var(--secondary-content-background);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  color: var(--text-color);
}

.errorIcon {
  background-color: $orange-40;
  width: 32px;
  border-radius: 16px;
  margin-right: 16px;
}

.errorTitle {
  @include display-m;

  margin-bottom: 2px;
}

.errorDescription {
  @include font-s;

  margin-bottom: 12px;
}

.errorContact {
  @include display-xs;

  a {
    text-decoration: none;
  }
}

.disabled {
  opacity: 0.5;
}

.description {
  width: 80%;
}

.availableSeats {
  font-weight: $font-medium;
}
