@import "../shared/vars";
@import "../shared/mixins";

.wrap {
  @include secondary-content-background;

  width: 100%;
  overflow: hidden;
}

.dynamicPadding {
  @media screen and (min-width: $breakpoint-mobile) {
    @include dynamic-padding;
  }
}

.headerControls {
  justify-content: space-between;
}

.sort {
  margin-left: 0;
}
