@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.message {
  @include font-m;

  text-align: center;
  margin: $unit * 0.5;
}

.small {
  @include font-s;
  @include secondary-text-color;
}

.clickToDownload {
  position: absolute;
  display: block;
  bottom: 0;
  width: 100%;
}

.getNotified {
  background: $gray-10;
  text-transform: none;
  border-radius: $unit;
  display: block;
  text-align: center;
  border: $default-border;
  color: $black;
  text-decoration: none;
  padding: $unit * 2;
  margin-top: $unit * 2.5;
}

.button {
  margin-top: $unit * 2;
}
