@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/theme";
@import "../../shared/colors";

.inviteType {
  display: flex;
  flex-basis: 0;
  padding-top: 0;
  margin-bottom: $unit;
}

.inviteTypeInput {
  align-self: flex-start;
  margin: 3px 0.5ex;
}

.details {
  margin-left: $unit;

  h3 {
    @include font-m;

    font-weight: $font-medium;
    margin: 0;
    padding-bottom: $unit * 0.5;
  }

  p {
    margin: 0 0 $unit * 0.5;
  }
}

.nextButton {
  margin-left: $unit;
}
