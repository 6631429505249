@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/colors";
@import "../shared/theme";

.wrapper {
  position: relative;
}

.popoverForm {
  @include content-background;

  width: 400px;
  padding-bottom: $unit;
  box-shadow:
    0 0 0 1px $black-a05,
    0 4px 8px 0 $black-a10,
    0 2px 4px 0 $black-a10;
  border-radius: 6px;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 2000;
}

@include dark-theme {
  .wrapper {
    .popoverForm {
      @include modal-outline;

      position: absolute;
    }
  }
}

.formContainer {
  padding: $unit * 3 $unit * 4;

  @media (min-width: $breakpoint-mobile) {
    padding: $unit * 2 $unit * 3;
  }
}

@media (max-width: $breakpoint-mobile) {
  .mobileModalContent {
    padding: 0;
  }
}

.titleContainer {
  @include secondary-content-background;
  @include inset-border-bottom;

  padding: 14px $unit * 2;
}

.radioGroup {
  padding: 12px $unit * 0.5 0;
}

.radioLabel {
  padding: $unit * 0.5 0;
}

.radioItemIcon {
  margin-left: $unit * 0.5;
}

.inputContainer {
  margin-bottom: 12px;
}

.titleFlexLeft {
  overflow: hidden;

  .titleIcon {
    margin-right: $unit * 0.5;
  }

  .titleText {
    @include ellipsis;

    margin: 0;
  }
}

.dateWrapper {
  padding-left: $unit;
  white-space: nowrap;
}

.date {
  @include secondary-text-color;
}
