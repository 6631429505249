@import "../shared/vars";

.commentReply {
  position: relative;
  margin: $unit 0;
  display: flex;
  min-width: 100%;
}

.form {
  flex-grow: 1;
  position: relative;
  min-width: 0;
}

.avatar {
  margin-right: $unit;
}
