@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";

.pill {
  @include outer-keyboard-focus;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $unit * 1.5;
  background: $gray-20;
  flex-direction: row;
  min-width: $unit;
  height: $unit * 2;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0 $unit * 0.75;
  font-weight: $font-medium;

  &.clickable {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.disclosure {
  padding-right: $unit * 0.25;
  padding-left: $unit * 0.75;
}

.pillText {
  @include font-xs;

  flex: 0 0 auto;
  flex-wrap: nowrap;
}

.defaultStatus {
  position: relative;
  background: none;
  border: none;
  color: $gray-70;
}

.chevron {
  color: $gray-50;
  flex: 0 1 auto;
  height: inherit;
  width: $unit * 2;
}

.paleAllGood,
.adminBadge {
  background: $green-10;
}

.allgood {
  color: $white;
  background: $green-50;

  .chevron {
    color: $white;
  }
}

.warning {
  color: $white;
  background: $orange-50;

  .chevron {
    color: $white;
  }
}

.purple {
  color: $white !important;
  background: $purple;

  .chevron {
    color: $white;
  }
}

.pink {
  color: $gray-70;
  background: $pink-10;
}

.paleTint {
  background: $blurple-10;
}

.paleDanger {
  color: $white;
  background: $red-50;
}

.error {
  color: $white;
  background-color: $red-50;
}

.default {
  background: $gray-20;
  color: $gray-70;
}

@include dark-theme {
  .pill {
    color: $white;
    background: $gray-60;

    &.allgood {
      background: $green-50;
    }
  }

  .pillIcon {
    color: $white;
  }

  .paleAllGood {
    background: $green-70;
  }

  .adminBadge {
    background: $green-50;
  }

  .warning {
    background: $orange-50;
  }

  .purple {
    background: $purple;
  }

  .defaultStatus {
    background: none;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid $gray-60;
      border-radius: inherit;
      pointer-events: none;
      touch-action: none;
    }
  }

  .pink {
    color: $white;
    background: $pink-80;
  }

  .paleTint {
    background: $blurple-80;
  }

  .paleDanger {
    background: $red-50;
  }

  .default {
    color: $white;
    background: $gray-60;
  }
}
