@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.modalContainer {
  width: $unit * 62;
  height: $unit * 55;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.modalTop {
  padding: ($unit * 6) ($unit * 6) 0 ($unit * 6);
  width: 100%;
}

.modalTopInner {
  width: $unit * 50;
}

.modalBottom {
  @include border-top;

  width: 100%;
  padding: $unit * 2;
  height: $unit * 8;
}

.dropzone {
  position: relative;
  display: flex;
  border: 2px dashed $gray-40;
  border-radius: $unit;
  flex-direction: column;
  height: $unit * 18;
  justify-content: center;
  margin: ($unit * 2) 0;
  padding: $unit * 2;
  text-align: center;

  &.emojiPreview {
    @include secondary-content-background;
    @include box-shadow;

    border: none;
  }

  &.onRejected {
    border-color: $red-60;
    margin-bottom: $unit;
  }

  &.onDragOver {
    border-color: $blurple-60;

    .attachmentIcon {
      svg {
        fill: $gray-50;
      }
    }
  }

  &.disabled {
    @include input-background-disabled;
  }
}

.previewImg {
  width: $unit * 4;
  height: $unit * 4;
}

.attachmentValidation {
  @include secondary-text-color;
}

.validationError {
  margin-bottom: $unit;
  padding: 0;
  text-align: center;
}

.buttonRemove {
  @include box-shadow;
  @include content-background;

  border-radius: $unit * 0.5;
  position: absolute;
  top: $unit * 2;
  right: $unit * 2;
  width: $unit * 4;
  height: $unit * 4;

  button {
    width: 100%;
    height: 100%;
  }
}
