@import "../shared/vars";
@import "../shared/mixins";

.iconTransitionGroup {
  height: $unit * 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusIcon {
  width: $unit * 3;
  height: inherit;
  align-items: center;
}
