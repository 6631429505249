@import "../shared/vars";
@import "../shared/mixins";

.files {
  @include secondary-content-background;
  @include border;

  border-radius: $unit * 0.5;
  padding: 0 $unit;
  margin-bottom: 0;
  min-height: 58px;

  li {
    padding-top: $unit * 0.5;
    padding-bottom: $unit * 0.5;
  }
}
