@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/colors";
@import "../../shared/mixins";
@import "../../shared/theme";

.targetLayerIcon {
  margin: 0 0 0 $unit;
}

.propertyRow {
  min-width: 0;
  margin: 0 $unit * 2;
  padding: $unit 0;

  &.grouped {
    border-top: none;
    padding: $unit * 0.5 0;
  }
}

.targetLayer {
  cursor: pointer;
  height: $unit * 4;
  padding: 0 $unit;
  margin: $unit * -1;
  border-radius: $unit;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.label {
  @include font-xs;
  @include secondary-text-color;

  font-weight: $font-regular;
  text-transform: uppercase;
  margin-bottom: $unit;
}

.value {
  @include font-s;
  @include font-mono;
  @include ellipsis;

  position: relative;
  top: 1px;
}
