@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.settingsForm {
  height: 100%;
}

.emptyCard {
  margin-bottom: 0;
}

.formSection {
  div:nth-child(3)::after {
    border: none;
  }

  &.empty {
    height: 100%;
    padding-bottom: 0;

    .emptyCard {
      min-height: 35 * $unit;
      height: calc(100% - 24px);
    }
  }
}

.row {
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
}

.headerLegend {
  @include secondary-content-background;

  padding: 0 ($unit * 2);
}

.legendItem {
  @include font-xs;
  @include secondary-text-color;
}

.inputSearch {
  width: $unit * 24;
}

.rowItem {
  flex: 1;
}

.emojiImgColumn {
  flex-grow: 1;
}

.emojiNameColumn {
  flex-grow: 5;
  flex-flow: wrap;
}

.authorColumn {
  flex-grow: 5;
}

.dateColumn {
  flex-grow: 4.5;
}

.emojiWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.avatarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $unit * 4;
  height: $unit * 4;

  img {
    border-radius: $unit * 2;
  }
}

.emoji {
  width: $unit * 4;
  height: $unit * 4;
}

.emojiName {
  @include display-m;

  width: 100%;
  word-break: break-all;
}

.authorName {
  @include ellipsis;

  margin: 0 $unit;
  font-weight: $font-medium;
}

.addEmojiButton {
  margin-left: $unit * 2;
}

.sortButton {
  border-radius: 0;
  display: flex;
  width: 100%;
}

.headerItemPadding {
  padding: $unit 0 $unit ($unit * 1.5);

  &.noIcon {
    padding: $unit 0 $unit $unit;
  }
}

.rowItemPadding {
  padding: 0 0 0 ($unit * 2);
}

.date {
  @include font-s;
  @include secondary-text-color;
}

.removeEmoji {
  &:hover:not(.disabled) {
    background: none;
  }
}

@include dark-theme {
  .headerLegend {
    @include border-left;
    @include border-right;
  }

  .sortButton:hover {
    border-radius: 0;
  }
}

.smileyIcon {
  svg {
    fill: $gray-30;
  }
}

.noResults {
  @include secondary-text-color;

  margin: ($unit * 8) 0;
}
