@import "../../shared/mixins";
@import "../../shared/vars";
@import "../../shared/colors";
@import "../../shared/typography";
@import "../../shared/theme";

.searchWrapper {
  padding: $unit * 1.5;
}

.body {
  border-radius: 6px;
  padding-bottom: $unit * 0.5;
}

.emptyExplanation {
  padding: 0 $unit * 2;
  padding-bottom: $unit;
  max-width: 100%;
}

.searchTerm {
  font-weight: $font-medium;
  word-wrap: break-word;
}

.selectorContainer {
  max-width: 244px;
  max-height: none;
}

.submenuContainer {
  max-width: 240px;
}
