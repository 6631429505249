@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.container {
  @include border-bottom;
  @include content-background;

  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}

.headerTop {
  @include border-bottom;

  padding: $unit * 2;
  flex-grow: 1;
  display: flex;
  min-width: 0;
}

.headerBottom {
  padding: $unit * 2;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.shadowWrapper {
  position: relative;
  z-index: 1;
}

.shadow {
  position: absolute;
  z-index: 0;
  width: 99%;
  bottom: 0;
  height: 10px;
  left: 0.5%;
  border-radius: 100px / 2px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.largeContainer {
  .container {
    @include dynamic-padding;

    height: $unit * 9;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .headerTop {
    flex-grow: 1;
    padding: 0;
    width: auto;
    border-bottom: none;
  }

  .headerBottom {
    flex-shrink: 1;
    width: auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left {
    order: 1;
  }

  .middle {
    order: 2;
  }

  .right {
    order: 3;
  }
}

.left {
  flex-shrink: 0;
}

.rightInner {
  max-width: 100%;
}
