@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/Input/style";

.inputs {
  padding: $unit * 2 $unit * 4 $unit * 3;
}

.splitInputs {
  display: flex;

  .stateInput {
    margin-right: $unit * 0.5;
  }

  .zipInput {
    margin-left: $unit * 0.5;
  }
}

.stateInput,
.zipInput {
  width: 50%;
}

.input {
  composes: text;
  padding: 0;
}

.withError {
  composes: inputError;
}

.error {
  margin-bottom: $unit * 2;
  margin-right: -$unit * 2;
  margin-left: -$unit * 2;
  margin-top: -$unit;
  text-align: center;
}

.footer {
  padding: $unit * 3 $unit * 4;

  @media screen and (min-width: $breakpoint-mobile) {
    border-top: $default-border;
  }
}
