@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.empty {
  border-radius: 6px;
  align-items: center;
  justify-content: center;
}

.item {
  padding: $unit $unit * 2;
  box-shadow: 0 1px 0 0 $black-a10;

  &:last-child {
    box-shadow: none;
  }
}

.controls {
  @include font-m;

  font-weight: $font-regular;
  height: $unit * 3;
  margin-top: $unit * 1.5;

  @media screen and (min-width: $breakpoint-mobile) {
    margin-bottom: -$unit;
  }
}

.itemLink {
  @include font-m;
}

.itemDetails {
  @include font-s;
  @include secondary-text-color;
}

@include dark-theme {
  .item {
    box-shadow: 0 1px 0 0 $white-a15;

    &:last-child {
      box-shadow: none;
    }
  }

  .controls {
    @include secondary-text-color;
  }
}

.note {
  margin-top: $unit * 0.5;
  font-weight: $font-regular;
}

.checkbox {
  margin-right: $unit;

  /* Checkboxes have a default align-self: start at mobile sizes */
  align-self: center !important;
}

.checkboxWrapper {
  margin-bottom: 0;
  margin-right: $unit;
}

.checkboxLabel {
  padding: 0;
  padding-top: $unit * 0.5;
}

.selectedInfo {
  margin-left: $unit;
  margin-right: $unit * 1.5;
}
