@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/colors";
@import "../shared/theme";
@import "../shared/animations";

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  &.hideControls {
    cursor: none;

    // extra specificity overrides the zoom in/out cursor on the layer preview
    .layerCanvasPresentation img {
      cursor: none;
    }
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: 100vh;
  height: 100%;
}

.preview {
  background-color: $gray-10;
  display: flex;
  position: relative;
  width: 100%;
  overflow: auto; // for tooltip placement

  .darkMode & {
    background-color: $black;
  }
}

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translate(-20%, -50%);
  }

  to {
    opacity: 1;
    transform: translate(0%, -50%);
  }
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translate(20%, -50%);
  }

  to {
    opacity: 1;
    transform: translate(0%, -50%);
  }
}

.sidebar {
  @media (min-width: $breakpoint-tablet) {
    max-height: 100%;
  }
}

.sidebarContent {
  @include content-background;

  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;

  @media (max-width: $breakpoint-tablet) {
    transform: none;
    transition: none;
  }
}

.showComments {
  .sidebarContent {
    visibility: visible;
    opacity: 1;

    @media (max-width: $breakpoint-tablet) {
      min-width: 100vw;
    }
  }
}

.comments {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  min-height: 0;
}

.commentForm {
  flex-shrink: 0;
  padding-top: $unit * 1.5;
  padding-right: $unit * 2;
  padding-left: $unit * 2;

  @media (max-width: $breakpoint-mobile) {
    padding-bottom: $unit * 2;
  }
}

.inspectButton {
  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

@include dark-theme {
  .layerCanvasPresentation::after {
    box-shadow: none;
  }
}

// Offset by 1px https://github.com/goabstract/JIRA/issues/3458#issuecomment-368176963
.hotspotsOffset {
  position: absolute;
  top: -1px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fullscreenDesktop {
  display: flex;
  animation: fadeIn 1s;
  width: 100%;
  height: 100%;
}

.fullscreenWeb {
  @include secondary-content-background;

  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.topLeftControls,
.topRightControls,
.bottomLeftControls,
.bottomRightControls {
  z-index: 1;
  opacity: 1;
  transition:
    opacity 200ms $super-ease-out,
    visibility 200ms $super-ease-out;
  visibility: visible;

  .hideControls & {
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }

  .button {
    border-radius: $unit * 0.5;
  }
}

.topLeftControls {
  position: absolute;
  top: $unit * 4.5;
  left: $unit * 4.5;

  @media (max-width: $breakpoint-mobile) {
    top: $unit * 1.5;
    left: $unit * 1.5;
  }
}

.bottomLeftControls {
  position: absolute;
  bottom: $unit * 4.5;
  left: $unit * 4.5;

  @media (max-width: $breakpoint-mobile) {
    bottom: $unit * 1.5;
    left: $unit * 1.5;
  }
}

.bottomRightControls {
  position: absolute;
  bottom: $unit * 4.5;
  right: $unit * 4.5;
  display: grid;
  grid-gap: $unit * 1.5;
  grid-auto-flow: column;

  @media (max-width: $breakpoint-mobile) {
    bottom: $unit * 1.5;
    right: $unit * 1.5;
  }
}

.topRightControls {
  position: absolute;
  top: $unit * 4.5;
  right: $unit * 4.5;
  display: grid;
  grid-gap: $unit * 1.5;
  grid-auto-flow: column;

  @media (max-width: $breakpoint-mobile) {
    top: $unit * 1.5;
    right: $unit * 1.5;
  }
}

.previousLayerButton {
  margin-right: $unit;
}

.unstyledInput {
  background: none !important; // For Input/styles
  box-shadow: none !important; // For Input/styles
  min-height: $unit * 2.5;
  display: flex;
  padding: $unit * 0.25 $unit * 0.5 0 $unit;
  margin: 0;
  cursor: pointer;

  &::placeholder {
    color: $gray-60;
  }
}

@include dark-theme {
  .unstyledInput {
    &::placeholder {
      color: $gray-30;
    }
  }
}

.unstyledInputWrapper {
  background: none;
  margin: 0;
  display: flex;
  align-items: center;
}

.unstyledButton {
  position: relative;
  display: block;
  appearance: none;
  background: none;
  border: 0;
  height: $unit * 2.75;
  width: 100%;
  padding: 0;
  text-align: left;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.newCommentWithAnnotationButton {
  margin-right: $unit * 0.5;
}

@media (max-width: $breakpoint-mobile) {
  .quickActions {
    display: none;
  }
}
