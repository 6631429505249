@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

%toolbar {
  display: flex;
  align-items: center;
  min-height: $unit * 6;
  padding: 0 $unit * 1.5;

  & > button + button {
    margin-left: $unit; // Automatically space button elements
  }
}

.layerCompare {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.condensedTab {
  display: flex;
  align-items: center;
}

.compareContainer {
  flex: 1;
  display: flex;
  position: relative;
}

.offscreen {
  transform: translate(-10000px, -10000px);
}

.time {
  @include font-xs;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: $unit * 0.5;
  border-bottom-right-radius: 3px;
  background-color: $black-a25;
  color: $white;
  text-transform: uppercase;
  font-weight: $font-medium;
}

.sideBySide {
  flex: 1;
  display: flex;
  flex-direction: row;

  .compareContainer + .compareContainer {
    box-shadow: -1px 0 $black-a10;
  }

  .layerCanvas,
  .compareLayerCanvas {
    flex: 1;
    display: flex;
    width: 50%;
  }
}

.overlay {
  flex: 1;
  display: flex;

  &.overlayDisabled {
    .compareTime {
      display: none;
    }

    .compareLayerCanvas {
      visibility: hidden;
    }

    .invertFilter {
      filter: none;

      .layerCanvas {
        mix-blend-mode: normal;
      }
    }
  }

  .invertFilter {
    flex: 1;
    filter: invert(100%);

    .layerCanvas {
      mix-blend-mode: difference;
    }
  }

  .layerCanvas,
  .compareLayerCanvas {
    display: flex;
    position: absolute; // inside .compareContainer
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .layerCanvas {
    z-index: 1;
  }
}

.error {
  color: $gray-50;
}

.layerCompareFooter {
  @include inset-border-top;

  min-height: $unit * 6;
}

.footerToolbarCenter,
.footerToolbarRight {
  @extend %toolbar;
}

@include dark-theme {
  .overlay {
    .invertFilter {
      .layerCanvasInner {
        &::after {
          box-shadow: none;
        }
      }
    }
  }

  .sideBySide {
    .compareContainer {
      .previousCommitCanvas::after {
        box-shadow: -1px 0 0 0 $black inset;
      }

      .latestCommitCanvas::after {
        box-shadow: -1px 0 0 -1px $black inset;
      }
    }
  }
}
