@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";

.helper,
.message,
.error {
  @include font-s;

  font-weight: $font-regular;
  line-height: $unit * 2;
}

.helper,
.message {
  color: $gray-50;
}

.create {
  .helper {
    margin-bottom: -$unit * 0.5;
  }
}
