@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";

.gridContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.horizontalGuide {
  height: 100%;
  width: 0;
  pointer-events: all;
  position: absolute;
  border-left: 1px solid $pure-red;

  &:hover {
    border-left-color: $pink-40;
  }
}

.verticalGuide {
  width: 100%;
  height: 0;
  position: absolute;
  border-top: 1px solid $pure-red;

  &:hover {
    border-top-color: $pink-40;
  }
}

.verticalGuideHoverArea {
  position: absolute;
  top: -10px;
  width: 100%;
  height: 20px;
  pointer-events: all;

  &:hover + .guideLabel {
    display: block;
    background-color: $pink-40;
  }
}

.horizontalGuideHoverArea {
  position: absolute;
  left: -10px;
  width: 20px;
  height: 100%;
  pointer-events: all;

  &:hover + .guideLabel {
    display: block;
    background-color: $pink-40;
  }
}

.guideLabel {
  display: none;

  @include font-xs;
  @include font-mono;

  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2px 4px;
  background-color: $pure-red;
  color: $white;
  border-radius: 4px;
  font-size: 10px;
  line-height: normal;
  text-transform: lowercase;
  z-index: 1000;
}
