@import "../../shared/vars";
@import "../../shared/typography";

.form {
  height: 100%;
}

.colorSelectorWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 12px;
}

.colorPicker,
.assetsWrapper {
  width: 100%;
}

.note {
  @include font-s;
  @include secondary-text-color;

  padding: 0 $unit * 2;
}

@media screen and (min-width: $breakpoint-mobile) {
  .colorSelectorItem {
    padding: 12px $unit * 2;
  }

  .colorSelectorWrap {
    margin-top: 0;
  }

  .note {
    padding: 0 $unit * 4;
    max-width: $breakpoint-mobile;
  }
}

.inputSwitch {
  margin-bottom: 0;
}

.inputHelper {
  @include font-s;
}

.submit {
  justify-content: flex-end;
}
