@import "../shared/colors";
@import "../shared/mixins";

.item {
  @include text-color;
  @include font-m;
  @include content-background;

  display: block;
  align-items: center;
  width: 100%;
  border: 0;
  padding: $unit * 0.5 $unit * 1.5;
  min-height: $unit * 3;
  text-decoration: none;
  margin: 0;

  &:focus {
    outline: none;
  }
}

.inner {
  max-width: 100%;
}

.submenuBody {
  overflow-y: auto;
}

.truncate {
  white-space: nowrap;

  .text {
    overflow: hidden;

    > *:first-child {
      @include ellipsis;
    }
  }
}

.default {
  // This covers the case of hovering over a menu item's submenu.
  // In that case, we want the background of the menu item to be blue (active),
  // not grey with white text.
  &:not(.active):hover,
  &:focus {
    @include cell-hover-background;
  }

  &:active,
  &:active:hover {
    background: $black-a10;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.active,
.active:focus,
.activeHover:hover,
.activeHover:focus {
  background-color: $blurple-60;
  color: $white;

  span {
    color: $white;
  }

  .icon {
    * {
      fill: $white;
    }
  }
}

.active:active,
.activeHover:active {
  background: darken($blurple-60, 5%);
}

.hasIcon {
  .text {
    margin-left: $unit * 0.5;
    overflow: hidden;
  }
}

.hasAvatar {
  .text {
    margin-left: $unit;
    overflow: hidden;
  }
}
