@import "../shared/vars";

.more,
.new {
  height: $unit * 4;
  display: flex;
  align-items: center;
}

.spinner {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -$unit * 0.5;
}

.comments {
  margin-left: -$unit * 3;

  @media screen and (min-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}
