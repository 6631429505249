@import "../shared/vars";

.group {
  z-index: 1;
}

.zoomToFitButton {
  font-feature-settings: "tnum";
  min-width: 6 * $unit;
}

.incrementButton span,
.decrementButton span {
  margin: 0 !important; // Remove margin from Button.icon
}
