@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

.heading {
  margin-bottom: $unit;
}

.radioLabel {
  padding: $unit * 0.5 0;
}

.checkbox {
  margin: $unit * 0.5 0;
  margin-right: $unit;
}

.checkboxWrapper {
  padding: $unit * 0.5 0;
  margin-bottom: 0;
}

.checkboxLabel {
  font-weight: $font-regular;
  padding: 0;
}

.disabled {
  @include secondary-text-color;
  @extend .checkboxLabel;

  opacity: 0.5;
}

.notPermitted {
  @include font-s;

  margin-left: 20px;
  margin-top: -($unit * 0.5);
  margin-top: -($unit * 2);
  margin-bottom: $unit * 1.5;
}
