@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";
@import "../shared/mixins";

.switch {
  width: $unit * 4;
  height: $unit * 2;
  position: relative;
  order: 1;

  div {
    @include quick-transition(background);

    width: 100%;
    height: 100%;
    border-radius: $unit;
    background: $gray-40;
    cursor: pointer;
    min-width: $unit * 4;
    content: "";

    &::after {
      content: "";
      position: absolute;
      top: $unit * 0.25;
      left: $unit * 0.25;
      width: $unit * 1.5;
      height: $unit * 1.5;
      border-radius: 50%;
      background: $white;
      transition: transform 0.1s cubic-bezier(0.5, 0, 0.25, 1.6);
    }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    margin: 0;
    cursor: pointer;
  }

  input:checked ~ div {
    background: $green-40;

    &::after {
      transform: translateX($unit * 2);
    }
  }

  input:focus ~ div {
    box-shadow:
      0 0 0 2px white,
      0 0 0 3px $blurple-60 !important;
  }

  input:disabled {
    &,
    ~ div {
      cursor: default;
    }

    ~ div::after {
      opacity: 0.5;
    }

    &:checked ~ div {
      opacity: 0.5;

      &::after {
        opacity: 1;
      }
    }
  }
}

@include dark-theme {
  .switch {
    input:not(:checked) ~ div {
      background: $gray-50;
    }

    input:focus ~ div {
      box-shadow:
        0 0 0 2px $gray-70,
        0 0 0 3px $blurple-60 !important;
    }
  }
}

.inputWrapper {
  line-height: $unit * 2;

  label {
    order: 0;
    margin-right: $unit * 2;
  }
}
