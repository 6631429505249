@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";
@import "../shared/typography";

.introText {
  padding-top: $unit * 2;
}

.formNotice {
  margin-top: $unit * 3;
}

.inputWrapper {
  margin: $unit * 2 0;
}

.termsNote {
  @include font-m;
  @include text-color;

  padding-left: $unit;
}

.link {
  @include text-color;

  text-decoration: underline;
}

.footer {
  z-index: 2;
  padding: 0 $unit * 4 $unit * 3;
}

@media screen and (min-width: $breakpoint-mobile) {
  .overlay {
    z-index: $depth-notifications + 1;
  }
}

.billingForm {
  margin-left: -$unit * 4;
  margin-right: -$unit * 4;
  margin-bottom: -$unit * 5;
}

.fullHeightModal {
  max-height: 100%;
}

.priceCalculation {
  @include secondary-text-color;
  @include font-s;
  @include border-bottom;

  display: block;
  text-align: center;
  padding-left: $unit * 4;
  padding-right: $unit * 4;

  p {
    margin-bottom: $unit;
  }
}

.billingModalHeading {
  text-align: center;
}
