@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.item {
  padding: $unit * 1.5 $unit * 2;
  height: $unit * 7;
  position: relative;
}

.content {
  padding-left: $unit * 1.5;
  padding-right: $unit;
}

.heading {
  width: 100%;
  height: 18px;
}

.channelName {
  @include font-s;

  flex-basis: 50%;
  text-align: left;
}

.disabled {
  @include secondary-text-color;
}

.menu {
  box-shadow: none;
  position: absolute;
  min-width: 0;
  top: $unit * 5;
  right: 12px;
  z-index: 1;
}

.buttonWrap {
  display: inherit;
}

@media screen and (min-width: $breakpoint-mobile) {
  .content {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .heading {
    width: auto;
  }
}
