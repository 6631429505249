@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.helmet {
  @include font-m;
  @include text-color;
  @include inset-border-bottom;
  @include secondary-content-background;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $unit * 1.5;
  min-width: 0;
  border-radius: 6px 6px 0 0;
}

.shareIcon {
  margin-right: $unit * 0.5;
}

.content {
  @include font-s;

  padding: $unit * 2;
}

.info {
  margin: $unit $unit * 2 0;
}

.noMargin {
  margin: 0;
  padding-bottom: $unit;
}

.actions {
  padding: $unit * 2;
  padding-top: 0;
}

.settingsButton {
  margin-bottom: $unit * 1.5;
}

.layerInfo {
  margin: $unit 0;
}

.privateInfo {
  margin-bottom: $unit;
}

.icon {
  margin-right: $unit * 0.5;
  margin-top: 1px;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: $unit * 0.25;
}
