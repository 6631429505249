@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

@keyframes flash {
  0% {
    background-color: transparentize($highlight, 0.75);
  }

  50% {
    background-color: transparentize($highlight, 0.75);
  }

  100% {
    background-color: transparentize($highlight, 1);
  }
}

.container {
  list-style: none;

  @media (hover) {
    .menu {
      opacity: 0;
    }

    .actions {
      opacity: 0.4;
    }

    &:hover {
      .actions,
      .comment .reactionButton {
        opacity: 1;
      }

      .comment.tintBackgroundOnHover {
        background: $gray-10;
      }

      .unresolveButton {
        display: block;
      }

      .menu {
        opacity: 1;
      }
    }
  }
}

@include dark-theme {
  .container {
    @media (hover) {
      &:hover {
        .comment.tintBackgroundOnHover {
          background: $gray-80;
        }
      }
    }
  }
}

.container:focus-within .menu {
  opacity: 1;
}

.comment {
  @include font-m;
  @include quick-transition(background-color);

  display: flex;
  position: relative;
  padding: $unit $unit * 2;
  width: 100%;
  background-color: inherit;

  .avatar,
  .meta,
  .title,
  .body,
  .markdown,
  .actions,
  .reactionButton {
    @include quick-transition(opacity);
  }

  .actions {
    &:focus {
      opacity: 1;
    }
  }

  .floatingControl {
    transform: translate3d($unit * 0.5, -$unit * 1.75, 0);
  }

  @media (max-width: $breakpoint-mobile) {
    .actions {
      transform: translateY(-$unit);
      opacity: 1;

      &:hover,
      &:focus,
      &:active {
        border: 0;
        background: none;
      }
    }

    .floatingControl {
      padding: 0;
      box-shadow: none;
      background: none;
      transform: translateX($unit * 0.5);
    }
  }

  .reactions {
    padding-top: $unit * 0.25;
  }

  .body {
    width: 100%;
  }

  .markdown {
    word-break: break-word;
  }
}

.deleted {
  .title {
    @include placeholder-text-color;
  }

  .reactions {
    opacity: 0.5;
  }
}

.pending {
  .avatar,
  .meta,
  .title,
  .body,
  .markdown {
    opacity: 0.6;
  }
}

.disabled {
  pointer-events: none;

  .avatar,
  .meta,
  .title,
  .body,
  .markdown,
  .reactions {
    opacity: 0.5;
  }

  .replyButton,
  a {
    @include placeholder-text-color;
  }
}

.highlighted {
  animation: flash 2000ms linear;
}

.forceHighlight {
  background-color: transparentize($highlight, 0.75);
  animation: none;
}

.editing {
  padding-bottom: 0;

  .menu {
    opacity: 0;
    right: -100px;
  }

  .meta {
    position: absolute;
    bottom: 14px;
    left: $unit * 2;
  }
}

.reply {
  padding-left: $unit * 4.5;

  .avatar {
    margin-right: $unit;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    padding-left: $unit * 7.5;

    .replyToggle {
      visibility: hidden;
    }

    &:not(.disabled):hover {
      .replyToggle {
        visibility: visible;
      }
    }
  }
}

.activity {
  padding-left: 0;
  padding-right: 0;

  &:not(.reply) {
    margin-top: -6px;

    &.editing {
      margin-top: -$unit * 3.5;
    }

    .menu {
      top: -$unit;
    }
  }

  &.deleted {
    margin-top: 0;
    padding: ($unit * 0.5) 0;
  }

  .menu {
    right: 0;
  }
}

.selectable {
  text-decoration: none;

  &:focus,
  &:active {
    outline: none;
  }
}

.selected {
  background-color: $blurple-10;
}

@include dark-theme {
  .selected {
    background-color: $blurple-90;
  }
}

.avatar {
  top: 2px;
  margin-right: $unit * 1.5;
}

.dot {
  margin-left: $unit * 0.5;
  margin-right: $unit * 0.5;
}

.menu {
  position: absolute;
  top: $unit;
  right: $unit * 2;
}

.replyButton {
  margin-top: -1px;
  margin-bottom: -1px;
}

.title {
  padding-right: $unit * 4.5;
  margin: 0;
  order: 1;

  @include text-color;

  strong {
    font-weight: $font-medium;
  }
}

.meta {
  @include font-s;
  @include secondary-text-color;

  order: 3;
  min-height: $unit * 3;
  width: 100%;

  span {
    line-height: 22px;
  }
}

.title,
.meta {
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.retryButton {
  margin-right: $unit;
}

.reactionButton {
  $unfocused-opacity: 0.5;
  $focused-opacity: 1;

  display: inline-flex;
  opacity: $unfocused-opacity;
  height: $unit * 3;

  @include focus-visible using ($slot) {
    @if $slot == focus {
      opacity: $focused-opacity;
    } @else if $slot == reset {
      opacity: $unfocused-opacity;
    }
  }
}

.pinnedMeta,
.resolvedMeta {
  @include multiline-ellipsis(1);
}

.pinnedMetaItem,
.resolvedHeaderItem {
  display: inline-block;
  margin-right: $unit * 0.5;
}

.resolvedMeta {
  @include font-s;
  @include secondary-text-color;

  display: inline-block;
  white-space: nowrap;
}

.resolved {
  background: $gray-10;
}

.resolvedHeader {
  padding: $unit $unit * 2 0;
  background: $gray-10;
  height: $unit * 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pinButton,
.resolveButton {
  margin-right: $unit * 0.5;
}

@include dark-theme {
  .resolved,
  .resolvedHeader {
    background: $gray-80;
  }
}

.unresolveButton {
  @media (hover) {
    display: none;
  }
}

.metaLink {
  cursor: pointer;
}

.titleLink {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
