@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";

.separator {
  display: inline-block;
  width: $unit * 1.5;
  height: $unit * 3;
  border-left: 1px solid $black-a10;
  margin-left: $unit * 1.5;
  margin-right: 1px;
}

@include dark-theme {
  .separator {
    border-color: rgba(255, 255, 255, 0.15);
  }
}
