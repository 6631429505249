@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.content {
  text-align: center;
}

.fileIcon {
  margin: 0 auto;
}

.fileName {
  color: $gray-70 !important;
  font-size: 18px;
}

.fileLink {
  display: flex;
  align-items: center;
  margin-bottom: $unit;
  justify-content: center;
  height: $unit * 3;
  text-decoration: none;
}

.warning {
  @include font-s;

  display: flex;
  align-items: center;
  color: $orange-50;
  margin-top: $unit * 0.5;
  font-weight: $font-medium;

  a {
    color: $orange-50;
    text-decoration: underline;
  }
}

.description {
  @include font-s;

  color: $gray-50;

  a {
    color: $gray-50;
    text-decoration: underline;

    &:hover {
      color: darken($gray-50, 10%);
    }
  }
}

.warningIcon {
  margin-right: $unit * 0.5;
}
