@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.note {
  text-align: center;

  p {
    margin: $unit 0 0 0;
  }

  p:first-of-type {
    margin-top: 0;
  }
}

.secondaryText {
  @include font-s;
  @include secondary-text-color;
}
