@import "../shared/colors";
@import "../shared/mixins";

.gridContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.column {
  width: 0;
  height: 100%;
  position: absolute;
  border-left: 1px solid rgba($pure-red, 0.5);
}

.row {
  width: 0;
  width: 100%;
  position: absolute;
  border-top: 1px solid rgba($pure-red, 0.5);
}

.sectionMarker {
  border-color: rgba($pure-red, 1);
}
