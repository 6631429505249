@import "../../shared/mixins";

.actions {
  @include dynamic-padding(left right);

  padding-top: $unit * 2;
  margin-bottom: $unit * 2;
  width: 100%;
}

.backButton {
  margin-left: -$unit;
  // Ensure that we maintain our link color since we aren't changing the color of the icon.
  color: $blurple-60 !important;
  color: var(--link-color) !important;
}

.commentsList {
  @include dynamic-padding(left right bottom);

  margin: $unit * -2;
}

.content {
  max-width: 776px;
  width: 100%;
  margin: 0 auto;
}
