@import "../shared/colors";
@import "../shared/theme";
@import "../shared/mixins";

.button {
  @include content-background;
  @include border;
  @include font-xs;

  border-right: 0;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  outline: none;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: $font-medium;

  > span {
    height: $unit * 3;
  }

  &:first-child {
    border-top-left-radius: $unit * 0.5;
    border-bottom-left-radius: $unit * 0.5;
  }

  &:last-child {
    @include border-right;

    border-top-right-radius: $unit * 0.5;
    border-bottom-right-radius: $unit * 0.5;
  }
}

.inner {
  padding: 0 $unit;
  line-height: $unit * 2;
  min-width: $unit * 5;
}

.inactive {
  @include secondary-text-color;

  &:hover {
    .inner {
      @include cell-hover-background;
    }
  }

  &:active {
    .inner {
      background: $black-a10;
    }
  }
}

@include dark-theme {
  .inactive {
    color: $gray-50;

    &:active {
      .inner {
        background: $black-a50;
      }
    }
  }
}

.active {
  color: $white;
  border-color: transparent;
  border-right: 1px solid transparent;

  &:last-child {
    border-right: 1px solid transparent;
  }

  & + .inactive,
  & + .disabled {
    border-left: 0;
  }

  svg {
    fill: $white;
  }

  &.neutral {
    background: $gray-50;
  }

  &.allgood {
    background: $green-50;
  }

  &.danger {
    background: $red-50;
  }

  &.primary {
    background: $blurple-60;
  }
}

@include dark-theme {
  .active.neutral {
    background: $gray-60;
  }
}

.disabled {
  opacity: 0.5;
}

.buttonGroup {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
