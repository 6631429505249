@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";

.settingsForm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.formContent {
  position: relative;

  &.disabled {
    opacity: 0.65;
  }
}

.scrollable {
  width: 100%;
  padding-bottom: $unit * 3;

  &.noExtraPadding {
    padding-bottom: 0;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .scrollable {
    @include scrollable;

    position: absolute;
  }
}
