@import "../../shared/mixins";
@import "../../shared/typography";
@import "../../shared/vars";

.sidebar {
  @include secondary-content-background;

  margin: ($unit * 3) ($unit * 2);
  border-radius: 6px;
}

.sidebarHeading {
  padding: $unit * 2;
  padding-bottom: 0;
}

.message {
  @include secondary-text-color;

  padding: 0 ($unit * 2);
  margin-bottom: $unit * 1.5;
}

.file {
  margin-left: $unit;
  margin-right: $unit;
  padding: 0;
  width: calc(100% - #{$unit * 2});
  cursor: pointer;

  &:last-child {
    margin-bottom: $unit * 2;
  }
}
