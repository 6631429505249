@import "../shared/vars";
@import "../shared/theme";
@import "../shared/typography";
@import "../shared/mixins";

.breadcrumb {
  @include font-s;

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  flex-shrink: 0; // Need this fix a safari bug that truncates/shrinks nested flex items.
}

.crumb {
  color: $gray-60;
  text-decoration: none;

  @include ellipsis;

  &:hover {
    color: $gray-60;
    text-decoration: underline;
  }
}

@include dark-theme {
  .crumb {
    color: $gray-30;

    &:hover {
      color: $gray-30;
    }
  }
}
