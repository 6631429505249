@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.headerControls {
  justify-content: space-between;
}

.sort {
  @media (max-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}

.wrap {
  @include secondary-content-background;

  width: 100%;
  overflow: hidden;
}
