@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.expand {
  @include font-xs;

  height: $unit * 6;
  line-height: $unit * 6;

  .collapse {
    transform: rotate(180deg);
  }

  @media screen and (min-width: $breakpoint-mobile) {
    @include border-top;
  }

  &.hideBorder {
    border-top: 0;
  }
}

.expandLink {
  @include link;
  @include outer-keyboard-focus;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  height: $unit * 4;
  font-weight: $font-medium;
  width: 100%;
  cursor: pointer;

  &::before {
    border: 0 !important;
  }

  &:hover:not(:disabled) {
    color: $blurple-60;
    color: var(--link-color);
    background: rgba(0, 0, 0, 0.05);

    [data-theme="dark"] & {
      background: rgba(0, 0, 0, 0.25);
    }
  }
}
