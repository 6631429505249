@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.organizations {
  width: 100%;
  margin: ($unit * 3) 0;
}

.createOrganizationButton {
  margin-top: $unit * 3;
}
