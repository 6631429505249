@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

%toolbar {
  display: flex;
  align-items: center;
  min-height: $unit * 6;
  padding: 0 $unit * 1.5;

  & > button + button {
    margin-left: $unit; // Automatically space button elements
  }
}

.layerShell {
  @include content-background;

  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.headerContainer {
  @include inset-border-bottom;
}

.mainHeader {
  @media (max-width: 960px) {
    display: none;
  }
}

.mobileHeader {
  @media (min-width: 960px) {
    display: none;
  }
}

.closeButton {
  margin-right: $unit * 1.5;
}

.closeButtonDivider {
  height: $unit * 3;
  width: 1px;
  background: $black-a15;
  margin-right: $unit * 1.5;
}

@include dark-theme {
  .closeButtonDivider {
    background: $white-a15;
  }
}

.mainToolbarLeft {
  @extend %toolbar;

  flex: 1;
  min-width: 0;
  position: relative;
}

.mainToolbarCenter {
  @extend %toolbar;

  @media (max-width: 960px) {
    display: none;
  }
}

.mainToolbarRight {
  @extend %toolbar;

  @media (max-width: 960px) {
    display: none;
  }
}

.headerLayerName {
  @include secondary-text-color;

  display: flex;
  align-items: center;
  min-width: 0;
}

.headerLayerIcon {
  margin-right: 4px;
}

.commitTime {
  @include font-s;

  white-space: nowrap;
}

.commitTimeLink {
  @include secondary-text-color;

  text-decoration: none;
}

.commitTimeLink:hover {
  text-decoration: underline;
}

.layerNameLabel {
  @include font-m;
  @include ellipsis;
  @include text-color;

  font-weight: $font-medium;
}

.layerSetButton {
  margin-left: $unit;

  span {
    padding: 0;
    margin: 0 !important;
  }

  button {
    max-width: $unit * 3;
  }

  &:hover {
    cursor: pointer;
  }

  .layerSetLabel {
    @include text-color;

    padding: 0 $unit * 0.5;
  }
}

.openFileButton {
  margin-left: $unit;
  min-width: 72px;
}

.shareContainer {
  margin-left: $unit;
}

.layerMenuButton {
  margin-left: $unit;
}

.goToLatestPillBadge {
  @include outer-keyboard-focus;

  margin-left: $unit;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  cursor: default;

  &:hover,
  &:active {
    color: white;
  }
}

.goBackToSymbolInstanceButton {
  position: absolute;
  top: $unit * 3;
  left: $unit * 3;
}

.navigation {
  display: grid;
  grid-auto-flow: column;
  grid-gap: $unit * 0.5;
}
