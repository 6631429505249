.tooltip {
  max-width: 200px;

  dl {
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-align: left;
    line-height: 2;
    padding: 0;
  }

  dt,
  dd {
    margin: 0;
    float: left;
    width: 50%;
    padding: 0;
  }

  dd {
    opacity: 0.8;
  }
}
