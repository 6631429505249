@import "../../shared/vars";
@import "../../shared/typography";

.form {
  height: 100%;
}

.label {
  min-width: 110px;
}

.note {
  @include font-s;
  @include secondary-text-color;
}

.inputSwitch {
  margin-bottom: 0;
}

.inputHelper {
  @include font-s;
}

.reviewerWrapper {
  width: 100%;
}

.reviewerHelper {
  margin-bottom: $unit * 2;
}

.projectAdminCheckbox {
  margin: $unit * 0.5 0;
}

.projectAdminLabel {
  @include font-s;

  padding: 0 0 0 $unit * 0.5;
}

.reviewerList {
  padding: 0;
}

.projectAdminWrapper {
  display: flex;
  align-items: center;
}

.projectAdminBadge {
  margin-left: $unit;
}

.minimumApprovalWrapper,
.dismissReviewWrapper {
  display: flex;
}

.minimumApprovalInput {
  width: $unit * 5;
}

.dismissReviewHelper {
  padding-right: $unit * 2;
}

.submit {
  justify-content: flex-end;
}
