@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.submit {
  justify-content: flex-end;
}

.sectionText {
  margin-bottom: 18px;
}

.sectionLeadText {
  margin-bottom: $unit;
  margin-top: 0;
}

.sectionList {
  padding: 0 0 0 $unit * 2;
}
