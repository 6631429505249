@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";

.input {
  min-width: $unit * 25;
  cursor: text;
}

:global {
  .public-DraftEditorPlaceholder-root,
  .public-DraftEditorPlaceholder-hasFocus,
  .public-DraftEditorPlaceholder-inner {
    @include placeholder-text-color;
  }
}

// styles below are passed to draft-js-mentions-plugin
.mention {
  font-weight: $font-medium;
  text-decoration: none;
}

.mentionSuggestions {
  @include content-background;

  margin: 1.75em 0;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  font-size: 13px;
  border-radius: $unit * 0.5;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0 4px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform: scale(0);

  * {
    cursor: pointer !important;
  }
}

.fixedSuggestions .mentionSuggestions {
  width: 100%;
  margin: 0;
  left: 0;
  transform: scaleY(0);
}

// styles below are passed to AutoCompleteListItem
.mentionSuggestionsEntry {
  padding: 3px 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: $unit * 5;
    right: 0;

    @include border-top;
  }

  &:first-child::before {
    display: none;
  }
}

.mentionSuggestionsEntry:active {
  background-color: $gray-10;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: $blurple-60;

  &:first-child {
    border-top-left-radius: $unit * 0.5;
    border-top-right-radius: $unit * 0.5;
  }

  &:last-child {
    border-bottom-left-radius: $unit * 0.5;
    border-bottom-right-radius: $unit * 0.5;
  }

  &:active {
    background-color: darken($blurple-60, 5%);
  }

  &::before {
    display: none;
  }

  .mentionSuggestionsEntryName {
    color: $white;
  }

  .mentionSuggestionsEntryUsername {
    color: $white-a50;
  }
}

.mentionSuggestionsEntryContainer {
  min-height: $unit * 4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.mentionSuggestionsEntryName {
  white-space: nowrap;
  overflow: visible;
  color: $gray-70;
  font-weight: $font-medium;
  padding-left: $unit;
  padding-right: $unit * 0.5;
}

.mentionSuggestionsEntryUsername {
  color: $gray-50;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

@include dark-theme {
  .mentionSuggestions {
    @include inset-border;

    border: 1px solid $black;
  }

  .mentionSuggestionsEntryFocused {
    .mentionSuggestionsEntryUsername {
      color: $white-a50;
    }
  }

  .mentionSuggestionsEntryName {
    color: $white;
  }

  .mentionSuggestionsEntryUsername {
    color: $gray-50;
  }
}
