@import "animations";
@import "fonts";
@import "theme-vars";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";
@import "../shared/typography";

:global {
  * {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    height: 100%;
    padding: 0;
  }

  body {
    @include font-m;
    @include text-color;

    -webkit-font-smoothing: antialiased;
    cursor: default;
  }

  input,
  textarea,
  select {
    font: inherit;
    color: inherit;
    font-size: 14px;
  }

  input,
  select {
    &:disabled {
      // force onMouseLeave to be called on disabled inputs
      // this allows Popover wrappers to function correctly
      // see https://github.com/facebook/react/issues/4251
      pointer-events: none;
    }
  }

  #root {
    height: 100%;
  }

  a {
    @include link;
  }

  b,
  strong {
    // This is specific to the GT America font and should most likely be
    // removed if we ever move to another font. The bold weight looks too heavy
    // at small/normal font sizes. We only actually use the bold weight for
    // larger headlines.
    font-weight: $font-medium;
  }

  .theme-transition * {
    transition: all 0ms !important;
  }
}
