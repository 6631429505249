@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.wrapper {
  display: flex;
}

.preview {
  margin-right: $unit * 2;
}

.action {
  margin-right: $unit;
  margin-bottom: $unit;
}

.help {
  @include secondary-text-color;
}
