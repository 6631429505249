@import "../shared/vars";
@import "../shared/typography";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";

.propertyValue {
  max-width: 25%;
  min-width: 25%;

  &.half {
    max-width: 12.5%;
    min-width: 12.5%;
  }

  &.double {
    max-width: 50%;
    min-width: 50%;
  }

  &.full {
    max-width: 100%;
    min-width: 100%;
  }

  &.enabled:hover,
  &.enabled:focus {
    cursor: pointer;

    .value {
      border-bottom: 1px dotted currentColor;
    }
  }

  &:focus {
    outline: 0;
  }

  & + & {
    padding-left: $unit;
  }
}

.label {
  @include ellipsis;
  @include font-xs;
  @include secondary-text-color;

  font-weight: $font-regular;
  text-transform: uppercase;
  margin-bottom: $unit;

  .changed & {
    @include link-color;

    font-weight: $font-medium;
  }
}

.valueContainer {
  .truncate & {
    @include ellipsis;
  }

  .disabled & {
    opacity: 0.5;
  }
}

.value {
  @include font-s;
  @include font-mono;

  display: inline;

  .changed & {
    @include link-color;

    font-weight: $font-medium;
  }

  .added & {
    @include allgood-text-color;
  }

  .removed & {
    @include error-text-color;

    text-decoration: line-through;
  }
}

.color {
  @include ellipsis;
}

.textContent {
  white-space: pre-line;
}

.pre {
  white-space: pre;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.colorSwatch {
  display: inline-block;
  margin-right: $unit;
}

.gradientPosition {
  padding-left: $unit * 3;
}

.styleNamePath {
  @include ellipsis;
  @include font-s;
  @include font-mono;
  @include secondary-text-color;
}

.styleNameRight {
  @include font-s;
  @include font-mono;

  white-space: nowrap;
}

.styleNameSlash {
  @include secondary-text-color;
}

.styleNameBase {
  cursor: pointer;
}
