@import "../shared/vars";
@import "../shared/mixins";

.submit {
  justify-content: flex-end;
}

.avatar {
  flex: 0 0 auto;
  margin: 0 $unit;
}

.teamInfoWrapper {
  @include secondary-content-background;

  border-radius: $unit * 1.5;
  display: flex;
  align-items: center;
  padding: $unit * 2;
}

.teamInfo {
  display: flex;
  flex-direction: column;
}

.teamName {
  font-weight: $font-medium;
}
