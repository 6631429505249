@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";

.separator {
  @include border-top;

  margin: $unit * 2 0;
  height: 1px;
  border: {
    bottom: none;
    left: none;
    right: none;
  }
}
