@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.wrapper {
  display: block;
  height: 100%; // prevents content overflow in virtualized lists
  color: inherit;
  text-decoration: none;
  padding: 0 $unit * 2;

  &:hover {
    @include cell-hover-background;

    color: inherit;
  }
}

.selected,
.selected:hover {
  background: $secondary-selected;

  &.focused {
    background: $selected;
    color: $white;

    .description {
      color: $white;
    }
  }
}

.inner {
  display: flex;
  position: relative;
  padding: 7px 0 7px $unit * 4;
  align-items: center;
  height: 100%;
}

.content {
  width: 0;
  flex-shrink: 1;
  flex-grow: 1;
}

.heading {
  @include ellipsis;
}

.description {
  @include font-s;
  @include ellipsis;
  @include secondary-text-color;

  line-height: 16px;

  span,
  time {
    display: inline;
  }
}

.commitTrack {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.iconComments,
.iconReference {
  margin-left: $unit * 1.5;
}

.placeholderWrapper {
  display: block;
  height: 100%; // prevents content overflow in virtualized lists
  padding: 0 $unit * 2;
}

.placeholderTrack {
  @include shimmerBackground;

  height: 48px;
  width: 8px;
  left: 8px;
}
