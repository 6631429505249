@import "../shared/vars";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.review {
  @include font-s;
  @include text-color;

  position: relative;
  cursor: pointer;
  line-height: 18px;

  &:hover {
    @include text-color;
  }

  * {
    cursor: pointer;
  }
}

.card {
  text-decoration: none;
}

.titleText {
  @include ellipsis;
}

.meta {
  @include secondary-text-color;
  @include ellipsis;

  margin: 0 0 $unit * 0.5;
  flex-shrink: 0;
}
