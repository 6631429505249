@import "../shared/mixins";
@import "../shared/theme";
@import "../shared/vars";
@import "../shared/typography";

.wrapper {
  @include content-background;
  @include border-bottom;

  padding: $unit $unit * 2;
}

.breadcrumbWrapper {
  display: none;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ($unit * 0.5) 0;
}

.titleMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  // This is a flex parent of `heading`, which has non wrapping text.
  // Non-wrapping child prevents the flex parent from getting narrower, unless the parent has a min width set.
  min-width: 0;
}

.heading {
  @include ellipsis;

  padding-right: 2px;
}

.titleAvatarIcon {
  margin-right: ($unit * 1.5);
  display: flex;
}

.titleIcon {
  display: flex;
  margin-right: $unit;
  margin-left: -($unit * 0.5);
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: $unit;
}

.action {
  margin-left: $unit;
  display: inline-flex;

  &:empty {
    margin-left: 0;
  }

  &:first-child {
    margin-left: 0;
  }
}

.controls {
  display: flex;
  flex-direction: column;
}

.navigation {
  display: flex;
  flex-direction: row;
  order: 0;
  margin: 0 ($unit * -2);
  padding: $unit;
  overflow-x: auto;
}

.navigationLink {
  padding-right: ($unit * 0.5);

  &:last-child {
    padding-right: $unit;
  }
}

.filter {
  display: flex;
  align-items: center;
  order: 2;
  padding: ($unit * 0.5) 0;
  margin-left: -$unit;
}

.extras {
  display: flex;
  flex-direction: row;
  align-items: center;
  order: 1;
  padding: $unit 0;
}

.sort {
  order: 1;
  // flex here seems unnecessary, but I had to use it to vertically align the
  // button.
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: $unit;
}

.search {
  margin-left: -$unit;
  order: 0;
  flex-grow: 1;
}

.displayControl {
  display: none;
}

.displayMeta {
  @include font-s;
  @include secondary-text-color;
}

@media (min-width: $breakpoint-mobile) {
  .wrapper {
    padding: ($unit * 2) ($unit * 3) 4px;
  }

  .headingSize {
    @include font-xl;
  }

  .titleSmall {
    margin: $unit 0;
  }

  .breadcrumbWrapper {
    display: block;
  }

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $unit 0;
  }

  .navigation {
    margin-right: 0;
    margin-left: -$unit;
    padding: 0;
    overflow-x: unset;
    order: 0;
  }

  .filter {
    order: 1;
  }

  .extras {
    flex-grow: 1;
    justify-content: flex-end;
    order: 2;
    padding: 0;
  }

  .sort {
    order: 0;
  }

  .search {
    order: 1;
    margin-left: $unit;
    flex-grow: 1;
    max-width: $unit * 34;
  }

  .displayControl {
    order: 2;
    display: block;
    margin-left: $unit;
  }
}
