@import "../shared/mixins";

.circle {
  width: $unit * 3;
}

.inset {
  stroke: $gray-20;
}

.insetLight {
  stroke: $blurple-45;
}

.progress {
  stroke-linecap: round;
  stroke: $blurple-60;
  stroke-dasharray: circumference(7);
  stroke-dashoffset: -(circumference(7));
  transition: stroke-dashoffset 0.5s ease 0s;
}

.progressLight {
  stroke: $white;
}

@include dark-theme {
  .progress {
    stroke: $blurple-45;
  }

  .inset {
    stroke: $gray-80;
  }

  .insetLight {
    stroke: $blurple-45;
  }

  .progressLight {
    stroke: $white;
  }
}
