@import "../shared/typography";
@import "../shared/vars";
@import "../shared/theme";
@import "../shared/colors";

.avatar {
  vertical-align: top;
  position: relative;
  width: $unit * 3;
  height: $unit * 3;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.defaultText {
  @include font;

  text-anchor: middle;
  font-weight: $font-medium;
  text-transform: uppercase;
  fill: $white;
}

@include dark-theme {
  .hexagon {
    stroke: $gray-70;
  }
}
