@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.wrapper {
  padding: $unit * 4;
  text-align: center;
}

.name {
  @include breakWord;
  @include text-color;

  margin-bottom: 0;
  margin-top: $unit;
}

.username,
.joinedOn {
  @include secondary-text-color;
}

.section {
  @include border-bottom;

  padding: $unit * 2;

  &:last-child {
    border: none;
  }
}

.mailTo {
  @include link;

  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.joinedOn {
  @include font-s;
}

@media screen and (max-width: 640px) {
  .wrapper {
    padding: $unit * 10;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
