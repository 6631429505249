@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

.wrapper {
  margin-bottom: $unit;

  &:last-child {
    margin-bottom: 0;
  }
}

.select {
  @include input;
  @include asset(arrow);

  background-position: top 10px right 8px;
  background-size: 8px 12px;
  height: 32px;
  padding-right: $unit * 3;

  &:disabled {
    @include asset(arrow-disabled);
  }
}

.nude {
  box-shadow: none;
  margin-left: -$unit;
}

.error {
  @include input-error;
}

.select::-ms-expand {
  display: none;
}

@include dark-theme {
  .nude {
    box-shadow: none;
  }
}
