@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";

.wrapper {
  padding: ($unit * 4) 0;
  height: 100%;
}

.loading {
  min-height: $unit * 36;
}

.dropzone {
  border: 2px dashed $gray-20;
  border-radius: $unit * 2;
  height: 100%;
  padding: $unit * 4;
  min-height: $unit * 36;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.dropzoneActive {
  border-color: $blurple-60;
}

.dropzoneLabel {
  margin-bottom: 0;
}

.dropzoneLabelMobile {
  @media (min-width: 640px) {
    display: none;
  }
}

.dropzoneLabelDesktop {
  display: none;

  @media (min-width: 640px) {
    display: inline;
  }
}

.dropzoneLink {
  color: inherit;
  cursor: pointer;

  @include link;
}

.dropzoneHelper {
  color: $gray-50;
}

.dropzoneError {
  color: $red-50;
  font-size: 14px;
  line-height: 20px;
}
