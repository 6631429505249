@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.item {
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;

  > .label {
    @include text-color;

    &:hover {
      background: $black-a05;
      background: var(--menu-hover-background-color);
    }

    .icon svg,
    .disclosure svg {
      @include menu-item-icon-color;
    }

    &.selected,
    &.selected:hover:not(.focused) {
      @include secondary-selected-background;
    }

    &.focused.selected,
    &.focused.selected:hover {
      background: $selected;
      color: $white;

      .icon svg,
      .disclosure svg {
        fill: $white;
      }

      .action {
        color: $white;
      }

      [data-blurred] & {
        @include text-color;
        @include secondary-selected-background;

        .icon svg,
        .disclosure svg {
          @include menu-item-icon-color;
        }
      }
    }
  }
}

.labelWrapper {
  // allows labels to elide where neccessary
  min-width: 0;
}

.label {
  @include ellipsis;
  @include text-color;
  @include inner-keyboard-focus;

  display: flex;
  align-items: center;
  text-decoration: none;
  padding: $unit $unit * 4;
  height: $unit * 4;
  width: 100%;
  user-select: none;
}

.large {
  > .label {
    padding-top: $unit * 1.5;
    padding-bottom: $unit * 1.5;
    min-height: $unit * 5;
  }
}

.mobile {
  > .label {
    padding: $unit * 1.5 $unit * 2 !important;
  }
}

.disabled {
  opacity: 0.5;

  > .label {
    &:active,
    &:hover,
    &.selected {
      @include text-color;

      background: none;
    }
  }
}

.disclosure {
  @include quick-transition(transform);
}

.collapsed {
  .disclosure {
    transform: rotate(180deg);
  }
}

.icon {
  margin-right: $unit;
}

.external.label {
  background: none !important;

  &:hover {
    text-decoration: underline;
  }

  &:active,
  &:hover,
  &.selected {
    background: none;
  }
}

.text {
  @include ellipsis;

  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  user-select: none;
}

.divider {
  @include border-top;

  height: 0;
  margin: 2 * $unit 0;
}

.action {
  @include link-color;
}

.checkmarkContainer {
  width: $unit * 3;
  height: $unit * 3;
  margin-left: -$unit * 2.5;
  margin-right: $unit;
}

.checkboxLabel {
  flex-grow: 1;
}

.danger {
  > .label {
    color: $red-50;

    &:hover {
      color: $red-60;
    }
  }
}
