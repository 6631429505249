@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.metricCard {
  margin-right: $unit * 2;
  margin-bottom: $unit * 2;
  min-width: 302px;

  &:last-child {
    margin-right: 0;
  }
}

.metricContent {
  padding: $padding;
}

.metricLabel {
  padding-bottom: $unit;
}

.stat {
  @include font-m;
  @include secondary-text-color;

  font-weight: $font-medium;
}
