@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";

.menuItem {
  @include text-color;
  @include inner-keyboard-focus;

  display: flex;
  align-items: center;
  padding-top: $unit * 0.5;
  padding-right: $unit * 1.5;
  padding-bottom: $unit * 0.5;
  padding-left: $unit * 5;
  border-radius: 5px;
  text-decoration: none;
  user-select: none;

  &:hover {
    @include menu-hover-background;
    @include text-color;
  }

  &.large {
    padding-top: $unit;
    padding-bottom: $unit;
  }

  &.selected,
  &.selected:hover {
    @include secondary-selected-background;

    &.menuFocused {
      color: $white;
      background: $selected;
    }
  }

  &.disabled,
  &.disabled:hover {
    @include text-color;

    background: none;
    opacity: 0.5;
  }
}

.menuItemWithIcon {
  padding-left: $unit * 1.5;
}

.icon {
  flex-shrink: 0;
  width: $unit * 3;
  height: $unit * 3;
  margin-right: $unit;
  margin-left: $unit * -0.5;
}

.labelWrapper {
  min-width: 0;
}

.label {
  @include ellipsis;

  flex-grow: 1;
  min-width: 0;
}

.large .label {
  @include display-l;
}

.hasDisclosure .label {
  flex-grow: 0;
}

.disclosure {
  flex-shrink: 0;
  margin-left: $unit * 0.5;
  transition: transform 100ms;

  &.collapsed {
    transform: rotate(-90deg);
  }
}

.actionButton {
  flex-shrink: 0;
  margin-right: $unit * -0.5;
}

.actionHover .actionButton {
  opacity: 0;
}

.menuItem.actionHover:hover .actionButton,
.menuItem.actionHover.focused .actionButton,
.menuItem.actionHover.mobile .actionButton {
  opacity: 1;
}

.menuItemPlaceholder {
  padding: $unit * 0.5 $unit * 1.5;
}

.iconPlaceholderWrapper {
  margin-left: $unit * -0.5;
}

.iconPlaceholder {
  @include shimmerBackground;

  width: $unit * 2;
  height: $unit * 2;
  margin: $unit * 0.5;
  border-radius: 33%;
}

.menuItemLabelPlaceholder {
  margin-left: $unit;
}
