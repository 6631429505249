@import "../shared/mixins";

.scrollable {
  @include scrollable;
}

.flex {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: inherit;
  min-height: 0;
}

.topBorder {
  @include box-shadow-top;
}

.bottomBorder {
  @include box-shadow-bottom;
}

.topAndBottomBorder {
  @include box-shadow-top-bottom;
}
