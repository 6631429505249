.container {
  display: flex;
  width: 100%;
}

.left,
.right,
.center {
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: 100%;
}

.left,
.right {
  flex: 1;
}

.left {
  flex-shrink: 0;
}

.right {
  justify-content: flex-end;
}
