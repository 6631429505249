@import "../../shared/typography";
@import "../../shared/mixins";
@import "../../shared/vars";
@import "../../shared/theme";

.item {
  @include content-background;
}

.innerItem {
  display: flex;
  flex-wrap: nowrap;
  padding: $unit * 1.5 $unit * 2;
}

.center {
  padding-top: 2px;
  margin-right: $unit * 1.5;
}

.icon {
  margin-left: -$unit * 0.5;
  margin-right: $unit;
}

.emphasis {
  font-weight: $font-medium;
}

.strikethrough {
  text-decoration: line-through;
}

.badge {
  height: $unit * 3;
  display: flex;
  align-items: center;
}
