@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";

.connected {
  @include font-xs;

  color: $green-50;
  font-weight: $font-medium;
}

.select {
  width: 100%;
}
