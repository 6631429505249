@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";
@import "../shared/typography";

.placeholderAvatar {
  @include shimmerBackground;

  width: $unit * 3;
  height: $unit * 3;
  border-radius: 33%;
}

.image {
  width: 100%;
  height: 100%;
  -webkit-user-drag: none;
}

.deletedCommentAvatar {
  @include placeholder-text-color;
  @include secondary-content-background;
}

.badge {
  border-radius: 100%;
  position: absolute;
  width: 14px;
  height: 14px;
  background-size: 14px;
  right: -3px;
  bottom: -3px;

  &.green {
    background-color: $green-50;
  }

  &.orange {
    background-color: $orange-50;
  }
}

.avatar {
  position: relative;
  width: $unit * 3;
  height: $unit * 3;
  border-radius: 33%;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  display: inline-block;
  overflow: hidden;
}

.hidden {
  display: none;
}

.stretch {
  width: 100%;
  height: 100%;
}

.large {
  width: $unit * 6;
  height: $unit * 6;
}

.ring {
  @include content-background;

  border: 2px solid;
  padding: 2px;
  box-sizing: content-box;
  margin: -4px;

  &.green {
    border-color: $green-50;
  }

  &.orange {
    border-color: $orange-50;
  }

  &.blurple {
    border-color: $blurple-60;
  }

  &.avatarBackgroundBlurple {
    background: $blurple-10;
  }
}

@include dark-theme {
  .ring {
    &.blurple {
      border-color: $blurple-30;
    }

    &.avatarBackgroundBlurple {
      background: $blurple-80;
    }
  }
}

.defaultAvatar {
  overflow: hidden;
  user-select: none;
}

.defaultUserAvatar {
  vertical-align: top;
}

.defaultText {
  @include font;

  text-anchor: middle;
  font-weight: $font-medium;
  text-transform: uppercase;
  fill: $white;
}
