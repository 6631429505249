@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.root {
  @include content-background;

  min-height: 0;
}

.icon {
  vertical-align: middle;
}

.headerLink,
.headerLink:hover {
  color: inherit;
  text-decoration: none;
}

.menu {
  @include border-bottom;
  @include content-background;
  // Offset tabs position ito account for the header
  margin-top: -21px;
  padding: 0 $hpadding;
  position: relative;
  z-index: 1;
}

.content {
  @include secondary-content-background;

  overflow-y: auto;
}

.banner > div {
  margin-bottom: 0;
}

.url {
  display: inline-block;
  // This truncates a webhook URL without needing to pass props down 3 layers
  // of components: it sets the width and offsets it by the sidebar's width.
  max-width: calc(98vw - (#{$unit} * 70));
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

@media (max-width: $breakpoint-tablet) {
  .url {
    max-width: calc(88vw - (#{$unit} * 35.5));
  }
}

@media (max-width: $breakpoint-mobile) {
  .tab {
    margin-left: -$unit * 0.5;
    margin-right: -$unit * 0.5;
  }

  .menu {
    margin-top: -$unit * 1.5;
    padding: 0 $unit * 2.5;
  }

  .subheading {
    overflow: hidden;
    text-overflow: ellipsis;
    // Offset width to account for the width of the sidebar
    width: calc(100vw - 136px);
  }

  .url {
    max-width: calc(98vw - (#{$unit} * 28.5));
  }
}
