@mixin animation($name) {
  animation-duration: 1s;
  animation-fill-mode: both;

  :global {
    animation-name: $name;
  }
}

@mixin fade-in {
  @include animation(fade-in);
}

@mixin fade-and-scale-in {
  @include animation(fade-and-scale-in);
}

@mixin fade-in-left {
  @include animation(fade-in-left);
}

@mixin fade-out {
  @include animation(fade-out);
}

@mixin bounce {
  @include animation(bounce);
}

@mixin scale-in-down {
  @include animation(scale-in-v);

  animation-duration: 0.25s;
  transform-origin: top;
  animation-timing-function: cubic-bezier(0.3, 1.2, 0.2, 1);
}

@mixin scale-out-up {
  @include animation(scale-out-v);

  animation-duration: 0.25s;
  transform-origin: top;
  animation-timing-function: cubic-bezier(0.3, 1.2, 0.2, 1);
}
