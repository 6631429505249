@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.list {
  height: 100%;
}

.heading {
  @include dynamic-padding(left right);

  padding-top: $unit * 2;
  height: $unit * 5;
}

.headingspacer {
  height: $unit * 2;
}

.sidebarTopHeadingContainer {
  height: $unit * 6;
}

.sidebarTopHeading {
  @include dynamic-padding(left right);

  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: $unit * 3;
  flex-shrink: 0;
}

.searchInput {
  @include font-s;

  font-weight: $font-regular;
  padding: 2px $unit 2px $unit * 3;
  border-radius: $unit * 2;

  @media (max-width: $breakpoint-mobile) {
    @include font-ml;
  }
}

.files {
  flex-grow: 1;
  position: relative;
  order: 1;
  height: 100%;

  @media (min-width: $breakpoint-mobile) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 0;
  }
}

.filesInner,
.emptyInner {
  flex-grow: 1;
  height: 100%;
  padding-bottom: $unit * 3;

  @media (min-width: $breakpoint-mobile) {
    position: absolute; // For firefox
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
  }
}

.emptyInner {
  display: flex;
  flex-direction: column;
}

.filter {
  @include border-bottom;

  padding: $unit * 1.5 $unit * 2;
  border-top: none;
  order: 0;

  @media (min-width: $breakpoint-mobile) {
    @include border-top;

    border-bottom: none;
    order: 1;
  }
}
