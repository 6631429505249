@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";

.statusMenu {
  border-radius: $unit * 0.75;
  width: $unit * 30;
  overflow: hidden;
  padding: $unit * 0.5 0;
}

.menuItem {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.menuText {
  margin-left: $unit * 0.75;
}

.branchStatusBadge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $unit * 1.5;
  background: $gray-20;
  flex-direction: row;
  min-width: $unit;
  height: $unit * 3;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-left: $unit * 0.5;
  padding-right: $unit;
  margin-top: -1px;

  &.noIcon {
    padding-left: $unit * 1.5;
  }

  &.noDisclosure {
    padding-right: $unit * 1.5;
  }

  &.clickable {
    cursor: pointer;
  }
}

.inlineSpacing {
  margin-top: -9px;
  bottom: $unit * -1;
  margin-left: $unit * 0.25;
  position: relative;
}

.badgeText {
  @include font-s;

  line-height: 15px;
  text-transform: none;
  font-weight: $font-regular;
  margin-top: -1px;
}

.badgeIcon {
  flex: 0 1 auto;

  &:not(:only-child) {
    margin-right: -2px;
  }
}

.chevron {
  color: $gray-50;
  flex: 0 1 auto;
  margin-left: $unit * 0.25;
  height: inherit;
  width: $unit * 2;
}

.defaultStatus {
  position: relative;
  background: none;
  border: none;
  color: $gray-70;

  .badgeIcon {
    color: $gray-50;
  }

  &::before {
    @include border;

    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    pointer-events: none;
    touch-action: none;
  }

  &.selected {
    &::before {
      border-color: $white-a15;
    }

    color: $white;

    .badgeIcon {
      color: $white;
    }
  }
}

.allgood {
  color: $white;
  background: $green-50;

  .badgeIcon {
    color: $white;
  }

  .chevron {
    color: $white;
  }
}

.purple {
  color: $white !important;
  background: $purple;

  .badgeIcon {
    color: $white;
  }

  .chevron {
    color: $white;
  }
}

.error {
  color: $white;
  background-color: $red-50;
}

.warning {
  color: $white;
  background: $orange-50;

  .badgeIcon {
    color: $white;
  }

  .chevron {
    color: $white;
  }
}

@include dark-theme {
  .branchStatusBadge {
    color: $white;
    background: $gray-60;

    &.allgood {
      background: $green-50;
    }
  }

  .badgeIcon {
    color: $white;
  }

  .warning {
    background: $orange-50;
  }

  .purple {
    background: $purple;
  }

  .error {
    color: $white;
    background-color: $red-50;
  }

  .defaultStatus {
    background: none;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      pointer-events: none;
      touch-action: none;
    }

    .badgeIcon {
      color: $white;
    }
  }
}

@media (max-width: $breakpoint-web) {
  // We want badges on branch list items to shrink to an icon-only
  // version, to make sure the item doesn't unnecessarily wrap.
  .branchStatusBadge.shrinkAtTablet {
    padding: 0;
    min-width: 28px;
    justify-content: center;

    .badgeIcon {
      margin-right: 0;
    }

    .badgeText {
      display: none;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  // On mobile, we can shrink every badge to the icon-only
  // version, to conserve screen space, unless specifically
  // listed to stay in full-size (activity feed items).
  .branchStatusBadge.shrinkAtMobile {
    &.noIcon {
      display: none;
    }

    padding: 0 !important;
    min-width: 28px;
    justify-content: center;

    .badgeIcon {
      margin-right: 0;
    }

    .badgeText {
      display: none;
    }
  }
}
