@import "../shared/mixins";
@import "../shared/vars";

.wrap {
  flex-grow: 1;
  position: relative;
}

.newSectionButton {
  margin-left: $unit * 1.5;
  flex-shrink: unset;
}

.searchInput {
  margin-bottom: 0;
}

.nav {
  width: 100%;
  position: relative;
  background-color: inherit;
}

.actions {
  position: relative;
  justify-content: flex-end;
  flex-grow: 0;
}

.createProjectButton {
  margin-left: $unit * 1.5;
  flex-shrink: unset;
}

@media screen and (min-width: $breakpoint-mobile) {
  .sortButton {
    margin-left: $unit;
    flex-shrink: unset;
  }

  .nav {
    margin: 0 $unit;
  }

  .actions {
    height: $unit * 3.5;
    padding-right: $unit;
    padding-left: $unit;
    overflow: hidden;
  }
}
