@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/colors";
@import "../../shared/mixins";
@import "../../shared/theme";

.toggleChangesetInput {
  padding: 0 $unit * 2;
  margin-bottom: 0;
}

.layoutMenuItem {
  display: flex;
  align-content: center;
  width: $unit * 30;
  height: $unit * 4;
  padding-bottom: 0;
  padding-top: 0;
}

.mono {
  @include font-mono;

  color: $gray-60;
}

.layoutToggle {
  pointer-events: none;
  width: $unit * 2 !important;
}

.popoverBody {
  width: $unit * 30;
}
