:global {
  button,
  a {
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  .ReactModal__Overlay--after-open {
    // Prevents being able to scroll past modals on iOS devices
    @media screen and (max-width: 960px) {
      position: fixed !important;
    }
  }
}
