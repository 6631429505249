@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.seeAll {
  @include font-m;
  @include secondary-text-color;

  text-decoration: underline;
  display: inline-block;
  margin: $unit 0;
  cursor: pointer;

  &:hover {
    @include text-color;
  }
}
