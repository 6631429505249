@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.listWrapper {
  flex-grow: 1;
  position: relative;
}

.innerListElement {
  border-left: $unit * 4 solid transparent;
  border-right: $unit * 4 solid transparent;
}

.listItem {
  &:hover + .listItem {
    .project::after {
      left: 0;
      right: 0;
    }
  }

  .project {
    @include virtualized-card-wrapper;
  }

  &.firstListRow {
    .project {
      @include border-top;

      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      @media (max-width: $breakpoint-mobile) {
        border-radius: 0;
        border-top: none;
      }

      &::after {
        border: none;
      }
    }
  }

  &.lastListRow {
    .project {
      @include border-bottom;

      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;

      @media (max-width: $breakpoint-mobile) {
        border-radius: 0;
      }
    }
  }
}

.waypoint {
  position: absolute;
  width: 100%;
  bottom: 0;
}
