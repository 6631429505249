@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

@mixin logo-dimensions {
  width: 118px;
  height: 30px;
}

.wrap {
  background-color: $gray-70;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.abstractLogo {
  display: block;

  @include logo-dimensions;
}

.page {
  @include content-background;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.header {
  flex-direction: row;
  margin: ($unit * 4) ($unit * 4) ($unit * 2);
}

@include media(desktop) {
  .logoSpacer {
    &::after {
      @include logo-dimensions;

      content: "";
      display: block;
    }
  }
}

.homeLink {
  a {
    @include outer-keyboard-focus;

    display: block;
    border-radius: 3px;
  }
}

@include media(mobile) {
  .header {
    flex-direction: column;
    margin: ($unit * 4) auto;
  }

  .homeLink {
    position: static;
  }

  .progressIndicator {
    margin-top: $unit * 4;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  height: 100%;

  &::after {
    content: "";
    padding-bottom: $unit * 4;
  }
}

.fullContentHeight {
  padding-bottom: 0;
}

.organizationLogoWrapper {
  margin-bottom: $unit * 3;
}

.innerContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  padding: $unit 0;
}

.centerWrap {
  width: calc(100% - 30px);
  max-width: 640px;
  height: auto;
  margin: auto;
}

.fullHeight {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.innerInnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.icon {
  margin-bottom: $unit * 3;
}

.subtitle {
  max-width: 400px;
  margin-bottom: $unit;
}

.footerLinkWrapper {
  @include font-s;
  @include secondary-text-color;

  text-align: center;
  padding-top: $unit * 4;
}

@include media(mobile) {
  .innerInnerContent {
    padding-bottom: $unit * 3;
  }

  .fullHeight {
    margin: 0 $unit * 2.5;
  }
}

@include media(large) {
  .splatter::before {
    @include asset(splatter);

    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: $unit * 11;
    margin: auto;
    background-position: center;
    background-size: contain;
    width: 237px;
    height: 407px;
    pointer-events: none;
  }
}
