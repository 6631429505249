@import "../shared/typography";
@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";

@include light-theme {
  --comment-header-color: #{$gray-10};
}

@include dark-theme {
  --comment-header-color: #{lighten($gray-70, 5%)};
}

.wrapper {
  display: flex;
  margin-bottom: $unit * 2;
  width: 100%;

  &:not(.narrow) {
    .activityIcon {
      @media (min-width: $breakpoint-mobile) {
        display: block;
        margin-right: 12px;
        margin-top: 6px;
        width: 32px;
        text-align: center;
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    // Prevents text magnification near images on mobile
    -webkit-text-size-adjust: none;
  }
}

.activityIcon {
  display: none;
}

.avatar {
  margin-right: 12px;
  margin-top: 3px;
}

.content {
  flex-grow: 1;

  &__header {
    @include border-bottom;

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    padding: 10px;
    background-color: var(--comment-header-color);
  }
}

.comment {
  @include border;

  border-radius: $unit;
  position: relative;

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    top: 10px;
    position: absolute;
    border: 10px solid transparent;
  }

  &::before {
    border-right-color: var(--border-color);
    left: -21px;
  }

  &::after {
    border-right-color: var(--comment-header-color);
    left: -20px;
  }
}

.preview {
  margin-bottom: -2px;
  padding: $unit * 1.5;
}

.meta {
  @include font-s;
  @include secondary-text-color;
}

.action,
.subject {
  @include secondary-text-color;

  a {
    @include link;
    @include outer-keyboard-focus;

    text-decoration: underline;
    border-radius: 3px;
  }
}

.icon {
  position: relative;
  top: 7px; // fix the positioning of the icon
  opacity: 0.5;
  margin-top: -3px; // fix the height of the container
  margin-right: -2px;
  margin-left: -2px;
}

.breadcrumbLink {
  @include outer-keyboard-focus;

  color: inherit;
  text-decoration: none;
  border-radius: 3px;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.userLink {
  @include outer-keyboard-focus;

  color: inherit;
  text-decoration: none;
  border-radius: 3px;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.deletedCommentName {
  font-weight: $font-medium;

  @include text-color;

  opacity: 0.5;
}
