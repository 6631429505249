@import "../shared/vars";

.gridRow {
  display: flex;
  padding-left: $unit * 2;
  padding-right: 0;

  @media screen and (min-width: $breakpoint-mobile) {
    padding-left: $unit * 4;
    padding-right: $unit * 2;
  }
}

.gridItem {
  padding-bottom: $unit * 2;
  padding-right: $unit * 2;
}

.item {
  transform: translate3d(0, 0, 0); // ensure scrolling does not repaint
}
