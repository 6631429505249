@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.empty {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $unit * 2;
}
