@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

$support-form-min-width: 320px;

.windowContainer {
  @include content-background;

  min-width: $support-form-min-width;
  height: 100%;

  input,
  button,
  a {
    -webkit-app-region: no-drag;
  }

  &.formSubmitted {
    height: 100%;

    .windowContent {
      @include flex;

      align-items: center;
      justify-content: center;
    }
  }
}

.header {
  background: linear-gradient(
    rgba(250, 250, 250, 0.5),
    rgba(220, 222, 223, 0.5)
  );
  box-shadow:
    0 -0.75px 0 $black-a15 inset,
    0 0.75px 0 $white inset;
  height: $title-bar-height;
  flex-grow: 0;
  flex-shrink: 0;
  -webkit-app-region: drag;

  [data-blurred] {
    background: $gray-10;
  }
}

.windowContent {
  padding-top: $unit * 3;
  height: 100%;
  margin: 0;
}

.heading {
  justify-content: space-between;
  margin: 0 $unit * 4;
  padding: 0 $unit * 2;
}

.headingRight {
  @include font-s;
  @include secondary-text-color;

  align-items: flex-end;
}

.notice {
  flex-grow: 0;
  display: flex;
  margin: $unit * 3 $unit * 4;
}

.hoursNotice {
  @include secondary-content-background;
  @include secondary-text-color;
  @include font-s;

  padding: $unit * 1.5 $unit * 2;
  margin: 0 $unit * 4;
  border-radius: $unit * 0.5;
}

.clockIcon {
  width: $unit * 2;
  height: $unit * 2;
  margin-top: $unit * 0.125;
  margin-right: $unit * 1.5;
}

.openHours {
  color: $green-60;
}

.validationError {
  padding: 0;
}

.title {
  @include text-color;

  display: inline-block;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: $breakpoint-mobile;

  &.webForm {
    width: 100%;
    margin: 0 auto;
  }
}

.formInputs {
  margin: 0 $unit * 4;
  padding-bottom: $unit * 10;
}

.fields {
  padding: $unit * 2;
}

.attachments {
  padding: $unit * 3 - 1 $unit * 2 $unit * 2;
}

.files {
  min-height: 0;
  max-height: $unit * 16;
  margin-top: $unit * 2 - 1;
}

.file {
  @include font-s;
  @include secondary-text-color;

  height: $unit * 4.5;
  justify-content: space-between;
  margin-bottom: $unit;

  &:last-child {
    margin-bottom: 0;
  }
}

.thumbnail {
  width: $unit * 4;
  height: $unit * 4;
  border-radius: 3px;
  margin: auto 0;
}

.fileName {
  @include multiline-ellipsis;

  flex-grow: 1;
  margin: auto 0;
  padding: 0 $unit;
  width: $unit * 17.5;
}

.submissionNotice {
  text-align: center;
  flex-grow: unset;

  .checkmark {
    background: $green-40;
    border-radius: 50%;
    margin-bottom: $unit * 2;

    svg {
      height: $unit * 12;
      width: $unit * 12;
    }
  }

  .note {
    @include font-s;

    margin-top: $unit * 2;
    line-height: $unit * 3;
  }
}

.inputWrapper {
  @include font-m;
  @include inner-keyboard-focus;

  resize: none;
  width: 400px;
}

.organizationPicker {
  @include font-m;

  font-weight: $font-medium;
  margin-top: $unit;
  max-width: $unit * 30;
  width: auto;
}

.windowFooter {
  @include border-top;
  @include content-background;

  bottom: 0;
  left: 0;
  display: flex;
  height: $unit * 8;
  justify-content: flex-end;
  padding: $unit * 2;
  position: fixed;
  width: 100%;
}

.rightButton {
  margin-left: $unit * 2;

  span {
    align-items: initial;
  }
}

.errorNotice {
  @include font-s;

  font-weight: $font-medium;
  color: $red-60;
  display: none;
  margin: auto $unit * 3 auto 0;

  &.onError {
    display: inline-block;
  }
}

@include dark-theme {
  .inputWrapper {
    @include inner-keyboard-focus;
  }

  .header {
    background: $gray-70;
    box-shadow:
      inset 0 -0.75px 0 0 $black-a75,
      0 0 0 1px $white-a10 inset;
  }

  .checkmark {
    background: $green-50;
  }

  .openHours {
    color: $green-30;
  }
}

@media screen and (max-width: $breakpoint-mobile - 1px) {
  .heading {
    flex-direction: column;
    margin: 0;

    .title {
      margin-bottom: $unit;
    }
  }

  .headingRight {
    align-items: flex-start;
    margin-bottom: $unit;
  }

  .notice {
    margin: $unit * 2 $unit * 2 $unit;
  }

  .hoursNotice {
    margin: 0 $unit * 2;
  }

  .webForm {
    width: 100%;
    margin-bottom: 0;
  }

  .formInputs {
    flex-direction: column;
    margin: 0;
  }

  .inputWrapper {
    width: 100%;
  }

  .attachments {
    padding: $unit $unit * 2 $unit * 2;
  }

  .windowFooter {
    position: relative;
    flex-direction: column;
    height: auto;
  }

  .errorNotice {
    margin: 0 auto $unit * 2;
  }

  .rightButton {
    margin: 0;
  }
}
