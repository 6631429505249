@import "../shared/colors";
@import "../shared/theme";
@import "../shared/vars";

.inline {
  display: inline-flex; // To avoid using line-height for layout
  min-width: 0;
}

.block {
  display: block;
  width: 100%;
}

.container {
  z-index: 10002;
  position: fixed;

  &.clickable {
    pointer-events: all;
  }
}

.hide {
  display: none;
}

.hoverMenuOpen {
  background: $gray-10;
}

.underlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10001;
}

.shortcut {
  padding-left: $unit;
  color: $gray-30;
}

@include dark-theme {
  .shortcut {
    color: $gray-60;
  }
}
