@import "../shared/vars";
@import "../shared/typography";
@import "../shared/colors";
@import "../shared/theme";
@import "../shared/mixins";

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes margin {
  0% {
    opacity: 0;
    margin-left: -$sidebar-max-width;
  }

  100% {
    opacity: 1;
    margin-left: 0;
  }
}

.sidebar {
  display: flex;

  @media (max-width: $breakpoint-tablet) {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    visibility: hidden;

    &.active {
      visibility: visible;
    }
  }
}

.opaque {
  .main {
    @include secondary-content-background;
  }
}

.overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.activeOverlay {
  z-index: 10;
}

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($gray-70, 0.5);
  visibility: hidden;
  opacity: 0;
  animation: opacity 200ms linear;
  transition:
    opacity 200ms $super-ease-out,
    visibility 200ms $super-ease-out;

  @media (max-width: $breakpoint-tablet) {
    .active & {
      visibility: visible;
      opacity: 1;
    }
  }
}

.header {
  @include inset-border-bottom;

  position: relative; // for .closeButton position
  padding: $unit * 1.5;
  display: flex;
  justify-content: center;

  @media (min-width: 960px) {
    display: none;
  }
}

.title {
  @include font-m;

  font-weight: $font-medium;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: $unit;
}

.main {
  @include quick-transition(margin, 250ms);

  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: $sidebar-max-width;

  @media (max-width: $breakpoint-tablet) {
    will-change: transform, opacity;
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
    height: 100%; // of absolute .sidebar
    opacity: 0;
    transition: opacity 200ms $super-ease-out;

    .active & {
      visibility: visible;
      position: absolute;
      transform: translate3d(0, 0, 0);
      opacity: 1;

      // override wide, fixed, and dynamic
      width: 100%;
      max-width: 512px !important;
    }

    .right.active & {
      right: 0;
    }
  }

  .left &::before {
    @media (min-width: 960px) {
      @include inset-border-right;

      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
    }
  }

  .right &::before {
    @media (min-width: 960px) {
      @include inset-border-left;

      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
    }
  }

  .contentBorder &::before {
    box-shadow: -1px 0 0 var(--border-color) inset;
  }

  .fixed.wide & {
    width: $sidebar-wide-width;
  }

  .dynamic & {
    transition:
      200ms max-width $super-ease-out,
      200ms min-width $super-ease-out;
    width: 22vw;
    min-width: $sidebar-min-width;
    max-width: $sidebar-max-width;
  }

  .dynamic.wide & {
    min-width: $sidebar-wide-width;
    max-width: $sidebar-wide-max-width;
  }

  .dynamic.collapsed & {
    min-width: 0; // for max-width: 0
    max-width: 0;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  min-height: 0;

  .dynamic & {
    min-width: $sidebar-min-width;
  }

  .dynamic.wide & {
    min-width: 320px;
  }
}
