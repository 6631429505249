@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";

.img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  border-radius: 25%;
  cursor: default;

  @include content-background;
}

.wrap {
  display: flex;
  position: relative;

  &::after {
    box-shadow:
      0 1px 2px 0 $black-a10,
      0 0 1px $black-a15;
    content: $empty;
    border-radius: 25%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@include dark-theme {
  .wrap {
    &::after {
      box-shadow: none;

      @include card-outline;
    }
  }
}
