@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

@mixin logo-dimensions {
  width: 118px;
  height: 30px;
}

.page {
  @include content-background;

  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.header {
  display: flex;
  align-items: center;
  margin: $unit * 5;
}

.contentWrapper {
  margin-top: $unit * 10;
}

@include media(desktop) {
  .withLogoSpacer::after {
    @include logo-dimensions;

    content: "";
    display: block;
  }
}

@include media(mobile) {
  .header {
    flex-direction: column;
    margin: $unit * 4;
  }

  .progressBar {
    margin-top: $unit * 4;
  }

  .contentWrapper {
    margin-top: $unit * 2;
  }
}

@include dark-theme {
  .page {
    @include secondary-content-background;
  }
}
