@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.button {
  margin-left: $unit * 0.5;
}

.message {
  max-width: $unit * 35;

  a {
    color: $white;
  }
}
