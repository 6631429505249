@import "../../shared/vars";
@import "../../shared/theme";
@import "../../shared/mixins";

.picker {
  @include border;

  border-radius: $unit * 0.5;
  height: 320px;
}

.note {
  margin-top: $unit * 3;
  margin-bottom: $unit;
}

.infoLink {
  @include font-s;
  @include link-color;

  text-decoration: underline;
}
