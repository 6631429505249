@import "../shared/mixins";

.ellipsis {
  overflow: hidden;
  display: flex;
  align-items: center;

  .innerName {
    @include ellipsis;
  }

  .lastWord {
    height: $unit * 2.5;
  }

  .lastWord,
  .iconWrap {
    display: inline-flex;
  }

  .iconWrap {
    width: $unit * 3;
  }

  .icon {
    position: absolute;
    margin-top: -2px;
  }
}

.innerName {
  // prevents emojis at the end of name being chopped
  padding-right: 2px;
}

.block {
  .lastWord {
    white-space: nowrap;
    // for centering the lock icon
    display: inline-flex;
  }

  .iconWrap {
    display: inline-flex;
  }
}
