@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.changePlanDialog {
  @include secondary-content-background;

  padding: $unit 0;
  max-width: initial;
  width: auto !important; /* overrides styling provided by DialogForm for fullscreen */
  display: block !important; /* overrides styling provided by DialogForm for fullscreen */
}

.invoiceLink {
  display: flex;
  align-items: center;
}

.noInvoices {
  margin-top: 0;
}

.updateButtons {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 1px;

  button {
    overflow: hidden;
    flex-shrink: unset;
    margin-left: $unit;

    span {
      @include ellipsis;
    }
  }
}

.seatButtons {
  button:first-child {
    margin-right: 10px;
  }
}

.detailsHeading {
  flex-shrink: 0;
}

.cancelNote {
  margin-top: $unit;

  @media (min-width: $breakpoint-mobile) {
    margin-top: 0;
  }
}

.planName {
  max-width: 280px;
}

.planLabelSubtitle {
  font-weight: $font-regular;
}

.salesLink {
  @include link;

  text-decoration: underline;
}

.red {
  @include error-text-color;
}

.seatNote {
  margin-top: $unit;
}

.pendingSeatsNote {
  margin: -$unit * 2;
  background: $yolk-20;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;

  p {
    margin-top: $unit;
  }
}

.pendingSeatsIconWrapper {
  background-color: transparent;
  margin-right: $unit * 1.5;
  margin-top: -4px;
}

@include dark-theme {
  .pendingSeatsNote {
    background-color: transparentize($yolk-40, 0.9);
    opacity: 1;
  }

  .scheduledChangesItem {
    background-color: transparent;
    border-color: transparentize($yolk-20, 0.2) !important;
  }
}

.scheduledChangesItem {
  background-color: $yolk-20;
  border-color: $yolk-40 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin: -1px;
}

.seatCapReached {
  display: flex;
  align-items: center;
}

.seatCapIcon {
  color: $orange-40;
  display: contents;
}

.expirationDate,
.nextPayment {
  margin-left: auto;

  @media (min-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}

.nextPayment {
  @include secondary-text-color;

  margin-top: $unit;
}

.priceItem {
  &:last-of-type {
    border-bottom: none;
  }
}

.manageSeats {
  width: 400px;
  max-width: 100%;
}

.errorText {
  @include error-text-color;
}

.error {
  @extend .errorText;
  @include font-s;
}
