@import "../../shared/vars";

.selectButton {
  position: absolute;
  top: $unit;
  left: $unit;
}

.menuButton {
  position: absolute;
  top: $unit;
  right: $unit;
}

.deleted {
  opacity: 0.5;
}
