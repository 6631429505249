@import "../shared/mixins";
@import "../shared/vars";

.scrollable {
  width: 100%;
}

.container {
  display: flex;
  flex: 1;
  min-height: 0;

  &.shadow {
    box-shadow: inset 0 -1px 1px $black-a10;
  }

  &.canHaveShadow {
    .scrollable {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-top: $unit;
      padding-bottom: $unit * 0.5;
    }
  }
}

.replyForm {
  padding-left: $unit * 7.5;
  padding-right: $unit * 2;
  flex-shrink: 0;

  @media screen and (min-width: $breakpoint-mobile) {
    padding-left: $unit * 10.5;
  }
}
