@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";

$close: "×";

.bubble {
  @include font-mono;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-medium;
  min-width: $unit * 3;
  text-align: center;
  height: $unit * 3;
  border-radius: $unit * 1.5;
  padding: 0 $unit * 0.5;
  background: $blurple-60;
  color: $white;
  cursor: pointer;
  -webkit-user-select: none;
  z-index: 1; // ensure bubbles always appear above annotation rectangles
}

.noClick {
  cursor: default;
}

.disabled {
  @include quick-transition(background);

  background: $gray-50;
}

// If the borders are hidden then center the number bubble instead of having
// it in the bottom right corner.
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: $unit * -1.5;
  margin-left: $unit * -1.5;
}

.large {
  min-width: $unit * 4;
  height: $unit * 4;
  border-radius: $unit * 2;
}

.small {
  @include font-s;
  @include font-mono;

  min-width: 20px;
  height: 20px;
}

.xsmall {
  @include font-xs;
  @include font-mono;

  min-width: $unit * 2;
  height: $unit * 2;
}

.removable {
  position: relative;

  &::after {
    @include quick-transition(opacity);

    content: $close;
    width: $unit * 3;
    height: $unit * 3;
    border-radius: $unit * 1.5;
    font-weight: $font-medium;
    line-height: 22px;
    font-size: 20px;
    background: $blurple-60;
    text-align: center;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
  }

  &.large::after {
    width: $unit * 4;
    height: $unit * 4;
    border-radius: $unit * 2;
    padding-top: $unit * 0.5;
    box-sizing: border-box;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &.small {
    &::after {
      width: 20px;
      height: 20px;
      line-height: 18px;
    }
  }
}
