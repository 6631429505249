@import "../shared/colors";
@import "../shared/mixins";

.highlighted {
  mark {
    color: $gray-70;
    background: $highlight;
    border-radius: 2px;
  }
}
