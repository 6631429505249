@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";
@import "../shared/typography";
@import "../shared/mixins";

.wrapper {
  top: $unit * 6;
}

@media (min-width: $breakpoint-mobile) {
  .wrapper {
    top: 25vh;
    min-height: $unit * 6;
  }

  .content {
    // this is purposefully set to an uneven number so that if search results
    // are scrollable this is hinted at by one result being partially hidden
    min-height: $unit * 33;
    max-height: 32vh;
  }
}

.inputWrapper {
  padding-left: $unit * 1.5;
  padding-right: $unit * 1.5;
  margin: 0;
}

.loading {
  position: absolute;
  top: $unit * 2;
  right: $unit * 5.5;
}

.input {
  @include font-l;

  box-shadow: none !important;
  background: none !important;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: $unit * 4;
  height: $unit * 6;
}

.results {
  @include border-top;
  @include border-bottom;

  height: 30vh;
}

.result {
  @include text-color;
  @include ellipsis;

  flex-shrink: 0;
  font-weight: $font-medium;
  display: flex;
  height: $unit * 5;
  padding: $unit ($unit * 1.5);
  text-decoration: none;

  &:not(.selected):hover {
    @include text-color;

    background: $black-a05;
  }
}

.resultContent {
  flex-shrink: 1;
  min-width: 0;
}

.resultContentInner {
  min-width: 0;
}

.resultIcon {
  width: $unit * 3;
  height: $unit * 3;
  flex-grow: 0;
  flex-shrink: 0;
}

.resultTitle {
  @include ellipsis;

  padding: 0 $unit;
  flex-grow: 2;
  user-select: none;
}

.resultStatusBadges {
  margin-left: $unit;
  margin-top: 1px;
}

.withShimmer {
  @include shimmerBackground;
}

.result.placeholderResult,
.result.placeholderResult:hover {
  background: none;
}

.placeholderIcon {
  border-radius: $unit;
  width: $unit * 2;
  height: $unit * 2;
  margin: 0 ($unit * 0.5);
}

.placeholderTitle {
  padding: 0;
  height: $unit * 2;
  margin-left: $unit;
  border-radius: 2px;
}

.resultContext {
  @include ellipsis;
  @include secondary-text-color;
  @include font-s;

  padding-right: $unit * 0.5;
  text-align: right;
  flex-shrink: 1;
  min-width: 0;
}

.avatar {
  margin-right: $unit;
}

.offline {
  margin-left: $unit;
}

.footer {
  @include secondary-content-background;

  height: $unit * 5.5;
  padding: 0 ($unit * 1.5) 0 ($unit * 2);
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: $font-medium;
  display: none;
}

@media (min-width: $breakpoint-mobile) {
  .footer {
    display: flex;
  }
}

.footerKey {
  @include text-color;
  @include font-xs;

  font-weight: $font-medium;
  margin-right: $unit * 1;
}

.footerLabel {
  @include secondary-text-color;
  @include font-xs;

  margin-right: $unit * 3;
}

.selected {
  background: $blurple-60;
  color: $white;

  &:hover {
    color: $white;
  }

  .resultContext {
    color: $white-a50;
  }
}

@include dark-theme {
  .results {
    border-bottom: 0;
  }

  .footer {
    box-shadow: 0 0 0 1px $white-a15 inset;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
