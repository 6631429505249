@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.status {
  @include display-xs;

  display: flex;
  margin-left: $unit * 0.25;
  white-space: nowrap;
}

.active {
  color: $green-50;
}

.inactive {
  @include secondary-text-color;
}

.settingsForm {
  min-height: 0;
}

.payload {
  @include font-mono;
  @include secondary-content-background;

  padding: $unit * 0.25;
  word-break: break-word;
}

.check {
  margin-left: -$unit;
}
