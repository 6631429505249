@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";
@import "../shared/theme";

.placeholderInnerCard {
  padding: ($unit * 1.5) ($unit * 2);
}

.placeholderAuthor {
  margin-right: $unit;
}

$avatar-offset: $unit * 5.5;
$mobile-offset: $unit * 2.5;
$default-padding: $unit * 2;

.card {
  text-decoration: none;

  @include text-color;

  &:hover {
    @include text-color;
  }
}

.branch {
  @include font-s;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  line-height: 18px;
  padding: $unit * 1.5 $unit * 2;
}

.hierarchyIndicator {
  align-self: flex-start;
  margin: $unit $unit * 2 0 $unit * 0.5;
}

.hierarchyLevel2 {
  &::after {
    left: $unit * 2 + $avatar-offset;
  }
}

.hierarchyLevel3 {
  .branch {
    padding-left: $unit * 2 + $avatar-offset;
  }

  &.mobile .branch {
    padding-left: $unit * 2 + $mobile-offset;
  }
}

.hierarchyLevel2 + .hierarchyLevel2,
.hierarchyLevel3 {
  &::after {
    left: $unit * 2 + $avatar-offset * 2;
  }
}

.hierarchyLevel4 {
  .branch {
    padding-left: $unit * 2 + $avatar-offset * 2;
  }

  &.mobile .branch {
    padding-left: $unit * 2 + $mobile-offset * 2;
  }
}

.hierarchyLevel3 + .hierarchyLevel3,
.hierarchyLevel4 {
  &::after {
    left: $unit * 2 + $avatar-offset * 3;
  }
}

.hierarchyLevel5 {
  .branch {
    padding-left: $unit * 2 + $avatar-offset * 3;
  }

  &.mobile .branch {
    padding-left: $unit * 2 + $mobile-offset * 3;
  }
}

.hierarchyLevel4 + .hierarchyLevel4,
.hierarchyLevel5 {
  &::after {
    left: $unit * 2 + $avatar-offset * 4;
  }
}

.hierarchyLevel6 {
  .branch {
    padding-left: $unit * 2 + $avatar-offset * 4;
  }

  &.mobile .branch {
    padding-left: $unit * 2 + $mobile-offset * 3;
  }
}

.hierarchyLevel5 + .hierarchyLevel5,
.hierarchyLevel6 {
  &::after {
    left: $unit * 2 + $avatar-offset * 5;
  }
}

.hierarchyLevel7 {
  .branch {
    padding-left: $unit * 2 + $avatar-offset * 5;
  }

  &.mobile .branch {
    padding-left: $unit * 2 + $mobile-offset * 3;
  }
}

.hierarchyLevel6 + .hierarchyLevel6,
.hierarchyLevel7 {
  &::after {
    left: $unit * 2 + $avatar-offset * 6;
  }
}

.hierarchyLevel8 {
  .branch {
    padding-left: $unit * 2 + $avatar-offset * 6;
  }

  &.mobile .branch {
    padding-left: $unit * 2 + $mobile-offset * 3;
  }
}

.hierarchyLevel7 + .hierarchyLevel7,
.hierarchyLevel8 {
  &::after {
    left: $unit * 2 + $avatar-offset * 7;
  }
}

.mobile {
  &::after {
    left: 0 !important;
  }
}

.detailsAndReviews {
  display: flex;
  flex: 1;
  min-width: 0;
  flex-wrap: nowrap;
}

.details {
  min-width: 0;
  margin-right: $unit * 2;
  flex-grow: 1;
  width: 100%;
}

.titleBox {
  @include ellipsis;
}

.titleText {
  margin-right: $unit;
}

.reviewsAndStatus {
  display: flex;
  flex: 0 0 auto;
  min-width: 0;
  flex-wrap: nowrap;
}

.badge {
  flex: 0 0 auto;
  margin: $unit 0;
}

.description,
.meta {
  @include ellipsis;

  margin: 0;
  flex-shrink: 0;

  @include secondary-text-color;

  span {
    display: inline;
  }
}

.description {
  @include secondary-text-color;
}

.reviews {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: flex-end;
  margin: $unit $unit $unit 0;
}

.badges {
  flex-shrink: 0;

  span {
    display: inline;
  }
}

.avatar {
  align-self: flex-start;
  margin-top: $unit * 0.5;
  margin-right: $unit * 1.5;
}

.actions {
  margin-left: $unit;
}

@media (max-width: $breakpoint-mobile) {
  .detailsAndReviews {
    flex-flow: row nowrap;
  }
}
