@import "../../shared/colors";
@import "../../shared/vars";
@import "../../shared/theme";

.dropzone {
  display: flex;
  border: 2px dashed $gray-40;
  border-radius: $unit;
  flex-direction: column;
  height: $unit * 16;
  justify-content: center;
  margin: $unit 0;
  padding: $unit * 2;
  text-align: center;

  &.onRejected {
    border-color: $red-60;
  }

  &.onDragOver {
    border-color: $blurple-60;

    .attachmentIcon {
      svg {
        fill: $gray-50;
      }
    }
  }

  &.disabled {
    @include input-background-disabled;
  }
}

.attachmentButton {
  margin: $unit * 0.5 auto 0;
  width: $unit * 15;
}

.attachmentIcon {
  svg {
    fill: $gray-40;
  }
}

@include dark-theme {
  .dropzone {
    border: 2px dashed $gray-50;

    &.onRejected {
      border-color: $red-30;
    }

    &.onDragOver {
      border-color: $blurple-45;
    }
  }

  .attachmentIcon {
    svg {
      fill: $gray-50;
    }
  }
}
