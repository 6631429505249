@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

.wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}

.text {
  .truncate & {
    @include ellipsis;
  }
}

.icon {
  margin-right: 2px;
}

.fileName {
  @include text-color;

  font-weight: $font-medium;
}

.fileExtension {
  @include secondary-text-color;

  font-weight: $font-regular;
}

.focused {
  .fileName {
    color: $white !important;
  }

  .fileExtension {
    color: $white-a50 !important;
  }
}
