@import "../shared/animations";
@import "../shared/vars";

.messages {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: initial;
  overflow: hidden;
}

.message {
  display: flex;
  margin: 0 ($unit * 2) ($unit * 2);
}

.messageAppear,
.messageEnter {
  @include fade-in-left;

  animation-duration: 0.6s;
}

.messageLeave {
  @include fade-out;

  animation-duration: 0.3s;
}
