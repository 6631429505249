@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.tabs {
  @include border-bottom;

  justify-content: center;
  flex-shrink: 0;
}

.contentWrapper {
  @include border-top;

  flex-grow: 1;
  flex-basis: 450px;
  height: 0;
}

.content {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.noResults {
  display: flex;
}

.filterWrapper {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: $unit * 2;
}

// Hacky styles to let inset border on dark mode modal pass through
@include dark-theme {
  .content {
    padding: 0 1px 1px;
    border-bottom-left-radius: $unit;
    border-bottom-right-radius: $unit;
  }
}
