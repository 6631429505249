@import "../shared/colors";
@import "../shared/typography";
@import "../shared/vars";

.container {
  display: inline-flex;
  box-shadow: inset 0 0 0 1px $gray-50;
  margin-left: $unit;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: $font-medium;
  line-height: 14px;

  @include secondary-text-color;

  padding: 0 3px;
  border-radius: 3px;
}

.light {
  box-shadow: inset 0 0 0 1px $white-a50;
  color: $white-a50;
}
