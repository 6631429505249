@import "../shared/mixins";

.teams {
  @include asset(teams);

  display: block;
  width: 100%;
  max-width: 400px;
  height: 145px;
  background-position: center;
  background-size: contain;
  margin: ($unit * 2) auto ($unit * 3);
}

.learnMore {
  display: inline-flex;
  align-items: center;
}
