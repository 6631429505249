@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

// this breaks HMR (hot module reloading) on desktop
// & is incompatible with stylelint (property-no-unknown)
/* stylelint-disable */
:export {
  mediumDarkGrey: $gray-30;
}
/* stylelint-enable */

.shareLinkSignin {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  flex: 1 0 auto;
  padding: $unit * 2 $unit * 4;
  padding-bottom: $unit * 2.5;
}

.header {
  @include text-color;

  margin-top: 0;
  margin-bottom: $unit * 2;
  font-size: 18px;
  font-weight: $font-medium;
  text-align: center;
  word-break: break-word;
}

.paragraph {
  @include text-color;

  font-weight: $font-medium;
  font-size: 14px;
  padding: $unit 0;
}

.huge {
  justify-content: center;
}

.secondaryText {
  @include font-s;

  margin-top: $unit * 2.5;
}

.link {
  text-decoration: none;
}
