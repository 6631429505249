@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

$footer-height: 66px;

.wrapper {
  display: flex;
  position: relative;
  height: 100%;

  @media (min-width: 640px) {
    display: block;
    height: auto;
  }
}

.content {
  flex-grow: 1;
  height: 100%;

  @include scrollable;

  padding-bottom: $footer-height;
  background-color: rgba($gray-70, 0.75);

  @media (min-width: 0), (max-width: 640px) {
    @include scrollable;
  }

  @media (min-width: 640px) {
    max-height: 80vh;
  }
}

.contentInner {
  display: block;
  height: auto;
  min-height: 100%;
  position: relative;

  @media (min-width: 640px) {
    min-height: $unit * 44;
  }
}

.loadingOverlay {
  position: absolute;
  top: 0;
  bottom: $footer-height;
  left: 0;
  right: 0;
  background: $black-a50;
}

.footer {
  @include content-background;
  @include inset-border-top;

  padding: $unit * 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

:global(.ReactCrop) {
  display: block; // get rid of extra vertical space from display: inline-block
}

:global(.ReactCrop__image) {
  background-color: $white;
}
