@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.modal {
  @include content-background;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
}

.overlay {
  top: $unit * 6;
}

.page {
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 0;
  left: 0;
  padding-bottom: $unit * 10;
}

.sidebar {
  height: 100%;
}

.form {
  width: 480px;
  margin: $vpadding $hpadding;
}

.continue {
  margin-left: $unit * 2;
}

.footer {
  @include content-background;
  @include border-top;

  height: $unit * 10;
  padding: $vpadding $hpadding;
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

.left {
  @include font-l;
  @include text-color;

  font-weight: $font-medium;
}

.titleBranchSelector {
  display: block;
}
