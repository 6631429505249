@import "../shared/mixins";
@import "../shared/vars";

.wrapper {
  // Forces Flex to scroll
  min-height: 0;
}

.scrollableWrapper {
  @include scrollable;
}

.scrollableContent {
  @include scrollable;
  @include dynamic-padding;
}

.metricsList {
  padding: $unit;
  max-width: 938px; // matches 3 x MetricBox

  .listHeader {
    margin-bottom: $unit * 2;
  }
}

.emptyTable {
  // this ensures the padding around <Empty> matches within the Card padding
  padding-top: $unit * 2.5;
}

.disclaimer {
  @include secondary-text-color;
}

.userInfo {
  padding-left: $unit;

  .name {
    @include font-ml;

    font-weight: $font-medium;
  }

  .username {
    @include font-s;
    @include secondary-text-color;

    &::before {
      content: "@";
    }
  }
}
