@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/typography";

.placeholder {
  display: inline-block;

  @include shimmerBackground;

  width: 75%;
  border-radius: 2px;
  margin: 2px 0;
  user-select: none;
}

.xxxl {
  font-size: 32px;
  line-height: 36px;
}

.xxl {
  font-size: 28px;
  line-height: 32px;
}

.xl {
  font-size: 24px;
  line-height: 28px;
}

.l {
  font-size: 16px;
  line-height: 20px;
}

.m {
  font-size: 14px;
  line-height: 16px;
}

.s {
  font-size: 13px;
  line-height: 14px;
}

.xs {
  font-size: 11px;
  line-height: 10px;
}
