@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.innerListElement {
  border-left: $unit * 4 solid transparent;
  border-right: $unit * 4 solid transparent;
}

.gridRow {
  padding-left: $unit * 4;
  padding-right: $unit * 2;

  .gridItem {
    padding-bottom: 0;
  }

  &:last-child {
    margin-bottom: $unit;
  }
}

.listItem {
  @include virtualized-card-wrapper;

  &::after {
    @include border-bottom;

    position: absolute;
    content: "";
    top: 0;
    left: $unit * 2;
    right: $unit * 2;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    border: 0;
    padding: 0;

    &::after {
      left: 0;
      right: 0;
    }
  }
}

.firstListItem {
  @include border-top;

  @media screen and (min-width: $breakpoint-mobile) {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  &::after {
    border: 0;
  }
}

.mobile {
  .team {
    @include content-background;
  }
}

.list {
  &:hover + .list {
    .team::after {
      left: 0;
      right: 0;
    }
  }

  .team {
    @include content-background;
    @include border-left;
    @include border-right;
  }

  &.firstListRow {
    .team {
      @include border-top;

      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      &::after {
        border: none;
      }
    }
  }

  &.lastListRow {
    .team {
      @include border-bottom;

      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}

.lastListItem {
  @include border-bottom;

  margin-bottom: $unit * 3;

  @media screen and (min-width: $breakpoint-mobile) {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.hoverable {
  @media screen and (min-width: $breakpoint-mobile) {
    &::after {
      @include quick-transition(background);

      background: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      right: $unit * 2.5;
      left: $unit * 2.5;
      content: "";
      z-index: -1;
    }
  }

  &:first-child::after {
    border-top-right-radius: $unit * 1.5;
    border-top-left-radius: $unit * 1.5;
  }

  &:last-child::after {
    border-bottom-right-radius: $unit * 1.5;
    border-bottom-left-radius: $unit * 1.5;
  }

  &.lastListRow::after {
    bottom: -$unit * 1.5;
  }
}

.expandButtonSideMargin {
  margin: 0 $unit * 4;
}

.expandButtonChin {
  @include border-bottom;
  @include content-background;

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid var(--border-color);
  border-top: none;
  box-shadow: none;
}

.isMobile {
  &.expandButtonChin::after {
    left: 0;
    right: 0;
  }
}

.expandButtonInner {
  padding: 0 $unit;
}

.waypoint {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.sectionHeader {
  @include font-ml;

  font-weight: $font-medium;
  margin-bottom: $unit * 2;

  &.grid {
    margin-left: $unit * 4;
  }
}

.noTeams {
  @include content-background;
  @include border;

  border-radius: 6px;
}

.noTeamsMobile {
  border-radius: initial;
  border-left: none;
  border-right: none;
}

.noTeamsGrid {
  left: $unit * 4 !important;
  right: $unit * 4;
  width: auto !important;
}
