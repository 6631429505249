@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 135px;
  padding-left: $unit * 3;
  padding-right: $unit * 4;
  background: $blurple-60;
  border-bottom: 1px solid $blurple-50;
}

.close {
  margin-right: $unit;
}

.left {
  @include font-l;

  color: $white;
  font-weight: $font-medium;
}

.layerCount {
  font-feature-settings: "tnum";
}
