@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.center {
  text-align: center;
}

.greyLink {
  @include font-s;

  color: $gray-50;

  &:hover {
    color: darken($gray-50, 10%);
  }
}

.logos {
  display: flex;
  align-items: center;
}

.plusIcon {
  margin: $unit;
}

.ignoreButton {
  margin: $unit * 2.5 0;
  width: 100%;
}
