@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";

.picker {
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba($black, 0.15);
}

.palette {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: $unit;
  margin: 0;

  .swatchWrapper {
    cursor: pointer;
  }
}

.noLines {
  &.picker {
    box-shadow: none;
  }

  .palette {
    padding: 0;
  }

  .custom {
    border-top: none;
    padding: $unit 0 0 0;

    .color {
      right: $unit * 0.5;
    }
  }
}

.color {
  padding: $unit * 0.5;
  display: flex;
  justify-content: center;
  position: relative;

  input {
    position: absolute;
    visibility: hidden;
  }

  &.selected {
    &::after {
      content: "";
      position: absolute;
      top: $unit * 0.5;
      right: $unit * 0.5;
      bottom: $unit * 0.5;
      left: $unit * 0.5;
      box-shadow:
        0 0 0 2px $blurple-60,
        0 0 0 1px $white inset;
      border-radius: 3px;
    }
  }
}

.swatchWrapper {
  display: flex;
  width: 100%;
  position: relative;
  padding-bottom: 100%;
}

.swatch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 3px;
  box-shadow: 0 0 0 1px $black-a10 inset;

  &.selected {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow:
        0 0 0 2px $blurple-60,
        0 0 0 1px $white inset;
      border-radius: 3px;
    }
  }
}

.swatchLarge {
  border-radius: 6px;

  &.selected::after {
    border-radius: 6px;
  }
}

@include dark-theme {
  .noLines {
    &.picker {
      box-shadow: none;
    }
  }

  .picker {
    @include box-shadow;
    @include input-background-disabled;

    .color {
      &.selected {
        &::after {
          box-shadow:
            0 0 0 2px $blurple-60,
            0 0 0 1px $gray-70 inset;
        }
      }
    }

    .swatch {
      box-shadow: 0 0 0 1px $white-a15 inset;

      &.selected {
        &::after {
          box-shadow:
            0 0 0 2px $blurple-60,
            0 0 0 1px $gray-70 inset;
        }
      }
    }
  }
}

.custom {
  @include border-top;

  display: flex;
  justify-content: space-between;
  padding: $unit;
  position: relative;

  // input wrapper
  > div {
    margin: 0;
  }

  > label {
    display: flex;
    justify-content: space-between;
    font-weight: $font-regular;
    align-items: center;
    flex: 1;

    span {
      font-size: 13px;
      font-weight: $font-medium;
      padding-left: $unit * 0.5;
    }
  }

  input {
    margin-left: $unit;
    width: 128px;
    padding-right: $unit * 4;
  }

  .color {
    position: absolute;
    top: $unit * 1.5;
    right: $unit * 1.5;
    width: $unit * 3;
    height: $unit * 3;
    padding: 0;
  }
}
