@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.wrap {
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.list,
.mobile {
  text-decoration: none;

  .action {
    margin-left: $unit * 2;
  }

  .name {
    @include text-color;
    @include ellipsis;

    display: flex;
    min-width: 0;
    align-items: center;
    font-weight: $font-medium;
    margin-bottom: $unit * 0.25;
  }

  .nameInner {
    @include font-ml;

    margin-right: $unit;
  }

  .section {
    flex-grow: 1;
    min-width: 0;
    margin-left: $unit * 2;
    margin-right: $unit * 2.5;
  }

  .sectionRight {
    text-align: right;
    margin-right: 0;
    flex-shrink: 0;
  }
}

.card {
  width: 100%;
  position: relative;
  text-align: center;

  .wrap {
    height: 0;
    padding-bottom: 100%;
  }

  .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    justify-content: center;
  }

  .name {
    @include font-l;
    @include text-color;

    display: block;
    font-weight: $font-medium;
    text-align: center;
    margin-top: $unit;
  }

  .section {
    padding: $unit * 1.5;
    width: 90%;
    max-width: 228px;
    align-items: center;

    @include border-bottom;

    padding-top: 0;
  }

  .facepile {
    margin-top: $unit * 1.5;
  }

  .action {
    position: absolute;
    top: $unit * 2;
    right: $unit * 2;
  }
}

.list {
  position: relative;
  width: 100%;
  box-shadow: none;
  background: none;
  border-radius: 0;
  margin-bottom: 0;
}

.mobile {
  .facepile,
  .placeholderFacepile {
    display: none;
  }
}

.placeholderNote {
  @include font-s;

  margin: 0;
  padding: 0;
}

.placeholderFacepile {
  margin-top: $unit * 1.5;
}

.action {
  display: flex;
  align-items: center;
}
