@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";

.accountButton {
  @include font-m;
  @include text-color;

  flex-shrink: 1;
  text-transform: none;
  margin: $unit * -0.5;

  &:hover {
    color: inherit !important;
  }
}

.accountButtonInner {
  margin: $unit * 0.5 0;
}

.userName {
  @include ellipsis;

  margin-left: $unit;
}

.arrowsIcon {
  width: $unit * 2;
  height: $unit * 2;
  margin-left: $unit;
}
