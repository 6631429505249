@import "../shared/vars";
@import "../shared/theme";

.mainContent {
  max-width: 100%;
}

.teamAvatar {
  margin: $unit;
}

.wrap {
  flex-grow: 1;
  position: relative;
}

.expandButtonSideMargin {
  margin: 0 $unit * 4;
}

.expandButtonChin {
  @include border-bottom;
  @include content-background;

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid var(--border-color);
  border-top: none;
  box-shadow: none;
}

.expandButtonInner {
  padding: 0 $unit;
}
