@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.noBottomMargin {
  margin-bottom: 0;
}

.note {
  margin-top: $unit * 3;
  text-align: center;
}

// SAMLRedirect.js styles
.ssoWrap {
  margin-top: $unit * 1.5;
}

.controls {
  padding-top: $unit * 2.5;
}

.controlsHelperText {
  @include secondary-text-color;
}

.buttonRow {
  padding-top: $unit * 3.5;

  .routeButton {
    margin-left: -$unit;
  }

  .routeButton + .routeButton {
    margin-left: $unit * 3;
  }
}
