@import "../shared/mixins";

.buttonUnstyled {
  @include outer-keyboard-focus;

  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
}
