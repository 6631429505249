@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.wrap {
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
}

.action {
  display: flex;
  align-items: center;
  visibility: hidden;
}

.showActions {
  visibility: visible;
}

.badges {
  display: inline-flex;
  align-items: center;
}

.badge {
  @include ellipsis;

  margin-right: $unit;
}

.list,
.mobile {
  .action {
    margin-left: $unit * 2;
  }

  .name {
    display: flex;
    align-items: center;
    font-weight: $font-medium;
    margin-bottom: $unit * 0.25;
  }

  .nameInner {
    @include font-ml;

    margin-right: $unit;
  }

  .section {
    flex-grow: 1;
    margin-left: $unit * 2;
    margin-right: $unit * 2.5;
  }

  .sectionRight {
    text-align: right;
    margin-right: 0;
    flex-shrink: 0;
  }
}

.card {
  width: 100%;
  position: relative;

  .wrap {
    height: 0;
    padding-bottom: 100%;
  }

  .badges {
    position: absolute;
    top: $unit * 2.5;
    left: $unit * 1.5;
    margin-left: $unit;
  }

  .inner {
    padding: $unit;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .avatar {
    margin-top: $unit * 2;
  }

  .name {
    @include font-l;

    font-weight: $font-medium;
    margin-top: 12px;
    white-space: nowrap;
    max-width: 100%;
    display: flex;
  }

  .nameInner {
    @include ellipsis;
  }

  .username {
    @include font-m;

    overflow: hidden;
    flex-wrap: nowrap;

    .user {
      margin-bottom: $unit * 0.5;
    }

    .badges {
      position: static;
      overflow: hidden;
    }
  }

  .section {
    padding: $unit * 1.5;
    width: 90%;
    max-width: 228px;
    align-items: center;
  }

  .sectionTop {
    @include border-bottom;

    padding-top: 0;
  }

  .action {
    position: absolute;
    top: $unit * 2;
    right: $unit * 2;
  }
}
