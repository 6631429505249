@import "../shared/colors";
@import "../shared/typography";
@import "../shared/vars";

.bg {
  fill: $gray-40;
}

.text {
  @include font;

  dominant-baseline: central;
  text-anchor: middle;
  font-weight: $font-medium;
  fill: $white;
}
