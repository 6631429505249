@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

.details {
  padding: $unit * 1.5 $unit * 2;
  padding-top: 0;
}

.detail {
  display: flex;

  & + & {
    margin-top: $unit;
  }
}

.detailLabel {
  @include font-xs;
  @include secondary-text-color;

  line-height: 20px; // match .detailValue
  text-transform: uppercase;
  min-width: $unit * 11;
}

.detailValue {
  @include font-m;

  a {
    @include text-color;
    @include outer-keyboard-focus;

    border-radius: 3px;
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.detailLoading {
  @include secondary-text-color;
}

.divider {
  @include border-bottom;

  height: 0;
  margin: $unit * 2 0;
}

.fileName {
  font-weight: $font-regular;
}

.commitValue {
  @extend .detailValue;

  white-space: normal;
}

.commitMeta {
  @include font-s;
  @include secondary-text-color;
}

.commitUserName {
  @include ellipsis;

  display: inline-block;
  max-width: 50%;
  vertical-align: bottom;
}
