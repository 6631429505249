// These variables should only be imported once per-platform from a root-level
// component.

@import "../shared/theme";
@import "../shared/colors";

@mixin default-theme {
  --content-background: #{$white};
  --secondary-content-background: #{$gray-10};
  --text-color: #{$gray-70};
  --secondary-text-color: #{$gray-60};
  --placeholder-text-color: #{$gray-50};
  --border-color: #{$black-a10};
  --input-background: #{$white};
  --input-background-disabled: #edeeef;
  --input-box-shadow-color: #{$black-a10};
  --box-shadow-focus-color: #{$blurple-60};
  --keyboard-focus-color: #{$blurple-30};
  --content-border-color: #{$black-a10};
  --link-color: #{$blurple-60};
  --link-color-inverse: #{$blurple-45};
  --link-color-hover: #{$blurple-70};
  --link-color-hover-inverse: #{$blurple-40};
  --link-color-active: #{$blurple-80};
  --link-color-active-inverse: #{$blurple-30};
  --error-text-color: #{$red-60};
  --purple-text-color: #5a5ae6;
  --allgood-text-color: #{$green-50};
  --cell-hover-background: #edeeef;
  --secondary-selected-background: #{$black-a15};
  --menu-icon-color: #{$black-a50};
  --menu-hover-background-color: #{$black-a05};
  --shimmer-background-color: #{$black-a05};
  --selected-yolk-background: #{$yolk-10};
  --card-shadow-color: #{$black-a05};
  --secondary-border-color: #{$black-a10};
}

@include light-theme {
  @include default-theme;
}

@include dark-theme {
  --content-background: #{$gray-70};
  --secondary-content-background: #{$gray-80};
  --text-color: #{$white};
  --secondary-text-color: #{$gray-30};
  --placeholder-text-color: #{$gray-50};
  --border-color: #{$white-a15};
  --input-background: #454747;
  --input-background-disabled: #353638;
  --input-box-shadow-color: #{$white-a25};
  --box-shadow-focus-color: #{$blurple-60};
  --keyboard-focus-color: #{$blurple-70};
  --content-border-color: #{$black};
  --link-color: #{$blurple-45};
  --link-color-inverse: #{$blurple-60};
  --link-color-hover: #{$blurple-40};
  --link-color-hover-inverse: #{$blurple-70};
  --link-color-active: #{$blurple-30};
  --link-color-active-inverse: #{$blurple-80};
  --error-text-color: #{$red-30};
  --purple-text-color: #7f7ffe;
  --allgood-text-color: #{$green-50};
  --cell-hover-background: #{$gray-80};
  --secondary-selected-background: #{$black-a25};
  --menu-icon-color: #{$white};
  --menu-hover-background-color: #{$black-a10};
  --shimmer-background-color: #{$white-a10};
  --selected-yolk-background: #{$yellow-70};
  --card-shadow-color: #{$black-a15};
  --secondary-border-color: #{$black-a15};
}
