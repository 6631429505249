/* stylelint-disable no-invalid-position-at-import-rule */
@use "sass:math";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";
/* stylelint-enable no-invalid-position-at-import-rule */

%toolbar {
  display: flex;
  align-items: center;
  min-height: $unit * 6;
  padding: 0 $unit * 1.5;

  & > button + button {
    margin-left: $unit; // Automatically space button elements
  }
}

.layerBuild {
  flex: 1;
  display: flex;
  min-height: 0;
  overflow: hidden; // to hide collapsed right sidebar
}

.layerSearch {
  padding: $unit * 1.5 $unit * 2;

  @include border-bottom;
}

.layerTreeScrollable {
  display: flex;
  flex-direction: column;
}

ul.layerTree {
  margin: 0;
  padding: 0;
}

.layerTreeHeader {
  min-height: $unit;
}

.layerTreeFooter {
  flex: 1;
  min-height: $unit;
}

.main {
  display: flex;
  flex: 1;

  @media (min-width: $breakpoint-mobile) {
    min-width: $unit * 52.5;
  }
}

// Helps to position the floating canvas buttons
// anchored to the top-left of a canvas display.
.floatingCanvasButtonContainer {
  height: 0;
  width: 0;
  position: relative;
}

.sidebarMaxWidth {
  // Uses !important to override re-resizable's inline maxWidth set
  max-width: #{$sidebar-width-ratio * 100}vw !important;

  // Hard-limit for sidebar's max resizable size
  @media (min-width: #{math.div($sidebar-resizable-max-width, $sidebar-width-ratio)}) {
    max-width: $sidebar-resizable-max-width !important;
  }
}

.sidebarContent {
  @include content-background;
}

.layerCanvas {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.layerInspectorOffset {
  position: relative;
  top: -1px;
}

.gridContainer {
  position: absolute;
  top: 0;
  left: 0;
}

.layerCanvasFooter {
  @include inset-border-top;

  min-height: $unit * 6;
}

.toggleLeftSidebarButton {
  margin-right: $unit * 1.5;

  @media (max-width: 960px) {
    display: none;
  }
}

.toggleRightSidebarButton {
  margin-left: $unit * 1.5;

  @media (max-width: 960px) {
    display: none;
  }
}

.mobileToolbarLeft {
  @extend %toolbar;
}

.mobileToolbarRight {
  @extend %toolbar;
}

.footerToolbarCenter {
  @extend %toolbar;
}

.footerToolbarLeft {
  @extend %toolbar;
}

.footerToolbarRight {
  @extend %toolbar;
}

@media (max-width: $breakpoint-tablet) {
  .divider {
    display: none;
  }
}

.divider {
  height: $unit * 3;
  width: 1px;
  background: $black-a15;
}

.dividerLeftToolbar {
  margin-right: $unit * 1.5;
}

.dividerRightToolbar {
  margin-left: $unit * 2;
}

@include dark-theme {
  .divider {
    background: $white-a15;
  }
}

.togglePreviewBackgroundButton {
  position: relative;
  margin-right: $unit;
}
