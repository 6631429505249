@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.bodyOpen {
  overflow: hidden;
}

.overlay,
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.macOSWithTitleBar {
  top: $title-bar-height;
}

.overlay {
  z-index: $depth-dialogs + 1;
  overflow: hidden;
  background-color: $black-a50;
  display: flex;
  align-items: center;
  justify-content: center;

  &.withTransitions {
    @include quick-transition(opacity);

    opacity: 0;
  }
}

.webWithTitleBarMobile {
  z-index: $depth-dialogs; // Make sure to override .overlay when we're displaying the title bar. This ensures that the flyovers from titlebar are above the modal content.

  @media (max-width: $breakpoint-tablet) {
    top: $unit * 6;
  }
}

.webWithTitleBarDesktop {
  z-index: $depth-dialogs; // Make sure to override .overlay when we're displaying the title bar. This ensures that the flyovers from titlebar are above the modal content.
}

.overlayAfterOpen {
  &.withTransitions {
    opacity: 1;
  }
}

.overlayBeforeClose {
  &.withTransitions {
    @include quick-transition(opacity);

    opacity: 0;
  }
}

.modal {
  @include content-background;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.withTransitions:not(.opacityTransitionOnly) {
    @include quick-transition(transform);

    transform: translate3d(0, 20px, 0);
  }

  &:focus {
    outline: none;
  }
}

.modalAfterOpen {
  &.withTransitions:not(.opacityTransitionOnly) {
    transform: translate3d(0, 0, 0);
  }
}

.sizeMedium,
.sizeLarge {
  @media (min-width: $breakpoint-mobile) {
    @include modal-shadow;

    width: $unit * 63;
    height: auto;
    min-height: 150px;
    max-height: 90%;
    border-radius: 16px;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;

    @include dark-theme {
      @include modal-outline;
    }
  }
}

.sizeLarge {
  @media (min-width: $breakpoint-mobile) {
    width: 736px;
  }
}

.modalFixedHeight {
  height: 90%;
  max-height: 600px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  flex-grow: 1;
  height: auto;
  position: relative;
  width: 100%;
}

@media (min-width: $breakpoint-mobile) {
  .medium .content {
    position: static;
    display: unset;
    width: auto;
  }
}

.header {
  text-align: center;
  position: relative;
}

.headerWithTitle {
  @include border-bottom;

  min-height: $unit * 6;
  padding: 14px;
}

.headerCloseButton {
  position: absolute !important;
  left: $unit * 1.5;
  top: $unit * 1.5;
  z-index: 1;
}

.headerSubmitButton {
  position: absolute !important;
  top: $unit * 1.5;
  right: $unit * 1.5;
  z-index: 1;
}

.headerHidden {
  z-index: 1;
  opacity: 0;
}
