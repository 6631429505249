@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/vars";

.menu {
  padding: $unit * 0.5;
  line-height: $unit * 3;
  user-select: none;
}

.scroll {
  overflow-y: auto;
}

.separator {
  @include border-top;

  margin-top: $unit * 0.5;
  margin-left: $unit * -0.5;
  margin-right: $unit * -0.5;
  padding-top: $unit * 0.5;
}

.item {
  @include font-m;
  @include text-color;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: inherit;
  border: none;
  padding: 6px 16px;
  outline: none;
  width: 100%;

  &.hoverMenuItem {
    padding: 0;
  }
}

.item,
.hoverMenuLabel {
  border-radius: 4px;
}

.compact {
  padding-top: 2px;
  padding-bottom: 2px;

  .hoverMenuLabel {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.control {
  margin-left: $unit * 3;
  opacity: 0.7;
}

.enabled {
  @include focus-visible using ($slot) {
    @if $slot == focus {
      color: $white;
      background: $blurple-60;
      box-shadow: none;
    } @else if $slot == reset {
      color: unset;
      background: unset;
      box-shadow: unset;
    }
  }

  .hoverMenuOpen,
  &:hover,
  &:hover:focus,
  &:active {
    color: $white;
    background: $blurple-60;
    box-shadow: none;
  }

  &:active {
    background: $blurple-70;
  }
}

.disabled {
  opacity: 0.5;
  color: $gray-70;
}

.danger {
  color: $red-50;

  @include focus-visible using ($slot) {
    @if $slot == focus {
      color: $white;
      background: $red-60;
    } @else if $slot == reset {
      color: $red-50;
      background: unset;
    }
  }

  &.enabled {
    &:hover,
    &:hover:focus,
    &:active {
      color: $white;
      background: $red-60;
    }

    &:active {
      background: $red-70;
    }
  }
}

.checkbox {
  padding: 4px 16px 4px 12px;

  &.compact {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.checkmarkContainer {
  width: $unit * 3;
  height: $unit * 3;
  flex-shrink: 0;
  margin-left: -$unit;
  margin-right: $unit * 0.5;
}

@include dark-theme {
  .enabled {
    fill: $white;

    @include focus-visible using ($slot) {
      @if $slot == focus {
        color: $white;
        background: $blurple-60;
      } @else if $slot == reset {
        color: unset;
        background: unset;
      }
    }

    &:hover,
    &:hover:focus,
    &:active,
    .hoverMenuOpen {
      color: $white;
      background: $blurple-60;
    }

    &:active {
      background: $blurple-70;
    }
  }

  .disabled {
    fill: $white-a50;
    color: $white-a50;
  }

  .danger {
    color: $red-30;

    @include focus-visible using ($slot) {
      @if $slot == focus {
        color: $white;
        background: $red-40;
      } @else if $slot == reset {
        color: $red-30;
        background: unset;
      }
    }

    &.enabled {
      &:hover,
      &:hover:focus,
      &:active {
        color: $white;
        background: $red-40;
      }

      &:active {
        background: $red-50;
      }
    }
  }
}

// For menu items with submenus we must move the padding inwards a couple of
// elements to enable us to keep it "active" when the submenu is open
.hoverMenuLabel {
  padding: 4px 6px 4px 16px;
}
