@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";

@keyframes animateBannerHeight {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 4 * $unit;
    opacity: 1;
  }
}

.wrap {
  width: 100%;
}

.subheading {
  font-weight: $font-medium;

  @include ellipsis;
}

.banner {
  height: 0;
  line-height: $unit * 1.5;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition:
    height 200ms $super-ease-out,
    opacity 200ms $super-ease-out;
}

.activeBanner {
  height: $unit * 4;
  opacity: 1;
  animation: animateBannerHeight 300ms;
  animation-timing-function: ease-in-out;
}

.bannerMessage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.successMessageIcon {
  margin-right: $unit * 0.5;
}

.error {
  @include font-s;

  padding: 0;
  color: $white;
}

@include dark-theme {
  .error {
    color: $white;
  }
}

.headerButton {
  display: inherit;
  z-index: 0;
  margin-right: 1px;
  margin-left: $unit;
}

.icon {
  display: none;
}

@media (max-width: $breakpoint-mobile) {
  .icon {
    display: flex;
    margin-right: $unit * 0.5;
  }

  .wrapper {
    overflow: hidden;
  }

  .heading {
    font-size: 14px !important;
    font-weight: $font-medium;
    overflow: hidden;
  }
}
