@import "../../../shared/animations";

.rect {
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  box-shadow:
    0 0 0 1px #e86e2c,
    inset 0 0 0 1px #e86e2c;
  background-color: rgba(232, 110, 44, 0.15);

  &.disabled {
    cursor: default;
    box-shadow:
      0 0 0 1px #5a5959,
      inset 0 0 0 1px #5a5959;
    background-color: rgba(136, 137, 138, 0.15);
    pointer-events: none;
  }
}

.hiddenPreloadedImage {
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  touch-action: none;

  // Properties below used for debugging
  // opacity: 1;
  // visibility: visible;
  // height: 100px;
  // width: 100px;
  // object-fit: contain;
}
