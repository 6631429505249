@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";

.buttonWrapper {
  position: relative;
  width: $unit * 3;
  height: $unit * 3;
}

.hasNotificationsButton {
  @include button-reset;

  position: absolute;
  top: 0;
  left: 0;
  width: $unit * 3;
  height: $unit * 3;
  color: $blurple-60;
  border-radius: 25%;

  &:hover {
    color: $blurple-70;
  }

  &:active {
    color: $blurple-80;
  }
}

.buttonBackground {
  &.buttonBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: $unit * 3;
    height: $unit * 3;
    color: inherit;
  }
}

.bellIcon {
  &.bellIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: $unit * 3;
    height: $unit * 3;
  }
}

.notificationDot {
  position: absolute;
  top: $unit * -0.25;
  right: $unit * -0.25;
  width: $unit;
  height: $unit;
  background: #f2525a;
  border-radius: 50%;
  pointer-events: none;
}

.wrapper {
  position: relative;
}

.contextMenuRef {
  position: absolute;
  top: $unit * 3;
  right: $unit * 3;
  height: 1px;
  width: 1px;
}

.notificationsListFlyover {
  width: 400px;
  max-width: 90vw;
  padding: 0;
}

.unreadNotificationsColor {
  color: $blurple-45;
}

.markNotificationsAsRead {
  cursor: default;
  position: absolute;
  right: $unit;
}

.notificationsHeader {
  box-shadow: 0 1px 0 0 var(--border-color);
  padding: $unit * 1.5;
  position: relative;
  z-index: 3; // Used to sit above the <StickyHeader>
}

.notificationsOrgSwitcher {
  cursor: default;
}

.notificationsList {
  height: 80vh;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  transform: translateZ(0);
}

.notificationsLoadingSpinner {
  padding: $unit * 2 0;
}

.notificationsListElement {
  margin: 0;
  padding: 0;
}

.notificationLink {
  color: var(--text-color);
  cursor: default;
  display: flex;
  padding: $unit * 1.5 $unit * 2;
  text-decoration: none;
  width: 100%;
}

// Higher specificity for SVGIcon.svgContainer
.notificationAvatarContainer .notificationIcon {
  background: var(--content-background);
  border-radius: 50%;
  bottom: -6px;
  padding: 2px;
  position: absolute;
  right: -6px;
}

.notificationListItem {
  margin: 0;
  height: 77px; // Hardcoded for virtualization
  padding: 0;
  position: relative;

  &.notificationMenuOpen,
  &:hover,
  &:focus {
    @include cell-hover-background;

    .notificationLink {
      color: var(--text-color);
    }

    .notificationIcon {
      background: var(--cell-hover-background);
    }
  }
}

.notificationAvatarContainer {
  position: relative;
  height: $unit * 4;
}

.notificationAvatar {
  margin-top: 2px;
}

.notificationCopy {
  line-height: 18px;
  margin-left: $unit * 1.5;
  min-width: 0; // Necessary for text truncation on notificationMeta
  position: relative;
  user-select: none;
  width: 100%;
}

.notificationMessage {
  @include multiline-ellipsis(2);
}

.notificationMeta {
  @include ellipsis;
  @include font-s;
  @include secondary-text-color;
}

.unreadNotification {
  background: $yellow-10;

  .notificationIcon {
    background: $yellow-10;
  }

  &.notificationMenuOpen,
  &:hover,
  &:focus {
    background: $yellow-20;

    .notificationIcon {
      background: $yellow-20;
    }
  }
}

@include dark-theme {
  .notificationsList {
    // 1px of horizontal padding so the border box-shadows don't bleed
    padding-left: 1px;
    padding-right: 1px;
  }

  .unreadNotification {
    background: $yellow-80;

    .notificationIcon {
      background: $yellow-80;
    }

    &.notificationMenuOpen,
    &:hover,
    &:focus {
      background: $yellow-70;

      .notificationIcon {
        background: $yellow-70;
      }
    }
  }
}
