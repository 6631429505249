@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";
@import "../shared/typography";

.innerListElement {
  border-left: $unit * 4 solid transparent;
  border-right: $unit * 4 solid transparent;
}

.row {
  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 $unit * 4;
  }
}

.mobile {
  .team {
    @include content-background;
  }
}

.list {
  &:hover + .list {
    .team::after {
      left: 0;
      right: 0;
    }
  }

  .team {
    @include content-background;
    @include border-left;
    @include border-right;
  }

  &.firstListRow {
    .team {
      @include border-top;

      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      &::after {
        border: none;
      }
    }
  }

  &.lastListRow {
    .team {
      @include border-bottom;

      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}

.hoverable {
  @media screen and (min-width: $breakpoint-mobile) {
    &::after {
      @include quick-transition(background);

      background: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      right: $unit * 2.5;
      left: $unit * 2.5;
      content: "";
      z-index: -1;
    }
  }

  &:first-child::after {
    border-top-right-radius: $unit * 1.5;
    border-top-left-radius: $unit * 1.5;
  }

  &:last-child::after {
    border-bottom-right-radius: $unit * 1.5;
    border-bottom-left-radius: $unit * 1.5;
  }

  &.lastListRow::after {
    bottom: -$unit * 1.5;
  }
}

.gridRow {
  padding-left: $unit * 4;
  padding-right: $unit * 2;

  .gridItem {
    padding-bottom: 0;
  }

  &:last-child {
    margin-bottom: $unit;
  }
}

.waypoint {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.sectionHeader {
  @include font-ml;

  font-weight: $font-medium;
  margin-bottom: $unit * 2;

  &.grid {
    margin-left: $unit * 4;
  }
}
