@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.container {
  @include secondary-content-background;
  @include inset-border-top;

  padding: $unit * 1.5 $unit * 3;
  margin-top: 1px;

  @media (min-width: 1024px) {
    padding: $vpadding $hpadding;
  }
}

.heading {
  margin-bottom: $unit;
  padding: 3px 0;

  @media (min-width: $breakpoint-mobile) {
    margin-bottom: $unit * 2;
  }
}

$comments-box-border-radius: 12px;

.comments {
  @include content-background;
  @include border;

  border-radius: $comments-box-border-radius $comments-box-border-radius 0 0;
  border-bottom: 0;
  padding: $unit 0;
}

.commentForm {
  @include content-background;

  border-radius: $comments-box-border-radius;

  &.hasComments {
    border-top: 0;
    border-radius: 0 0 $comments-box-border-radius $comments-box-border-radius;

    form {
      border-radius: 0 0 $comments-box-border-radius $comments-box-border-radius;
    }
  }
}

.createCommentForm {
  @include border;
  @include input-background;

  padding: $unit * 0.5 $unit * 2;
  border-radius: $comments-box-border-radius;
}
