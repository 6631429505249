@import "../shared/vars";

.avatarWrapper {
  position: relative;
  font-size: 0;
  line-height: 0;
}

.teamAvatarPip {
  position: absolute;
  bottom: 0;
  right: 0;
}

.offset {
  bottom: $unit * -0.5;
  right: $unit * -0.5;
}
