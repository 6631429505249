@import "../../shared/mixins";
@import "../../shared/vars";

.about {
  padding: $unit * 2;
}

.aboutFormButton {
  margin-left: $unit;
}

.focused {
  @include box-shadow-focus;
}

@include dark-theme {
  .focused {
    @include box-shadow-focus;
    @include input-background;
  }
}

.noAbout {
  padding: 0 0 $unit * 2;
}
