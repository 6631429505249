@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.switch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.channelsWrapperWithSelections {
  padding: $unit 0 7px;
}

.channelsWrapperWithoutSelections {
  padding: $unit 0 $unit * 5;
}
