@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.container {
  @media (min-width: $breakpoint-mobile) {
    padding: $unit * 3 $unit * 4;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $unit * 3;

  @media (min-width: $breakpoint-mobile) {
    flex-wrap: nowrap;
    margin-bottom: $unit * 2;
    padding: 0;
  }
}

.headerTitle {
  @media (min-width: $breakpoint-mobile) {
    order: 1;
    width: 25%;
  }
}

.emptySpace {
  display: flex;
  justify-content: flex-end;

  @media (min-width: $breakpoint-mobile) {
    order: 3;
    width: 25%;
  }
}

.headerButtons {
  width: 100%;
  margin-top: 0;
  margin-bottom: $unit * 3;
  padding: 0 $unit * 3;

  @media (min-width: $breakpoint-mobile) {
    width: 250px;
    padding: 0;
    order: 2;
  }
}

.headerButton {
  width: 50%;
}

.main {
  @media (min-width: $breakpoint-mobile) {
    display: flex;
    align-items: stretch;
  }
}

.card {
  @include content-background;
  @include border-top;
  @include border-bottom;

  @media (min-width: $breakpoint-mobile) {
    @include border;

    border-radius: 6px;
  }
}

.cardColumn {
  composes: card;

  @media (min-width: $breakpoint-mobile) {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}

.cardColumnFirst {
  composes: cardColumn;
  margin-bottom: 16px;

  @media (min-width: $breakpoint-mobile) {
    margin-right: 16px;
    margin-bottom: 0;
  }
}

.cardBody {
  padding: $unit * 4;
  flex-grow: 1;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 996px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.enterpriseExplanation {
  @include display-m;

  margin-top: 4px;
  margin-bottom: 25px; // so the button is inline with the other prices
}

.cardHeaderBadge {
  @include font-xs;

  display: inline-block;
  text-transform: uppercase;
  background-color: $green-50;
  color: $white;
  border-radius: 3px;
  padding: 0 4px;
}

.cardHeaderBadgeInTrial {
  @include font-s;

  display: inline-block;
  background-color: $white;
  color: $red-60;
  border-radius: 3px;
  padding: 0 4px;
  border: 1px solid $red-60;
}

.priceContainer {
  display: flex;
  align-items: baseline;
  margin-bottom: $unit;
}

.cardPrice {
  @include display-xxxl;
  @include text-color;
}

.perDate {
  @include font-s;
}

.priceCaveat {
  @include font-s;
  @include secondary-text-color;

  margin-bottom: $unit * 2;
}

.userNumberRecommendation {
  font-weight: $font-medium;
  margin-bottom: $unit;
}

.priceFlex {
  display: flex;
  align-items: center;
}

.cardFooter {
  padding: $unit * 3;
  background-color: $gray-10;
  border-top: $default-border;
}

.footnote {
  margin: 0;
  padding: $unit * 3;

  @media (min-width: $breakpoint-mobile) {
    margin: 16px 0;
    padding: 0;
  }
}

.featureList {
  padding: 0 0 0 $unit * 2;
}
