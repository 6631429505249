@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.form {
  padding: 0;
}

.footer {
  padding: $unit * 3 $unit * 4;

  @media screen and (min-width: $breakpoint-mobile) {
    border-top: $default-border;
  }
}
