@import "../../shared/theme";
@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/mixins";

.content {
  @include border-bottom;

  padding-bottom: $unit * 2;
  margin-bottom: $unit * 2;
}

.innerTitle {
  font-weight: $font-medium;
  text-transform: uppercase;
}

.loadingContent {
  @include flex;

  min-height: $unit * 20;
}

.lightIcon {
  opacity: 0.5;
}

.learnLink {
  text-decoration: underline;
}

.label {
  max-width: 300px;
  margin-left: -4px;
}

.table {
  width: 100%;

  th {
    @include font-s;

    font-weight: normal;
    text-align: left;
    vertical-align: top;
    padding-bottom: $unit * 1.5;
  }

  td {
    padding-bottom: $unit * 1.5;
  }

  .widerColumn {
    width: 55%;
  }
}
