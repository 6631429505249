@import "./colors";

// base
$unit: 8px;

// typography
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

// layout
$title-bar-height: 38px;
$invite-dialog-width: 400px;
$color-bar-height: 4px;
$header-height: $unit * 12;
$sidebar-min-width: $unit * 30;
$sidebar-max-width: $unit * 35;
$sidebar-wide-width: 40 * $unit;
$sidebar-wide-max-width: 45 * $unit;
$sidebar-width-ratio: 0.2813;
$sidebar-resizable-max-width: $unit * 80;
$branch-list-min-width: 52.5 * $unit;
$notifications-width: 650px;
$max-form-width: $unit * 100;
$padding: $unit * 3;
$vpadding: $unit * 3;
$hpadding: $unit * 4;

// breakpoints
$breakpoint-mobile: 800px;
$breakpoint-tablet: 960px;
$breakpoint-web: 1180px;

// depths
$depth-menu: 11000;
$depth-notifications: 10000;
$depth-title: 9000;
$depth-dialogs: 8000;
$depth-darken: 1000;

// shared
$default-border: 1px solid $black-a10;

$empty: "";

$pi: 3.1415;

$super-ease-out: cubic-bezier(0.23, 1, 0.32, 1);
