@import "../../components/shared/vars";
@import "../../components/shared/theme";

.project {
  @include content-background;

  margin-bottom: 2 * $unit;
  overflow: initial;
}

.inner {
  padding: 0;
}

.mobile {
  @include border-bottom;

  .projectNestedBranch {
    &::after {
      right: 0;
      left: 0;
    }
  }
}

.projectHeader {
  display: flex;
  align-items: center;
  position: relative;

  .link {
    display: flex;
    align-items: center;

    @include text-color;

    text-decoration: none;
  }

  &:hover {
    .link {
      text-decoration: underline;
    }
  }
}

.projectIcon {
  vertical-align: bottom;
  margin-right: $unit * 0.5;
}

.projectLockIcon {
  vertical-align: bottom;
}
