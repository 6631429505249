@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

.zoom {
  @include modal-shadow;
  @include content-background;

  display: flex;
  align-items: center;
  border-radius: $unit * 0.5;
  position: relative;

  &::before {
    border-radius: $unit * 0.5;
  }
}

@include dark-theme {
  .zoom {
    @include modal-outline;

    &::after {
      border-radius: $unit * 0.5;
    }
  }
}

.inner {
  padding: $unit * 0.5;
  display: flex;
  align-items: center;
  position: relative;
}

.progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: rgba($black, 0.1);
  border-radius: $unit * 0.5;
  overflow: hidden;
  border: 0;
  width: 80px;
  height: 2px;

  &::-webkit-progress-bar {
    background: rgba($black, 0.1);
  }

  &::-webkit-progress-value {
    background: $blurple-60;
  }

  &::-moz-progress-bar {
    background: $blurple-60;
  }
}

.minButton {
  margin-right: $unit * 0.5;
}

.maxButton {
  margin-left: $unit * 0.5;
}

@include dark-theme {
  .zoom {
    .progress {
      &::-webkit-progress-bar {
        background: rgba($white, 0.15);
      }

      &::-webkit-progress-value {
        background: $blurple-45;
      }

      &::-moz-progress-bar {
        background: $blurple-45;
      }
    }
  }
}

.input {
  @include outer-keyboard-focus;

  border-radius: 3px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  left: $unit * 4;
  outline: none;
  background: none;
  width: 80px;

  &::-moz-range-track {
    display: none;
  }

  &::-moz-range-progress {
    background: $blurple-60;
  }

  // comma separating these selectors doesn't appear to work
  &::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;

    @include content-background;

    border: 0;
    box-shadow:
      0 0 0 1px $black-a05,
      0 4px 8px 0 $black-a10,
      0 2px 4px 0 $black-a10;
    width: $unit * 2;
    height: $unit * 2;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &:active {
      transform: scale(1.1);
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;

    @include content-background;

    border: 0;
    box-shadow:
      0 0 0 1px $black-a05,
      0 4px 8px 0 $black-a10,
      0 2px 4px 0 $black-a10;
    width: $unit * 2;
    height: $unit * 2;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &:active {
      transform: scale(1.1);
    }
  }
}

@include dark-theme {
  .zoom {
    .input {
      &::-moz-range-progress {
        background: $blurple-45;
      }

      &::-moz-range-thumb {
        @include card-outline;
      }

      &::-webkit-slider-thumb {
        @include card-outline;
      }
    }
  }
}
