@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.loading {
  width: 100%;
  height: 100%;
}

.subHeading {
  margin-top: $unit * 2;
}

.redirectCopy {
  margin-top: $unit * 3;
  word-break: break-word;
}

.buttons {
  align-self: stretch;
  margin-top: $unit * 4;
}

.cancelButton {
  margin-top: $unit * 3;
}
