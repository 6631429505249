@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.item {
  position: relative;
}

.wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: $unit * 0.5;
  overflow: hidden;
  min-width: 0;
}

.heading {
  margin: 0;

  @include ellipsis;
}

.link,
.role {
  @include font-s;
}

.role {
  color: $gray-50;
}

.headingWrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  overflow: hidden;
  min-width: 0;
}

.buttonWrap {
  display: inherit;
  margin-left: $unit * 2;
}

.menu {
  box-shadow: none;
  position: absolute;
  min-width: 0;
  margin-top: $unit * 4;
  right: $unit * 2;
  z-index: 1;
}

.modalParagraph {
  margin-top: 0;
}

.card {
  text-decoration: none;
  color: var(--text-color);

  &:hover .heading {
    text-decoration: underline;
    color: var(--text-color);
  }
}

.cardContents {
  display: flex;
}

.underline {
  text-decoration: underline;
  color: var(--link-color);
}
