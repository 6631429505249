@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";
@import "../shared/theme";

.thumbnailMoving {
  z-index: $depth-notifications;

  .actions {
    display: flex;
  }
}

.container {
  @include border-bottom;

  padding-bottom: $unit * 2;
  margin-bottom: $unit * 3;

  @media (max-width: $breakpoint-mobile) {
    padding-bottom: $unit;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: $unit * 2;
}

.headerTitleContainer {
  position: relative;
  display: inline-flex;
}

.newCollectionButton {
  margin-right: 1px;
}

.fakePreview {
  &:hover {
    .emptyStateCollectionText {
      text-decoration: underline;
    }
  }
}

.emptyStateCollectionText {
  margin-top: $unit;
  font-weight: $font-medium;
}

.hidden {
  display: none !important;
}

.grid {
  display: grid;

  // ensure that the hover state is outside the grid
  margin-left: -$unit * 1.5;
  margin-right: -$unit * 1.5;
  margin-top: -$unit * 1.5;
}

.invisible {
  visibility: hidden;
}

.noCollections {
  padding-top: 0;
}
