@import "../shared/typography";
@import "../shared/vars";

@mixin display-default {
  margin: 0;
  padding: 0;
}

.display-xxxl {
  @include display-default;
  @include display-xxxl;
}

.display-xxl {
  @include display-default;
  @include display-xxl;
}

.display-xl {
  @include display-default;
  @include display-xl;
}

.display-l {
  @include display-default;
  @include display-l;
}

.display-m {
  @include display-default;
  @include display-m;
}

.display-s {
  @include display-default;
  @include display-s;
}

.display-xs {
  @include display-default;
  @include display-xs;
}
