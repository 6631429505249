@import "../shared/colors";
@import "../shared/typography";
@import "../shared/vars";

.badge {
  @include font-xs;
  @include secondary-text-color;

  display: flex;
  align-items: center;
  font-weight: $font-medium;
}

.symbol {
  width: $unit * 2;
  height: $unit * 2;
  margin-left: -3px;
}

.statusAdded {
  color: $green-60;
}

@include dark-theme {
  .statusAdded {
    color: $green-30;
  }
}

.statusRemoved {
  color: $red-60;
}

@include dark-theme {
  .statusRemoved {
    color: $red-30;
  }
}

.statusEdited {
  color: $blurple-60;
}

@include dark-theme {
  .statusEdited {
    color: $blurple-45;
  }
}

.active .badge {
  color: $white;

  svg {
    fill: $white;
  }
}
