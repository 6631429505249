@import "../../shared/vars";
@import "../../shared/mixins";
@import "../../shared/theme";

.commit {
  @include border;
  @include cell-hover-background;

  border-radius: $unit * 0.5;
  margin-bottom: $unit * 2;
}

.inputFocus {
  @include input-background;
  @include box-shadow-focus;
}

@include dark-theme {
  .inputFocus {
    @include box-shadow-focus;
  }
}
