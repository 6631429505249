@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.menu {
  @include border-bottom;

  padding: 0 $hpadding;
  flex-shrink: 0;
  height: $unit * 6;
}

.branchStatusBadges {
  margin-left: $unit;
}

.statusBadge {
  white-space: nowrap;
}
