@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";
@import "../shared/typography";
@import "../shared/vars";

.quickJump {
  @include font-s;
  @include content-background;
  @include secondary-text-color;
  @include border;
  @include outer-keyboard-focus;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  height: $unit * 4;
  border-radius: $unit;
  outline: none;
  box-shadow: none;
  user-select: none;
}

.text {
  margin-left: $unit;
  white-space: nowrap;
}

@include dark-theme {
  .quickJump {
    background: $black-a25;
  }
}

.icon {
  margin-left: $unit;
}
