@import "../../shared/theme";
@import "../../shared/typography";
@import "../../shared/vars";

.container {
  background: $white-a50;
  padding: $unit * 2;
  border-radius: 9px;
}

.smallStar {
  width: 16px;
  height: 16px;
  margin-bottom: $unit * 0.25;
}

.orangeStar {
  color: #fb8049;
}

.yellowStar {
  color: #f3b11b;
  width: 32px;
  height: 32px;
  margin: 0 $unit * 0.5;
}

.blueStar {
  color: #7ac4e5;
}

.copy {
  @include display-m;

  max-width: 216px;
  margin-top: $unit;
  text-align: center;
}

.viewProjects {
  margin-top: $unit * 0.5;
}

@include dark-theme {
  .container {
    background: $white-a10;
  }
}
