@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/colors";
@import "../shared/theme";

.container {
  @include content-background;

  width: 100%;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  flex-grow: 0;
}

.top,
.bottom {
  @include border-bottom;

  width: 100%;
}

.top {
  padding: $unit * 1.5 $unit * 2;
}

.bottom {
  @include secondary-content-background;

  padding: $unit * 1.5;
}

.icon {
  margin-left: -$unit * 0.5;
  margin-right: $unit * 0.5;
}

@include dark-theme {
  .top {
    border: none;
    margin-top: 1px;
    margin-bottom: 1px;
    box-shadow: 0 0 0 1px $white-a15;
  }
}

.hasChildren {
  .top {
    border: none;
    box-shadow: none;
  }
}
