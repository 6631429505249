@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";

.heading {
  @include font;

  font-size: 40px;
  font-weight: $font-medium;
  letter-spacing: -0.03em;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}

@include media(mobile) {
  .heading {
    @include display-xxl;

    font-weight: $font-medium;
  }
}
