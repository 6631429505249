@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.project {
  @include text-color;

  position: relative;
  text-decoration: none;

  &:hover {
    overflow: hidden;

    .name {
      @include text-color;
    }
  }
}

.list {
  .wrap {
    position: relative;
  }

  .box {
    padding: $unit * 1.5 $unit * 2;
  }

  .nameAndDescription {
    margin-left: $unit * 2;
    display: flex;
    flex-direction: column;
    max-height: 118px;
  }

  .date {
    order: 2;
    flex-shrink: 0;
  }

  .description {
    order: 1;

    &::before {
      opacity: 0;
      bottom: 20px;
    }
  }

  .meta {
    margin-left: $unit * 1.5;
  }

  .name {
    overflow: hidden;
    line-height: $unit * 2.5;
    margin-bottom: 0;

    span {
      flex-shrink: 0;
    }
  }
}

.mobile {
  .box {
    padding-left: 22px;
  }

  .nameAndDescription {
    margin-left: 18px;
  }

  .actions {
    margin-right: -$unit;
  }
}

.notDownloaded {
  .name,
  .description,
  .date,
  &:hover .name {
    @include secondary-text-color;
  }
}

.wrap {
  display: flex;
  flex-direction: row;
}

.box {
  max-width: 100%;
}

.sectionName {
  @include secondary-text-color;
}

.updatedAt {
  display: block;
  margin-right: 0.25em;
}

.nameAndDescription {
  margin: -2px 0;
  flex-grow: 1;
  max-height: $unit * 10;
  overflow: hidden;
  position: relative;
}

.description {
  @include font-s;
  @include text-color;

  margin: 0;
  word-wrap: break-word;
  white-space: inherit;
  flex-grow: 1;
  line-height: $unit * 2.5;

  &,
  p {
    cursor: pointer;
  }

  &::before {
    content: $empty;
    position: absolute;
    height: $unit * 2.5;
    width: $unit * 15;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 85%
    );
    bottom: 0;
    right: 0;
    opacity: 1;
  }
}

@include dark-theme {
  .description {
    &::before {
      background: linear-gradient(
        to right,
        rgba($gray-70, 0) 0%,
        rgba($gray-70, 1) 85%
      );
    }
  }
}

.meta {
  flex-grow: 0;
  justify-content: space-between;
}

.actions {
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 1;
  margin-left: $unit * 1.5;
}

.star {
  margin-right: $unit * 0.5;
}

.facepile {
  display: flex;
  align-items: center;
  margin-left: $unit;
}

.spinner {
  padding: 0;
  margin-right: 6px;
  margin-bottom: -$unit * 0.5;
}

.spinnerWrap,
.progressWrap {
  @include font-s;
  @include secondary-text-color;

  display: flex;
  align-items: center;
  overflow: hidden;
}

.progressMessage {
  margin-right: 6px;

  @include ellipsis;
}

.syncButton {
  width: $unit * 14;
}

.name {
  display: flex;
  margin: 0;
  margin-bottom: $unit * 0.5;
  justify-content: space-between;
  white-space: pre-wrap;
  line-height: $unit * 3;
}

.card {
  width: 100%;
  min-height: 192px;
  display: flex;

  .wrap {
    flex-direction: column;
    height: 100%;
  }

  .nameAndDescription {
    max-height: 110px;
    margin: $unit * 0.5 0 $unit;
    flex-grow: 1;
  }

  .description {
    @include content-background;

    margin-top: -1px;
  }

  .date {
    margin-bottom: $unit * 0.5;
  }

  .meta {
    padding: 0 $unit * 2 $unit * 2;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .box {
    padding-top: 22px;
    flex-grow: 1;
  }

  .sectionName {
    margin-bottom: $unit * 0.5;
  }

  .facepile {
    margin-left: 0;
  }
}

.date {
  @include font-s;
  @include secondary-text-color;

  overflow: hidden;
  height: 18px;

  span {
    @include ellipsis;
  }

  .dot {
    margin: 0 $unit * 0.5;
  }

  .webLinkText {
    @include secondary-text-color;
  }

  .webLink {
    @include outer-keyboard-focus;

    display: flex;
    align-items: center;
    border-radius: 3px;
    white-space: nowrap;
    margin-right: -$unit * 0.5;

    &:hover {
      .webLinkText {
        color: darken($gray-50, 10%);
        text-decoration: underline;
      }
    }

    @include dark-theme {
      &:hover {
        .webLinkText {
          color: darken($gray-10, 10%);
        }
      }
    }
  }
}
