@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.date {
  margin-right: $unit * 2;
}

.amount {
  text-align: right;
  display: inline-block;
  min-width: 48px;
}

.amount,
.download {
  margin-left: $unit * 2;
}

.status,
.open {
  margin: 0;
  cursor: default;
}

.collected {
  color: $green-50;
  box-shadow: 0 0 0 1px $green-50 inset;
}

.failed,
.past_due {
  color: $red-50;
  box-shadow: 0 0 0 1px $red-50 inset;
}

@media screen and (max-width: $breakpoint-mobile) {
  .line {
    flex-flow: wrap;
    flex-direction: row;
  }

  .meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .description {
    display: block;
    width: 100%;
    margin: $unit 0;
  }

  .amount,
  .download {
    margin: 0;
  }

  .status {
    order: -1;
  }
}
