@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";

.tab {
  @include font-xs;
  @include outer-keyboard-focus;

  border-radius: 3px;
  display: inline-block;
  position: relative;
  color: $gray-60;
  font-weight: $font-medium;
  text-decoration: none;
  outline: none;

  &::after {
    /*
      We display this element in a transparent state rather than on :hover,
      :selected, or :active in order to avoid this double-tapping issue on
      mobile: https://css-tricks.com/annoying-mobile-double-tap-link-issue/
    */
    content: $empty;
    height: 2px;
    background: transparent;
    position: absolute;
    bottom: 0;
    left: $unit * 1.5;
    right: $unit * 1.5;
    cursor: pointer;
  }

  &.selected,
  &:hover,
  &:active {
    &::after {
      background: $gray-20;
    }
  }

  &:hover {
    color: $gray-60;
  }

  &:active {
    color: $gray-70;

    &::after {
      background: $gray-30;
    }
  }

  &.selected {
    @include link-color;

    &::after {
      background: $blurple-60;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $unit * 1.5 $unit * 1.5;
  height: 48px;
}

@include dark-theme {
  .tab {
    color: $gray-50;

    &.selected,
    &:hover,
    &:active {
      &::after {
        background: $white-a15;
      }
    }

    &:hover {
      color: $gray-40;
    }

    &:active {
      color: $white;

      &::after {
        background: $white-a25;
      }
    }

    &.selected {
      @include link-color;

      &::after {
        background: $blurple-45;
      }
    }
  }
}
