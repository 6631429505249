@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.facepile {
  display: flex;
  position: relative;
}

.bubble {
  @include font-s;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-medium;
  box-shadow: 0 0 0 2px $white;
  background: $gray-60;
  padding: 0 6px;
  color: $white;
  z-index: 1;
}

.bubbleBackgroundBlurple {
  box-shadow: 0 0 0 2px $blurple-10;
}

@include dark-theme {
  .bubble {
    box-shadow: 0 0 0 2px $gray-70;
  }

  .bubbleBackgroundBlurple {
    box-shadow: 0 0 0 2px $blurple-80;
  }
}

.avatar {
  position: relative;
  box-shadow: 0 0 0 2px $white;
}

.avatarBackgroundBlurple {
  box-shadow: 0 0 0 2px $blurple-10;
}

.avatarBackgroundTransparent {
  box-shadow: 0 0 0 2px $gray-10;
}

.firstUserHasColor {
  margin-left: -4px;
}

@include dark-theme {
  .avatar {
    box-shadow: 0 0 0 2px $gray-70;
  }

  .avatarBackgroundBlurple {
    box-shadow: 0 0 0 2px $blurple-80;
  }

  .avatarBackgroundTransparent {
    box-shadow: 0 0 0 2px $gray-80;
  }
}

.teamAvatar {
  position: relative;
}

.popoverTarget {
  display: flex;
}

.indicator {
  border: 2px solid;
  padding: 2px;
}

.green {
  border-color: $green-50;
}

.orange {
  border-color: $orange-50;
}
