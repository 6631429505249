@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.item {
  @include border-bottom;

  strong {
    font-weight: $font-medium;
  }

  p {
    margin-bottom: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    &:first-child {
      .box {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    &:last-child {
      .box {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

.box {
  padding: $unit * 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secondary {
  @include font-s;
  @include secondary-text-color;

  text-align: right;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.responsiveItem {
  .box {
    flex-wrap: wrap;
  }
}

.inputItem,
.switchItem {
  .box {
    display: block;
  }
}

.linkItem {
  @include text-color;

  text-decoration: none;

  .box {
    @include quick-transition(background);
  }

  &:hover {
    cursor: pointer;

    @include text-color;

    .box {
      @include secondary-content-background;
    }
  }
}

@include dark-theme {
  .linkItem {
    @media screen and (min-width: $breakpoint-mobile) {
      .box {
        margin: 1px;
      }
    }
  }
}

.itemLabel {
  font-weight: $font-medium;
  flex-basis: 100%;
  align-self: flex-start;
}

.itemContent {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: $breakpoint-mobile) {
  .inputItem {
    .box {
      padding: $unit * 1.5 $unit * 2;
    }
  }

  .itemContent {
    padding-left: $unit * 2;
  }

  .responsiveItem {
    .box {
      flex-wrap: nowrap;
    }

    .itemLabel,
    .itemContent {
      flex-basis: 50%;
    }

    .wide {
      &.itemLabel {
        flex-basis: 20%;
      }

      &.itemContent {
        flex-basis: 80%;
      }
    }
  }
}
