@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.sidebarHeading {
  @include dynamic-padding(top right);

  padding-left: $unit;
  margin: 0;
  padding-bottom: $unit;
  flex-shrink: 0;
  display: flex;
}

.dynamicPadding {
  @include dynamic-padding(top right left);
}

.plain {
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    position: relative;
    margin-left: $unit;
    flex-shrink: 1;
  }

  a {
    @include font-xs;

    font-weight: $font-regular;
    line-height: inherit;
    margin-left: $unit;
    flex-shrink: 1;
  }
}

.linked {
  display: flex;

  @include text-color;

  text-decoration: none;
  align-items: center;

  &:hover {
    @include text-color;

    text-decoration: underline;
  }
}
