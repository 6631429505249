@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.icon {
  width: $unit * 4;
  height: $unit * 4;
  border-radius: 16px;
  margin-right: $unit;
}

.project {
  background-color: rgba($purple, 0.2);
  color: $purple;
}

.branch {
  background-color: rgba($green-50, 0.2);
  color: $green-50;
}

.commit {
  background-color: rgba($pink-50, 0.2);
  color: $pink-50;
}

@include dark-theme {
  .project {
    background-color: $purple;
    color: $white;
  }

  .branch {
    background-color: $green-40;
    color: $white;
  }

  .commit {
    background-color: $pink-40;
    color: $white;
  }
}
