@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.formNotice {
  @include text-color;
  @include secondary-content-background;

  padding: $unit * 2;
  border-radius: $unit * 0.5;
}

.alignContent {
  margin-left: -$unit * 2;
  margin-right: -$unit * 2;
}

.iconWrapper {
  background-color: $orange-50;
  border-radius: 50%;
  margin-right: $unit * 2;
  margin-top: $unit * 0.5;
  height: $unit * 4;
  flex-basis: $unit * 4;
}

.body {
  @include font-s;

  margin: 0;
}

.link {
  @include display-xs;

  margin-top: $unit;
}

.notificationCard.error {
  .link {
    padding: 0;
  }
}

.allgood {
  background: $green-50;
}

.tint {
  background: $blurple-60;
}

.warning {
  background: $orange-40;
}

.purple {
  background: $purple;
}

@include light-theme {
  .formNotice.error {
    background: #ffe0e1;
    color: $gray-70;

    .iconWrapper {
      background-color: #e20005;
    }

    // Note: these colors may or may not be a part of our global color system.
    // Overriding them here until that's determined, and will update with
    // color variables if they are
    .link {
      color: #800004 !important;

      &:hover,
      &:active {
        color: #800004 !important;
      }
    }
  }
}

@include dark-theme {
  .formNotice.error {
    background: #4d0003;
    color: $white;

    .iconWrapper {
      background-color: #ea2428;
    }

    .link {
      color: #fbc1c2 !important;

      &:hover,
      &:active {
        color: #fbc1c2 !important;
      }
    }
  }

  .allgood {
    background: $green-30;
  }

  .warning {
    background: $orange-30;
  }
}
