@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/vars";

.name {
  @include font-s;
  @include secondary-text-color;

  font-weight: $font-regular;
  overflow: hidden;
  flex-shrink: 0;
}

.name,
.inner {
  display: flex;
  align-items: center;
}

.inline {
  display: inline-flex;
}
