@import "../shared/animations";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/vars";

$height: $unit * 4;

.banner,
.notice {
  @include font-s;

  display: flex;
  justify-content: space-between;
  background: $blurple-60;
  width: 100%;
  height: $height;
  line-height: $height;
  padding: 0 $unit * 2;
  color: $white;
  align-items: center;
}

.error {
  text-align: center;
  background: $red-50;
}

.success {
  text-align: center;
  background: $green-50;
}

.message {
  flex: 1;
}

.actions {
  flex-grow: 0;
  flex-shrink: 0;

  button {
    margin-left: $unit;
  }
}

.close {
  opacity: 0.9;
  color: $white;
  text-decoration: none;
  font-size: 22px;
  margin-left: $unit;
  margin-right: -$unit;

  &:hover {
    opacity: 1;
  }
}

.bannerAppear .banner,
.bannerEnter .banner {
  @include scale-in-down;

  animation-duration: 0.6s;
}

.bannerLeave .banner {
  @include scale-out-up;

  animation-duration: 0.6s;
}
