@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.headingContainer {
  position: relative;
}

.heading {
  padding: $unit * 2;
}

.divider {
  border-bottom: 1px solid var(--border-color);
  bottom: -1px;
  left: $unit * 2;
  position: absolute;
  right: $unit * 2;
}

.filters {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.filters > button {
  margin: 0 $unit * 0.5;
}

.card,
.card:hover {
  color: inherit;
  text-decoration: none;
}

.time {
  @include secondary-text-color;
}

.arrow {
  margin-left: $unit * 1.5;
}

.retry,
.redeliverSuccessIcon {
  margin-left: $unit * 2;
}

.identifier {
  @include font-s;
  @include font-mono;

  padding-left: $unit;
}

.redeliverSuccess {
  display: flex;

  .enter svg {
    transition:
      opacity 300ms $super-ease-out,
      transform 300ms $super-ease-out;
    opacity: 0;
    transform: scale(1.5);
  }

  .enterActive svg {
    opacity: 1;
    transform: scale(1);
  }
}

.redeliverSuccessIcon svg {
  fill: $green-50;
}

@include dark-theme {
  .redeliverSuccessIcon svg {
    fill: $green-40;
  }
}

.controls {
  flex: 0;
  justify-content: center;
  max-width: $max-form-width;
  padding: ($unit * 2) ($unit * 4);
}
