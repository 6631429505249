@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";

.dot {
  height: $unit * 2;
  width: $unit * 2;
  border-radius: $unit;
  margin-right: $unit * 0.5;
}

.icon {
  margin-left: $unit * -0.5;
  margin-top: $unit * -0.5;
}

.defaultStatus {
  @include menu-item-icon-color(color);

  &.selected {
    color: $white;
    background: none;

    [data-blurred] & {
      @include menu-item-icon-color(color);
    }
  }
}

[data-blurred] .allgood,
.allgood {
  color: $white;
  background: $green-50;
}

[data-blurred] .purple,
.purple {
  color: $white;
  background: $purple;
}

[data-blurred] .warning,
.warning {
  color: $white;
  background: $orange-50;
}

[data-blurred] .error,
.error {
  color: $white;
  background: $red-50;
}

.selected {
  color: $selected;
  background: $white;
}
