@import "shared/mixins";
@import "shared/vars";

@mixin large-font {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.01em;
}

.mainContentSheet {
  margin: 0 $unit;
  min-width: 0;
}

.centeredText {
  text-align: center;
}

.subHeading {
  margin-top: $unit;
}

.largeCopy {
  @include large-font;
}

.mediumCopy {
  font-size: 16px;
  line-height: 24px;
}

.extraInfo {
  margin-top: $unit * 2;
}

.link {
  text-decoration: none;
}

.form {
  margin-top: $unit * 4;
}

.inputLabel {
  font-weight: $font-regular;
}

.checkboxLabel {
  margin-left: $unit;
  font-weight: $font-regular;
}

.submit {
  margin-top: $unit * 4;
}

@include media(desktop) {
  .inputLabel {
    @include large-font;
  }

  .inputHelper {
    @include large-font;
  }

  .input {
    font-size: 20px;
    line-height: 24px;
    padding: $unit;
    border-radius: 10px; // 40px height / 4
  }

  .select {
    height: 40px;
    background-position: top 14px right 8px;
  }

  .checkboxLabel {
    @include large-font;
  }

  .checkbox {
    width: $unit * 2;
    height: $unit * 2;
  }

  .button {
    @include large-font;

    font-weight: $font-regular;
    display: inline-flex;
    align-items: center;
    min-height: 40px;
    border-radius: 10px; // 40px height / 4
  }
}

@include media(mobile) {
  .largeCopy {
    @include font-l;
  }

  .mediumCopy {
    @include font-m;
  }

  .submit {
    margin-top: $unit * 3;
  }
}
