@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.sheet {
  width: 100%;
  padding: $unit * 7.5 $unit * 10;
}

.vertical {
  max-width: 544px;
  border-top-left-radius: $unit * 5;
  border-top-right-radius: $unit * 5;
}

.horizontal {
  max-width: none;
  border-top-right-radius: $unit * 5;
  border-bottom-right-radius: $unit * 5;
}

.blurple {
  background: $blurple-10;
}

.kelp {
  background: $kelp-10;
}

@include media(mobile) {
  .sheet {
    padding: $unit * 3;
  }
}

@include dark-theme {
  .blurple {
    background: $blurple-90;
  }

  .kelp {
    background: $kelp-90;
  }
}
