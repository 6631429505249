@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

$headings: h1, h2, h3, h4, h5, h6;

.emoji {
  letter-spacing: 0.15rem;
}

a .markdown,
a .markdown img {
  cursor: pointer;
}

.markdown {
  @include font-m;
  @include text-color;
  @include code-block;

  -webkit-user-select: text;
  overflow-wrap: anywhere;
  word-break: break-word; /* non-standard, webkit only. https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html#//apple_ref/doc/uid/TP30001266-word-break */
  hyphens: none;
  cursor: text;

  a {
    @include link;

    cursor: pointer;
    text-decoration: none;
  }

  p,
  ul,
  ol,
  pre {
    margin: $unit 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  em {
    font-style: italic;
  }

  s {
    text-decoration: line-through;
  }

  ul,
  ol {
    padding-left: 20px;

    li p {
      margin: 0 0 0 4px;
    }

    ul,
    ol {
      margin-top: 0;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }

    ul {
      li {
        list-style-type: circle;
      }

      ul {
        li {
          list-style-type: square;
        }
      }
    }
  }

  :global {
    ul.contains-task-list {
      padding: 0;

      li.task-list-item {
        list-style-type: none;
      }

      ul {
        padding-left: 20px;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  hr {
    border-width: 0;

    @include border-bottom;

    margin: $unit * 1.5 0;
  }

  code {
    font-size: 13px;
  }

  pre {
    @include secondary-content-background;

    display: block;
    padding: $unit * 1.5 $unit * 2;
    overflow-x: auto;
    border-radius: $unit * 0.5;

    code {
      padding: 0;
    }
  }

  #{$headings} {
    margin: 0 0 $unit 0;
  }

  h1 {
    @include display-xl;
  }

  h1:not(:first-child) {
    margin-top: $unit * 2;
  }

  h2 {
    @include display-l;
  }

  h3 {
    @include display-m;
  }

  h4,
  h5,
  h6 {
    @include display-s;
  }

  img {
    border-radius: $unit * 0.5;
    max-width: 100%;
    margin: $unit 0;
    cursor: default;
  }

  blockquote {
    @include secondary-text-color;

    margin: $unit 0;
    position: relative;
    padding-left: $unit * 1.25;

    &:first-child {
      margin: 0;
    }

    &::after {
      @include secondary-content-background;

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      border-radius: 1px;
      content: $empty;
    }
  }
}

.font-ml {
  @include font-ml;
}

.inlineMarkdown {
  @include ellipsis;

  width: 100%;

  br {
    display: none;
  }

  pre {
    white-space: nowrap;
    display: inline;
  }

  img {
    display: none;
  }

  mark {
    background: $highlight;
  }

  a {
    @include outer-keyboard-focus;

    border-radius: 3px;
  }
}

@include dark-theme {
  .markdown {
    @include secondary-text-color;
    @include code-block-dark;
  }

  .forceLightText {
    color: $white;
  }
}
