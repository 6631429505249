@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.wrapper {
  @include secondary-content-background;

  min-height: 0;
  min-width: 0;
}

.scrollableWrapper {
  @include scrollable;
}

.projectType {
  margin-left: $unit * -0.5;
}

.scrollableContent {
  @include scrollable;
  @include dynamic-padding;
}

.mainColumn {
  min-width: 0;
}

.sidebar {
  width: 300px;
  margin-left: $unit * 2;
}

.star {
  margin-left: $unit * 0.5;
}
