@import "../shared/vars";
@import "../shared/theme";

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: $unit * 2.5;
  padding-bottom: $unit * 2.5;
  max-width: 440px;

  &.loggedOut {
    @include secondary-content-background;
    @include inset-border;

    flex-direction: row;
    align-items: flex-start;
    border-radius: $unit * 0.5;
    padding: $unit * 2.5;

    .invitationTextContainer {
      padding: 0 $unit * 1.5;
    }
  }
}

.invitationLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $unit * 1.5;
}

.plusIcon {
  margin: 0 $unit * 3;
}

.invitationTextContainer {
  text-align: left;
  padding-top: $unit * 2.5;
}
