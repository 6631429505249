@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";

.radio {
  margin: 0 $unit 0 0;
}

.wrapper {
  label {
    font-weight: normal;
  }
}
