@import "../shared/colors";
@import "../shared/vars";
@import "../Input/style";

.inputs {
  // recurly hosted fields cannot accept this css attribute so we reset the
  // entire form so that all the fields match
  -webkit-font-smoothing: initial;
  padding: $unit * 2 $unit * 4 $unit * 2;
}

.scroll {
  @media screen and (min-width: $breakpoint-mobile) {
    max-height: calc(90vh - 100px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.note {
  margin-bottom: 0;
}

.nameInputs {
  margin-bottom: $unit * -2;
}

.splitInputs {
  display: flex;

  .firstNameInput,
  .lastNameInput,
  .yearInput,
  .monthInput {
    width: 50%;
  }

  .countrySelect,
  .cityInput {
    width: 65%;
  }

  .stateSelect,
  .zipInput {
    width: 35%;
  }

  .monthInput,
  .countrySelect,
  .firstNameInput,
  .cityInput {
    margin-right: $unit * 0.5;
  }

  .yearInput {
    margin-right: $unit;
  }

  .cvvInput {
    width: 60px;
  }

  .yearInput,
  .lastNameInput,
  .stateSelect,
  .zipInput {
    margin-left: $unit * 0.5;
  }
}

.input {
  composes: text;
  padding: 0;
}

.withError {
  composes: inputError;
}

:global {
  .recurly-hosted-field {
    height: $unit * 4;
  }

  .recurly-hosted-field-focus {
    @include box-shadow-focus;

    outline: 0;
    border-radius: 3px;
  }
}

.threeDSContainer {
  margin: $unit * 4;
  height: 400px;
  position: relative;
}

.spinner,
.threeDSchallenge {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.spinner {
  z-index: -1;
  align-items: center;
}
