@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";

.form {
  padding-bottom: $unit * 2;
}

.projectTabToggle {
  margin-bottom: $unit * 2;
}

.privateLabel {
  display: flex;
  font-weight: $font-medium;
  align-items: center;
  margin-top: -2px;

  &.disabled {
    opacity: 0.5;
  }
}

.privacyInput {
  margin-bottom: 0;
}

.note {
  @include font-s;

  color: $gray-50;
}

.errorNotification {
  margin-bottom: $unit * 1.5;
}

.colorPicker {
  width: 100%;
}

.moreSettings {
  display: flex;
  align-items: center;
  margin-top: $unit * 2;
}

.infoIcon {
  margin-left: -$unit * 0.5;
  margin-right: 2px;
}
