@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.wrapper {
  @include secondary-content-background;

  flex-grow: 1;
  position: relative;
  overflow-x: auto;

  @media (min-width: $breakpoint-mobile) {
    max-height: 100%;
  }
}

.content {
  @include secondary-content-background;

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @media (min-width: $branch-list-min-width) {
    min-width: $branch-list-min-width;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.action {
  margin-left: $unit;
}
