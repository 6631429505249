@import "../shared/vars";
@import "../shared/mixins";

.mainContent {
  max-width: 100%;
}

.wrap {
  flex-grow: 1;
  position: relative;
}

@media screen and (min-width: $breakpoint-mobile) {
  .dialog {
    width: $invite-dialog-width;
  }
}
