@import "../../shared/vars";
@import "../../shared/colors";
@import "../../shared/mixins";
@import "../../shared/theme";

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.opening {
  opacity: 0.75;
}

.disabled {
  .name {
    opacity: 0.5;
  }
}

.name {
  @include ellipsis;
}

.styleLabel {
  font-weight: $font-medium;
}

.icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: -$unit * 0.5;
}

.statusIcon {
  margin-left: 6px;
}
