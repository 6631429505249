@import "../shared/theme";
@import "../shared/vars";

.mainContent {
  max-width: 100%;
}

.wrap {
  flex-grow: 1;
  position: relative;
}

.inviteModalHeader {
  border-bottom: none;
}

.inviteModalHeaderText {
  font-size: $unit * 4;
  font-weight: $font-bold;
  margin-top: $unit * 3.25;
  line-height: normal;
}
