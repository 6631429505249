@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.card,
.card:hover {
  color: inherit;
  text-decoration: none;

  &:first-child > div {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child > div {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.icon {
  align-self: flex-start;
}

.check {
  padding-top: $unit * 0.5;
}

.check * {
  fill: $green-50;
  height: $unit * 3;
  width: $unit * 3;
}

.content {
  padding-left: $unit;
  word-break: break-all;
  min-width: 0;
}

.note {
  font-weight: $font-regular;
}

.statusInfo {
  padding-left: $unit;
}

.status {
  @include font-xs;

  display: flex;
  font-weight: $font-medium;
  margin-left: $unit * 0.25;
  white-space: nowrap;
}

.active {
  color: $green-50;
}

.error {
  color: $red-50;
  margin-right: $unit * 2;
}

.inactive {
  @include secondary-text-color;
}

.arrow {
  margin-left: $unit * 2;
}

.mobile {
  .arrow {
    margin-left: $unit;
  }

  .statusInfo {
    margin-top: $unit;
  }
}

.shell {
  min-width: 0;
}

.url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
