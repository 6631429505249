@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";

.container {
  @include content-background;

  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.projectCollections {
  width: 100%;
  flex: 1;
}

.filteredItemsDisclaimer {
  margin-top: $unit * 8;
  margin-bottom: $unit * 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.filteredNumberOfItems {
  @include font-s;
  @include secondary-text-color;

  margin: 0;
}

.filteredHeading {
  padding: ($unit * 2) 0;
}

.mobileMainContent {
  padding: 0;
}

.gridRow {
  padding-left: $unit * 2.5;
  padding-right: $unit;
}

.gridItem {
  padding-right: $unit * 1.5;
  padding-bottom: $unit * 1.5;
}

.virtualizedList {
  padding-top: $unit * 1.5;
}
