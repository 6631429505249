@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";

.banner {
  @include dynamic-padding(right left);

  padding-top: $unit * 1.5;
  padding-bottom: $unit * 1.5;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  background: $white;
  width: 100%;
  min-height: $unit * 4;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.link {
  text-decoration: none;
}

.expired {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: $cherry-10;
}

.expiring {
  background: $yolk-10;
}

@include dark-theme {
  .banner {
    background: $blurple-60;
  }

  .expired {
    background: $cherry-60;
  }

  .expiring {
    background: $yolk-90;
  }

  .link {
    text-decoration: underline;
    color: $white;
  }
}

.message {
  display: flex;
  align-items: flex-start;
  margin-right: $unit;
  width: 100%;

  @media (max-width: $breakpoint-mobile) {
    margin: 0 0 $unit 0;
  }

  @media (max-width: $breakpoint-tablet) {
    align-items: flex-start;
  }
}

.messageText {
  span {
    display: inline-block;
  }

  strong {
    line-height: $unit * 3;
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.icon {
  margin-right: $unit;
}
