@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.activateSSOImage {
  @include asset("activate-sso");

  background-size: contain;
  height: 15.5vw; // image height/width ratio
  margin: 18px 0 $unit * 4;
  max-height: 124px;
  width: 100%;
}

.checkmark {
  margin-left: $unit * -0.25;
}

.component {
  margin: $unit * 8 auto;
  max-width: $unit * 100;
  padding: 0 $unit * 2;
}

.heading {
  text-align: center;
}

.icon {
  background-color: $green-50;
  border-radius: 50%;
  height: $unit * 8;
  margin-bottom: $unit * 2;
  width: $unit * 8;
}

.note {
  max-width: $unit * 63;
  text-align: center;
}
