@import "../shared/mixins";

.star {
  @include quick-transition(opacity);

  opacity: 0.75;

  &.starred {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
}
