@import "../../shared/mixins";
@import "../../shared/vars";
@import "../../shared/colors";
@import "../../shared/typography";
@import "../../shared/theme";

.top {
  @include border-bottom;

  padding: $unit * 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;

  @media (min-width: $breakpoint-mobile) {
    padding: $unit * 2 $unit * 4;
  }
}

.left {
  display: flex;
  align-items: center;
  overflow: hidden;

  // provides buffer to prevent box-shadow on multiselect checkbox being clipped
  padding-left: 10px;
  margin-left: -10px;
}

.right {
  justify-content: flex-end;
  padding-left: 0;
}

.selectButton {
  margin-right: 13px;
  margin-left: 1px;
}

.icon {
  margin-right: $unit * 0.5;
  margin-left: -10px;
}

.fileInfo {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fileNameWrap {
  display: flex;
  align-items: center;
  margin: -3px 0;
}

.fileLink {
  text-decoration: none;

  .fileNameWrap {
    @include text-color;
  }

  &:hover {
    .fileNameWrap {
      text-decoration: underline;
    }
  }
}

.projectName {
  margin-left: $unit * 0.5;
  max-height: 18px;
}

.fileName {
  font-weight: $font-medium;
  line-height: $unit * 3;
  user-select: text;
}

.more {
  margin-left: $unit * 0.5;
  opacity: 0.75;
}

.lastEdited {
  @include ellipsis;
  @include font-s;
  @include secondary-text-color;

  a {
    @include secondary-text-color;
    @include outer-keyboard-focus;

    border-radius: 3px;
    text-decoration: underline;

    &:hover {
      color: darken($gray-60, 10%);
    }
  }

  span {
    display: inline;
  }
}

@include dark-theme {
  .lastEdited {
    a:hover {
      color: lighten($gray-30, 10%);
    }
  }
}
