@import "../../shared/mixins";
@import "../../shared/vars";

.innerMenu {
  margin: 0 $unit * 2 $unit * 3;
}

.reviewsBadge {
  cursor: inherit;
  margin-left: $unit * 0.5;
}

.currentProjectSection {
  margin-top: $unit * 1.5;
}

.projectMenuItem {
  margin-top: $unit * 1.5;

  &.firstProject {
    margin-top: 0;
  }
}

.favoriteProjectsSection {
  margin-top: $unit * 2.5;
}

.favoriteProjectsHeading {
  @include secondary-text-color;

  padding: 0 $unit * 1.5;
}

.favoriteProjectsOnboarding {
  margin-top: $unit;
}

.onboarding {
  background-color: $blurple-60;
  border-radius: $unit * 0.5;
}

.onboardingContainerArrow {
  transform: rotate(45deg);
  background-color: $blurple-60;
  width: $unit * 2;
  height: $unit * 2;
  position: absolute;
  left: -7px;
  top: 26px;
  z-index: -1;
}

.onboardingContent {
  display: flex;
  flex-direction: column;
  color: $white;
  padding: $unit $unit * 1.5;
}

.onboardingMessage {
  @include font-s;

  margin-bottom: $unit;
}

.onboardingActionSection {
  display: flex;
}

.upgradeCTA {
  @include button-color-variant(
    $bg: $blurple-20,
    $bg-hover: $blurple-30,
    $bg-active: $blurple-40
  );
  @include font-xs;

  text-transform: unset;
  color: $blurple-60 !important;
  margin-top: $unit * 3;
  padding: ($unit * 0.25) $unit;

  svg {
    fill: $blurple-60;
  }
}

@include dark-theme {
  .upgradeCTA {
    @include button-color-variant(
      $bg: $blurple-90,
      $bg-hover: darken($blurple-90, 5),
      $bg-active: darken($blurple-90, 10)
    );

    color: $blurple-40 !important;

    svg {
      fill: $blurple-40;
    }
  }
}
