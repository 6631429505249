@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.section {
  padding: $unit * 3 $unit * 4;
  padding-bottom: 0;
  max-width: $max-form-width;
  margin-right: auto;
  width: 100%;

  &:last-child {
    padding-bottom: $unit * 3;
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $unit * 3;
}

.headingLite {
  margin-bottom: $unit * 2;
}

.card {
  // follows the wrapper styling from abstract-core but leaves out box, header
  // and footer styling
  @include content-background;

  border-radius: 9px;
  margin-bottom: $unit * 2;
  box-shadow:
    0 1px 2px 0 $black-a10,
    0 0 1px 0 $black-a25;
  min-height: $unit * 7;
  display: flex;
  flex-direction: column;
}

@include dark-theme {
  .card {
    box-shadow: 0 0 0 1px $white-a15;

    @media screen and (min-width: $breakpoint-mobile) {
      @include card-outline;
    }
  }
}

.description {
  @include font-s;
  @include secondary-text-color;

  margin-bottom: $unit * 3;
}

@media screen and (max-width: $breakpoint-mobile) {
  .section {
    padding: 0;
    width: 100vw; // Explicit width for MS devices
    max-width: 100%; // For Safari
  }

  .card {
    border-radius: 0;
    margin-top: $unit * 2;
    margin-bottom: 0;
  }

  .heading,
  .description {
    margin: 0;
    padding: $unit * 2;
    padding-bottom: 0;
  }
}
