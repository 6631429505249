@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(240px + 2%), 1fr));
  grid-gap: $unit * 2;
}

.list {
  @include content-background;

  grid-template-columns: 100%;
  grid-gap: 0;
  border-radius: 6px;
  overflow: hidden;
  box-shadow:
    0 1px 2px 0 $black-a10,
    0 0 1px 0 $black-a25;

  &:not(.flat):not(.mobile) {
    > a:first-child {
      border-top-right-radius: $unit;
      border-top-left-radius: $unit;
    }

    > a:last-child {
      border-bottom-right-radius: $unit;
      border-bottom-left-radius: $unit;
    }
  }
}

.mobile {
  border-radius: 0;
}

.flat {
  border-radius: 0;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  background: none;
}

/*
 IE11 doesn't support grid auto-placement. Using flex to provide a usable layout for IE11.
 All items are fixed to 225px and won't grow or shrink based on available space like grid will.
 */
// stylelint-disable-next-line selector-type-no-unknown
_:-ms-fullscreen,
:root .wrapper {
  display: flex;
  flex-wrap: wrap;
}

// stylelint-disable-next-line selector-type-no-unknown
_:-ms-fullscreen,
:root .wrapper > * {
  width: 225px;
  margin-right: $unit * 2;
  margin-bottom: $unit * 2;
}

@include dark-theme {
  .list {
    box-shadow: 0 0 0 1px $white-a15;

    @media screen and (min-width: $breakpoint-mobile) {
      @include card-outline;
    }
  }
}
