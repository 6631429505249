@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.star {
  opacity: 0.75;

  &:hover {
    opacity: 1 !important;
  }
}

.collapsed,
.empty {
  @include inset-border-bottom;

  @media screen and (min-width: $breakpoint-mobile) {
    border: none;
    box-shadow: none;
  }
}

.collapsed {
  .disclosure {
    transform: rotate(-90deg);
  }

  @media screen and (min-width: $breakpoint-mobile) {
    .star,
    .menuButton {
      opacity: 0;
    }
  }
}

.name {
  @include font-ml;

  font-weight: $font-medium;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 3px;
  }
}

.count {
  @include font-m;
  @include secondary-text-color;

  margin-left: 6px;
  font-weight: $font-regular;
}

.actions {
  display: flex;
  align-items: center;
  margin-left: $unit;
}

.star,
.menuButton {
  @include quick-transition(opacity);
}

.menuButton {
  margin-left: $unit * 0.5;
}

.title {
  background: inherit;
  display: flex;
  align-items: center;
  padding: $unit * 1.5;

  &:hover {
    .menuButton {
      opacity: 1;
    }

    .star {
      opacity: 0.75;
    }
  }

  @media screen and (min-width: $breakpoint-mobile) {
    padding: $unit 0;
    margin-left: -$unit;
  }
}

.starred {
  .star {
    opacity: 1;
  }

  .title:hover .star {
    opacity: 1;
  }
}
