@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.page {
  flex-grow: 1;
  min-height: 0;
  position: relative;
  overflow-y: hidden;
  display: flex;
}

.pageInner {
  width: 100%;
  min-height: 100%;

  @media screen and (min-width: $breakpoint-mobile) {
    height: 100%;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    position: absolute;
  }
}
