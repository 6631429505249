@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.wrapper {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
}

.textCenter {
  text-align: center;
}

.invitePromptText {
  margin-bottom: $unit * 3;
}

.downloadPromptText {
  margin-bottom: $unit * 2;
}

.downloadRetry {
  margin-bottom: $unit * 5;
}

.appVersionText {
  font-size: $unit * 1.5;
  margin-top: $unit * 2;
  color: $gray-50;
}

.nonMacImage,
.startedAppDownloadImage,
.startAppDownloadImage {
  background-position: center;
  background-size: contain;
}

.nonMacImage {
  @include asset(nonmac-platform);

  width: 90vw;
  height: 30vh;
  max-width: 325px;
  max-height: 204px;
  margin-bottom: $unit * 4.5;
}

.appDownload {
  @include asset(download-illustration);

  background-position: center;
  background-size: 900px 400px;
}

.startedAppDownloadImage {
  @include asset(starting-download);

  width: 281px;
  height: 88px;
  margin-top: $unit * 7.5;
  margin-bottom: $unit * 6;
}

.startAppDownloadImage {
  @include asset(start-download);

  width: 90vw;
  height: 15vh;
  max-width: 500px;
  max-height: 163px;
  margin-top: $unit * 4;
  margin-bottom: $unit * 4.5;
}

.dialog {
  width: 100%;
}

@include dark-theme {
  .startedAppDownloadImage {
    @include asset(starting-download-dark);
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .wrapper {
    padding: 0 $unit * 3;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .dialog {
    width: $invite-dialog-width;
  }
}
