@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.selectPlaceholder {
  @include placeholder-text-color;
}

.phoneNumberWrapper {
  position: relative;
  margin-top: $unit;
}

.phoneNumber {
  margin-bottom: 0;
}

.phoneNumberNote {
  @include font-m;
  @include secondary-text-color;

  position: relative;
  margin-top: $unit;
}

.error {
  color: $cherry-60;
  margin-top: $unit * 2;
}

.billingModalFooter {
  padding: $unit * 3 $unit * 4;

  @media screen and (min-width: $breakpoint-mobile) {
    border-top: $default-border;
  }
}
