@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/colors";
@import "../../shared/mixins";
@import "../../shared/theme";

.assetsGrid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$unit;
  margin-right: -$unit;
  box-sizing: border-box;
  margin-bottom: $unit;
}

.overview {
  display: flex;
  padding: 0 $unit;
}

.errorOverview {
  align-items: center;
}

.details {
  margin: $unit;
  min-width: 0;
}

.layerName {
  @include ellipsis;

  font-weight: $font-medium;
}

.assetGroup {
  margin-left: -$unit * 2;
  margin-right: -$unit * 2;
}

.meta {
  @include font-s;
  @include secondary-text-color;

  min-height: 18px;
}

.artboardAssetsSection {
  margin-bottom: $unit;
  margin-top: $unit * 1.25;
}

.sectionDescription {
  @include font-s;
  @include secondary-text-color;

  padding: 0 $unit * 2;
  min-height: 18px;
}

.artboardPropertyRow {
  display: block;
  margin: $unit * 0.5 $unit * 2;
  padding: 0;
}

.label {
  @include font-xs;
  @include secondary-text-color;

  font-weight: $font-regular;
  text-transform: uppercase;
}

.assetFormat {
  @include font-s;
  @include font-mono;

  margin: $unit * 0.5 0;
}

.formatRow {
  margin: $unit $unit * 2;
  margin-bottom: $unit * 1.5;
}

.buttonLink {
  @include font-s;
  @include font-mono;
  @include text-color;

  text-decoration: none;
  line-height: 16px;
}

.downloadableAsset:hover {
  @extend .buttonLink;

  cursor: pointer;
  border-bottom: 1px dotted currentcolor;
}

.errorWrapper {
  display: flex;
  padding-top: $unit * 0.25;
}

.offline {
  color: $orange-50;
}

.icon {
  margin-top: -3px;
  margin-left: -3px;
}

.error {
  @include font-s;

  color: $red-50;
}

.link {
  display: inline-flex;
  text-decoration: none;
}

.externalIcon {
  margin-top: -2px;
  margin-left: -2px;
}

.helpLink {
  @extend .link;

  display: flex;
  padding-top: $unit * 0.25;
}

.scissorsIcon {
  margin-top: -$unit * 2;
}

.empty {
  .generateButton {
    margin-top: $unit * 2;
  }
}

.image {
  @include asset(sketch-exportable-screenshot);

  height: 94px;
  background-position: center;
  background-size: contain;
  margin: $unit * 2 0;
}

.bold {
  font-weight: $font-medium;
}
