@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";

.outerDiv {
  flex-grow: 1;
}

.channelListItem {
  display: flex;
  align-items: center;
  padding: $unit * 0.75 $unit;
  border-radius: 12px;
  user-select: none;
  outline: none;
}

.highlightedChannelListItem {
  display: flex;
  align-items: center;
  padding: $unit * 0.75 $unit;
  border-radius: 12px;
  user-select: none;
  outline: none;
  background: $blurple-60;
  color: $white;
}

.selectedChannelListItem {
  display: flex;
  align-items: center;
  padding: $unit * 0.5 $unit;
  margin: $unit * 0.75 $unit * 0.75 $unit * 0.75 0;
  border-radius: $unit;
  user-select: none;
  outline: none;
  background: $blurple-20;
}

.inputSearchError {
  box-shadow: 0 0 0 1px $red-50;
}

.selectedChannelListContainer {
  justify-content: flex-start;
}

.name {
  @include ellipsis;

  flex-shrink: 0;
  margin-left: $unit * 0.75;
}

.selectedChannelName {
  @include ellipsis;

  font-weight: $font-medium;
  flex-shrink: 0;
  margin-left: $unit * 0.75;
}

.channelName {
  @include secondary-text-color;
  @include ellipsis;

  flex-grow: 1;
  margin-left: $unit * 0.75;
}

.clickable {
  &:hover {
    @include cell-hover-background;

    cursor: pointer;
  }

  &:focus {
    color: $white;
    background: $blurple-60;

    .channelName {
      color: $white-a50;
    }
  }
}

@include dark-theme {
  // Even though position is already declared in the normal class we need to
  // re-declare it here because dark mode will override it.
  .popoverChannelSelector {
    position: absolute;
  }

  .clickable {
    &:focus {
      color: $white;
      background: $blurple-45;
    }
  }

  .selectedChannelListItem {
    background: $blurple-80;
  }
}

.removeButton {
  margin-left: $unit;
  opacity: 1;
}

.channelsTitle {
  margin-left: $unit;
  font-weight: $font-regular;
  text-transform: none;
}

.channelsList {
  padding-inline-start: $unit * 0.5;
  padding-inline-end: $unit * 0.5;
  margin: $unit 0;
}

.inputSearchContainer * {
  margin-bottom: 0;
}

.placeholderText {
  margin-left: $unit * 0.75;
  flex-grow: 0;
}

.noMatches {
  @include secondary-text-color;

  margin: $unit;
}

.popoverChannelSelector {
  @include content-background;

  width: 300px;
  position: absolute;
  margin-top: $unit;
  z-index: 2000;
}

.popoverChannelSelectorHidden {
  visibility: hidden;
}

.channelsWrapper {
  justify-content: flex-end;
}

@media screen and (min-width: $breakpoint-mobile) {
  .channelsWrapperLeftContainer {
    width: 52%;
  }

  .channelsWrapperRightContainer {
    width: 48%;
  }
}
