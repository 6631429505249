@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.tree {
  list-style: none;
}

.item {
  @include inner-keyboard-focus;

  display: block;
  position: relative;
  -webkit-user-select: none;
  text-decoration: none;
  outline: none;
  padding: $unit * 0.5 $unit * 2 $unit * 0.5 $unit;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100vw;
    bottom: 0;
    display: block;
    width: 100vw;
  }

  &.hoverable:hover,
  &.targeted {
    @include cell-hover-background;

    &::after {
      @include cell-hover-background;
    }
  }
}

.hidden {
  visibility: hidden;
}

.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.children {
  margin: 0;
  padding: 0;
  padding-left: $unit * 3;
  list-style: none;

  &.hideDisclosure {
    padding-left: $unit * 0.5;
  }
}

.label {
  @include ellipsis;
  @include font-m;
  @include text-color;

  display: block;
  flex-grow: 1;
  padding-left: $unit * 0.5;
  text-decoration: none;
}

.selected:hover,
.selected {
  background-color: $secondary-selected;

  &::after {
    background-color: $secondary-selected;
  }

  &.focused {
    background-color: $selected;

    .icon {
      g,
      path {
        fill: $white;
      }
    }

    .label {
      color: $white;
    }

    &::after {
      background-color: $selected;
    }
  }
}

.disclosureContainer {
  min-width: $unit * 3;
  min-height: $unit * 3;
  max-width: $unit * 3;
  max-height: $unit * 3;

  &.hideDisclosure {
    min-width: $unit * 0.5;
    max-width: $unit * 0.5;

    > .disclosure {
      display: none;
    }
  }
}

.disabled {
  .icon {
    opacity: 0.5;
  }
}

.disclosure {
  @include quick-transition(transform);
}

.collapsed {
  .disclosure {
    transform: rotate(-90deg);
  }
}
