@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.branches {
  position: relative;
  flex-grow: 1;
}

.placeholderBranches {
  @include border;
  @include content-background;

  margin: ($unit * 3) ($unit * 4);
  border-radius: $unit;
}

.mobile {
  margin: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
