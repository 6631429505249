@import "../shared/vars";
@import "../shared/theme";

.dateHeader {
  padding-bottom: $unit * 2;
}

.empty {
  padding-top: $unit * 10;
}

.spinner {
  @include content-background;

  flex-shrink: 0;
  height: $unit * 10;
}
