@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";
@import "../shared/typography";

.section {
  height: $unit * 6;
  padding: 0 $unit * 2;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 $unit * 4;
  }
}

.heading {
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: none;
}

.disclosure {
  @include quick-transition(transform);

  margin-left: -$unit * 0.5;

  &.collapsed {
    transform: rotate(-90deg);
  }
}

.icon {
  margin-right: $unit * 0.5;
}

.selectButton {
  margin-right: 10px;
  margin-left: 3px;
}

.itemCount {
  @include secondary-text-color;
  @include font-s;
}
