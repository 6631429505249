@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";

$border-radius: 6px;

.wrapper {
  @include secondary-content-background;
  @include outer-keyboard-focus;

  position: relative;
  display: flex;
  align-items: stretch;
  color: inherit;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  border-radius: $border-radius;
}

.transparency {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: $border-radius;
  overflow: hidden;

  @media (min-width: $breakpoint-mobile) {
    flex-direction: row;
  }
}

.preview {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
}

.previewInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
}

.previewCropper {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 1px solid var(--border-color);
    pointer-events: none;
    touch-action: none;
  }
}
