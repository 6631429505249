.annotations {
  position: relative;
  pointer-events: none; // allows clicking through to image behind
}

.list {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; // allows clicking through to image behind
}

.creating {
  cursor: crosshair;
  pointer-events: all; // when creating a new annotation it's no longer possible to zoom
}
