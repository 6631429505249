@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";

.label {
  display: none;
}

.settingsForm {
  min-height: 100%;
}

.card {
  @include text-color;

  text-decoration: none;

  &:hover .heading {
    @include text-color;

    text-decoration: underline;
  }
}

.cardContents {
  display: flex;
}

.wrap {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: $unit * 0.5;
  overflow: hidden;
  min-width: 0;
}

.headingWrap {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  overflow: hidden;
  min-width: 0;
}

.heading {
  margin: 0;

  @include ellipsis;
}

.orgList {
  padding-bottom: 0 !important;
  max-width: $max-form-width;
  margin-right: auto;
  width: 100%;
}

.orgMenu {
  margin-left: 0;
  margin-top: $unit * 2;
}

@media screen and (min-width: $breakpoint-mobile) {
  .label {
    display: block;
  }

  .orgMenu {
    margin-left: $unit * 4;
  }
}
