@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 256px;
}

.collectionText {
  width: 100%;
  margin-bottom: $unit * 2;
  padding-right: $unit * 2;
}

.addButton {
  @include content-background;
  @include inset-border;

  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: $unit;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;

  // ensures a square aspect ratio
  padding-bottom: 100%;
  width: 100%;

  &:hover {
    @include secondary-content-background;
  }

  &:focus {
    outline: 0;
  }

  img {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    max-width: $unit * 12;
    width: 100%;
  }
}

.addButtonInner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.addButtonText {
  color: $gray-60;
  text-decoration: none;
  margin: ($unit * 1.5) 0 0 0;
}

@include dark-theme {
  .addButtonText {
    color: $gray-30;
  }
}

.layerDropTarget {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 70px;
    width: 2px;
    background: $blurple-60;
    opacity: 0;
  }

  &::before {
    left: -$unit - 1px;
  }

  &::after {
    right: $unit - 1px;
  }
}

.textDropTarget {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: $unit * 2;
    height: 2px;
    max-width: 652px; // Accounting for our negative margin-left to display drag handle from CollectionText.showDragHandleOffset
    background: $blurple-60;
    opacity: 0;
  }

  &::before {
    top: -$unit;
  }

  &::after {
    bottom: 0;
  }
}

.toggleDragTargetBefore {
  &::before {
    opacity: 1;
  }
}

.toggleDragTargetAfter {
  &::after {
    opacity: 1;
  }
}
