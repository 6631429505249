@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.sidebarAccordion {
  @include border-bottom;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 0;

  &:last-child {
    border-bottom: none;
  }
}

.label {
  @include font-s;

  font-weight: $font-medium;
  text-transform: uppercase;
  flex: 1;
}

.children {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition:
    max-height 200ms ease-in-out,
    opacity 200ms ease-out;
  padding-bottom: $unit;

  .collapsed & {
    overflow: hidden;
    max-height: 0;
    padding-bottom: 0;
  }
}

.header {
  @include content-background;

  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  max-height: $unit * 5;
  padding: $unit $unit * 2 $unit $unit;
}

.disclosure {
  margin-right: $unit * 0.5;

  .collapsed & {
    transform: rotate(-90deg);
  }
}
