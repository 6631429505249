@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.invitation {
  max-width: 320px;
  margin-top: $unit * 3;
  margin-bottom: $unit;
}

.mobileRecaptcha {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

:global {
  .grecaptcha-badge {
    box-shadow: 0 0 0 1px $black-a10 !important;
    border-radius: 3px;
  }
}

@include dark-theme {
  :global {
    .grecaptcha-badge {
      box-shadow: 0 0 0 1px $white-a15 !important;
    }
  }
}
