@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";

.container {
  @include content-background;

  width: 100%;
  padding: $unit ($unit * 1.5);
  border: $default-border;
  border-radius: $unit * 0.5;
  margin: $unit * 3 auto $unit * 2;
}

.projectIcon {
  margin-left: -$unit * 0.5;
  margin-right: $unit * 0.5;
}

.projectName {
  @include display-m;
}
