@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/vars";

.navLink {
  @include font-m;
  @include text-color;
  @include quick-transition(background-color, 200ms);
  @include outer-keyboard-focus;

  font-weight: $font-medium;
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 12px;
  border-radius: 16px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    @include text-color;

    background-color: $black-a05;
  }
}

.withIcon {
  padding-top: 4px;
  padding-bottom: 4px;
}

.active {
  background-color: $gray-20;

  &:hover {
    background-color: $gray-20;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  margin-left: -8px;
  margin-right: 4px;
  color: $gray-50;
}

.iconActive {
  color: $gray-60;
}

.avatar {
  width: 16px;
  height: 24px;
  padding-top: 4px;
  margin-left: -4px;
  margin-right: $unit;
}

@include dark-theme {
  .navLink:hover {
    background-color: $black-a15;
  }

  .active {
    background-color: $black-a50;

    &:hover {
      background-color: $black-a50;
    }
  }
}
