@import "../shared/vars";
@import "../shared/colors";

.wrap {
  display: flex;
  position: relative;
  max-width: 776px;
  width: 100%;
}

.activity {
  flex-grow: 1;
}

.preview {
  flex-shrink: 0;
  margin-top: $unit;
}

.default {
  @media screen and (min-width: $breakpoint-mobile) {
    .preview {
      display: inherit;
    }
  }
}

.form {
  margin-left: -$unit * 3;

  @media screen and (min-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}
