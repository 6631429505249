@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";
@import "../shared/theme";

$personal-projects: "Your personal projects";

.organization {
  @include text-color;
  @include inner-keyboard-focus;

  text-align: left;
  display: flex;
  align-items: center;
  padding: $unit * 1.5 $unit * 2;
  text-decoration: none;
  outline: none;

  &:hover {
    @include text-color;
  }

  &:hover:not(.selected),
  &:active:not(.selected) {
    @include cell-hover-background;
  }
}

.logo {
  margin-right: $unit * 1.5;
}

.name {
  @include font-m;
  @include ellipsis;

  flex-grow: 1;
  font-weight: $font-medium;
  user-select: none;
}

.shortcut {
  @include font-s;

  color: $gray-40;
  margin-left: $unit * 1.5;
  user-select: none;
}

.isOwnUsernameOrganization {
  position: relative;

  .name {
    &::after {
      @include font-s;
      @include secondary-text-color;

      display: block;
      content: $personal-projects;
      font-weight: $font-regular;
    }
  }
}

.linkContainer {
  position: relative;
  margin: 0 $unit * 2;
}

.optionLink {
  @include inner-keyboard-focus;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $gray-10;
  border-radius: $unit;

  &:hover {
    background: darken($gray-10, 5%);
  }

  &:active {
    background: darken($gray-10, 10%);
  }
}

.linkContent {
  display: flex;
  align-items: center;
  padding: $unit * 0.75 $unit * 1.5;
  border-radius: $unit;
  pointer-events: none;
  position: relative;
}

.icon {
  width: $unit * 2;
  height: $unit * 2;
  color: $gray-50;
}

.linkText {
  font-weight: $font-medium;
  margin-left: $unit;
}

.secondLink {
  margin-top: $unit;
}

.lastLink {
  margin-bottom: $unit;
}

@include dark-theme {
  .optionLink {
    background: $gray-60;
    color: $white;

    &:hover {
      background: darken($gray-60, 5%);
    }

    &:active {
      background: darken($gray-60, 10%);
    }
  }

  .icon {
    color: $gray-40;
  }
}
