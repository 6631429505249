@import "../shared/vars";

@keyframes :global(fade-and-scale-in) {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  70% {
    transform: scale(1.05);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes :global(fade-in) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes :global(fade-in-left) {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes :global(fade-out) {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0;
    max-height: $unit * 4;
    margin-bottom: $unit * 2;
  }

  100% {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
  }
}

@keyframes :global(scale-in-v) {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes :global(scale-out-v) {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes :global(bounce) {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
