@import "../shared/vars";
@import "../shared/mixins";

.checkbox {
  flex-shrink: 0;
  align-self: flex-start;
  margin: 0.5 * $unit 0;
}

@media screen and (min-width: $breakpoint-mobile) {
  .checkbox {
    margin: 1.5 * $unit 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .checkboxLabel {
    padding: 0;
  }
}
