@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

/* prettier-ignore */
$card-shadow: 0 1px 2px 0 $black-a10, 0 0 1px $black-a15;

.card {
  @include content-background;
  @include inner-keyboard-focus($card-shadow);

  display: block;
  border-radius: 6px;
  margin-bottom: $unit * 2;
  overflow: hidden;
  box-shadow: $card-shadow;
}

.box {
  position: relative;
  padding: $unit * 2;
}

.mobile,
.list,
.header,
.footer {
  &::after {
    @include border-bottom;

    position: absolute;
    content: "";
    top: 0;
    left: $unit * 2;
    right: $unit * 2;
  }
}

.mobile,
.list {
  position: relative;
  width: 100%;
  box-shadow: none;
  background: none;
  border-radius: 0;
  margin-bottom: 0;

  &:not(.wrappedList):first-child {
    &::after {
      border: 0;
    }
  }
}

.mobile {
  &::after {
    left: 0;
    right: 0;
  }

  .header,
  .footer {
    &::after {
      left: 0;
      right: 0;
    }
  }
}

.dynamic {
  .card {
    margin-bottom: 0;
  }
}

.header {
  composes: box;
  min-height: $unit * 6;
  padding: $unit * 1.5 $unit * 2;

  h2 {
    line-height: $unit * 3;
    display: flex;
    align-items: center;
  }

  &::after {
    top: auto;
    bottom: 0;
  }
}

.footer {
  composes: box;
}

.card.collapsed:not(.mobile) {
  .header::after {
    display: none;
  }
}

.hasList {
  &:not(.collapsed) {
    .footer::after {
      border: 0;
    }

    @media screen and (min-width: $breakpoint-mobile) {
      .header::after {
        border: 0;
      }
    }
  }

  &:not(.hasFooter):not(.mobile) {
    .box,
    .box > div {
      > a:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }

  &.hasHeader {
    @media screen and (min-width: $breakpoint-mobile) {
      .header {
        margin-bottom: -1px;
      }

      .box,
      .box > div {
        > a:first-child {
          &::after {
            @include border-bottom;
          }
        }
      }
    }
  }

  &.hasFooter {
    .box,
    .box > div {
      > a:last-child {
        padding-bottom: 1px;

        &::before {
          @include border-bottom;

          position: absolute;
          content: "";
          bottom: 0;
          left: $unit * 2;
          right: $unit * 2;
        }

        &:hover::before {
          left: 0;
          right: 0;
        }
      }
    }

    &.mobile {
      .box,
      .box > div {
        > a:last-child::before {
          left: 0;
          right: 0;
        }
      }
    }
  }
}

a.card:not(.list) {
  @include quick-transition(box-shadow);

  &:hover {
    box-shadow:
      0 4px 8px $black-a05,
      0 2px 4px $black-a15;
  }
}

a.mobile,
a.list {
  &:hover {
    .box {
      @include secondary-content-background;
    }

    &::after,
    & + .list::after {
      left: 0;
      right: 0;
    }
  }
}

@include dark-theme {
  .card:not(.list) {
    @media screen and (min-width: $breakpoint-mobile) {
      @include card-outline;
    }
  }

  a.card:not(.list) {
    &:hover {
      box-shadow:
        0 0 0 1px $black,
        0 0 0 1px $white-a15 inset,
        0 2px 4px $black-a50,
        0 4px 8px 0 $black-a25;
    }
  }

  a.list:not(.wrappedList):not(.mobile) {
    /* ensures that the white inset border remains visible */
    .box {
      margin-left: 1px;
      margin-right: 1px;
    }

    &:hover {
      &::after,
      & + .list::after {
        left: 1px;
        right: 1px;
      }
    }

    &:first-child {
      margin-top: 1px;
    }

    &:last-child {
      margin-bottom: 1px;
    }
  }

  .hasList:not(.hasFooter) {
    .box,
    .box > div {
      > a:last-child {
        padding-bottom: 1px;
      }
    }
  }
}
