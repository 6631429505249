@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";
@import "../shared/typography";

.wrapper {
  display: flex;
  width: 100%;
}

.preview {
  margin-right: $unit * 2;
  display: inherit;
}

.action {
  margin-right: $unit;
  margin-bottom: $unit;
}

.help {
  @include font-s;
  @include secondary-text-color;
}
