@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.learnMore {
  display: block;
  margin-top: $unit;
}

.switch {
  margin-bottom: $unit;
}

.note {
  margin-top: -$unit;
}

.code {
  @include font-mono;
  @include secondary-content-background;

  padding: $unit * 0.25;
}
