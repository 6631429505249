@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.content {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.list {
  margin: 0 ($unit * -2);
  margin-bottom: $unit * 2;
  padding: 0;
  list-style: none;
}

.error {
  color: $red-60;
}

@include dark-theme {
  .error {
    color: $red-30;
  }
}
