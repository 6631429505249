@import "../shared/colors";
@import "../shared/typography";

.screen {
  position: relative;
}

.guide {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px dotted $pink-40;

  &.xAxis {
    border-left: none;
    border-right: none;
  }

  &.yAxis {
    border-top: none;
    border-bottom: none;
  }
}

.dimension,
.distance {
  pointer-events: none;
  position: absolute;
  width: 1px;
  height: 1px;
  background-color: $pink-40;
}

.label {
  @include font-xs;
  @include font-mono;

  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2px 4px;
  background-color: $pink-40;
  color: white;
  border-radius: 4px;
  font-size: 10px;
  font-feature-settings: "tnum";
  line-height: normal;
}
