@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: $unit * 2;
}

.title {
  @include text-color;

  margin: $unit * 0.5 0 0 0;
}

.horizontal {
  flex-direction: row;
  align-items: center;

  .title {
    position: relative;
    top: -2px;
    margin: 0 0 0 $unit * 2;
  }
}

.small {
  .title {
    margin: 0 0 0 $unit;
  }
}
