@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/theme";

.container {
  @include content-background;

  position: relative;
  min-height: 0;

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.header {
  flex-grow: 0;
}

.back {
  margin-left: -$unit * 0.5;
}
