@import "../shared/vars";
@import "../shared/theme";

.item {
  @include border-bottom;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $unit 0;
  line-height: $unit * 3;

  &:last-child {
    border: 0;
  }
}

.file {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  overflow: hidden;
  margin-left: -$unit * 0.5;
}

.complete {
  display: flex;
  align-items: center;
}

.fileIcon {
  margin-top: -2px;
}

.icon {
  height: $unit * 3;
  width: $unit * 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  color: $gray-50;
  white-space: nowrap;
}
