@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";

.progress[value] {
  // Reset the default appearance
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // Get rid of default border in Firefox
  border: none;
  height: $unit * 0.5;
  overflow: hidden;
  border-radius: 0;

  // For IE10
  color: $blurple-60;

  &::-webkit-progress-bar {
    background: $gray-20;
    border-radius: 0;
    height: $unit * 0.5;
  }

  &::-webkit-progress-value {
    transition: width 250ms;
    border-radius: 0;
    background: $blurple-60;
  }

  &::-moz-progress-bar {
    border-radius: 0;
    background: $blurple-60;
  }
}

.rounded[value] {
  border-radius: 2px;

  &::-webkit-progress-bar {
    border-radius: $unit;
  }

  &::-webkit-progress-value {
    border-radius: $unit;
  }

  &::-moz-progress-bar {
    border-radius: $unit;
  }
}

@include dark-theme {
  .progress[value] {
    &::-webkit-progress-bar {
      background: $gray-80;
    }
  }
}
