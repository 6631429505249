@import "../shared/vars";
@import "../shared/theme";

.group {
  display: flex;
  align-items: center;
}

// !important is required on these resets as the dark theme
// overrides the border-radius of buttons, otherwise taking
// specificity priority over these rules.
.grouped:not(:first-of-type):not(:last-of-type) {
  border-radius: 0 !important;
  margin-right: 1px;
}

.grouped:first-of-type:not(:last-of-type) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-right: 1px;
}

.grouped:last-of-type:not(:first-of-type) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
