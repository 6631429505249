@import "../shared/mixins";
@import "../shared/vars";

// Allows just the table to scroll when it needs to
.tableWrapper {
  overflow: auto;
}

.table {
  .th {
    @include font-xs;
    @include secondary-text-color;
    @include inset-border-bottom;

    font-weight: $font-medium;
    padding: $unit * 2 0;

    // required for absolutely positioned resizer
    position: relative;

    .sortButton {
      margin-left: -$unit;
    }

    .sortButtonActive {
      margin-left: -($unit * 0.5);
    }

    .resizer {
      width: 2px;
      height: 100%;
      position: absolute;
      right: $unit * 2;
      top: 0;
      z-index: 1;
      // prevents from scrolling while dragging on touch devices
      touch-action: none;

      &:hover {
        background: rgba($blurple-60, 0.5);
      }

      &.isResizing {
        background: $blurple-60;
      }
    }
  }

  .tbody {
    .tr {
      @include inset-border-top;

      align-items: center;
    }

    &.isLoading {
      // dims table body to indicate new data is loading
      opacity: 0.5;
    }
  }

  .td {
    padding: ($unit * 1.5) 0;
  }
}

.pageInfo {
  @include font-xs;

  text-transform: uppercase;
  margin: 0 $unit * 0.5;

  .pageNum {
    font-weight: $font-medium;
  }
}
