@import "../shared/vars";
@import "../shared/colors";
@import "../shared/theme";
@import "../shared/mixins";

.container {
  top: $unit * 3.5;
}

.mainContainer {
  width: 100%;
  height: 100%;
}

.collectionsContainer {
  max-height: calc(100vh - 250px);
  overflow: scroll;
}

.searchBar {
  padding: $unit * 2;

  &.hasBorder {
    @include border-bottom;
  }
}

.collectionPreview {
  @include inner-keyboard-focus;

  padding: $unit * 0.5 $unit * 2;
  width: 100%;
  background: none;
  border: none;
  position: relative;
  outline: none;

  &:hover {
    @include cell-hover-background;
  }
}

.createCollectionButton {
  padding: $unit * 2;
  width: 100%;

  * {
    width: 100%;
  }

  .button {
    margin-left: 0 !important;
  }

  &.hasBorder {
    @include border-top;
  }
}

.collection {
  padding: $unit * 2;
}

.emptyContainer {
  height: 220px;
}
