@import "../shared/mixins";
@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";

.floating {
  @include modal-shadow;
  @include content-background;

  border-radius: 6px;
  max-width: 90vw;
  max-height: 90vh;
  height: auto;
}

// Note: This is important. Without it the waypoint inside of InfiniteScroll is not able
// to correctly calculate the height of the flyover which causes it to continuously trigger
.wrapper {
  height: 100%;
}

@include dark-theme {
  .floating {
    @include modal-outline;
  }
}
