@import "../shared/vars";
@import "../shared/mixins";

.toggle {
  @include button-reset;

  height: $unit * 3;
  line-height: $unit * 3;
  position: absolute;
  right: $unit * 0.5;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 3px;
}
