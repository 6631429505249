@import "../../shared/vars";
@import "../../shared/theme";
@import "../../shared/mixins";
@import "../../shared/typography";

.fileList {
  margin-top: $unit * 2;
  margin-bottom: $unit;
}

.fileRow {
  @include border-bottom;

  padding: $unit 0;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    border: none;
  }
}

.fileRowRight {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: $unit * 1.5;
  padding: 0 $unit * 0.5;
  height: 100%;
}

.fileName {
  margin-left: $unit * -0.5;
}

.fileSize {
  @include font-s;
  @include secondary-text-color;

  margin-left: ($unit * 3) - 2;
}

.largeFileSize {
  color: $red-60;
  font-weight: $font-medium;
}

@include dark-theme {
  .largeFileSize {
    color: $red-30;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.checkbox {
  margin: $unit * 0.5 0;
}

.checkboxLabel {
  font-weight: $font-regular;
  padding: 0 $unit * 1.5 0 $unit;
}

.fileButtonWrapper {
  @include border-left;

  display: flex;
  padding-left: $unit;
}

.infoLink {
  @include font-s;
  @include link-color;

  text-decoration: underline;
}

.largeCommitWarning {
  margin: $unit * 3 $unit * -2 0;
}
