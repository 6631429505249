@import "../shared/vars";
@import "../shared/typography";

$input-height: $unit * 3;
$input-large-height: $unit * 4;
$input-border-radius: $input-height * 0.25;
$input-large-border-radius: $input-large-height * 0.25;

.input {
  font-weight: $font-regular;
  padding: 0 $unit 2px $unit * 3;
  height: $input-height;
  border-radius: $input-border-radius;
}

@include dark-theme {
  .input:not(:focus) {
    box-shadow:
      0 0 0 1px var(--border-color),
      0 0 0 1px var(--border-color);
  }
}

.searchWrapper {
  margin-bottom: 0;
  flex-grow: 1;

  @media screen and (min-width: $breakpoint-mobile) {
    flex-grow: 0;
  }
}

.large {
  @include font-ml;

  height: $input-large-height;
  border-radius: $input-large-border-radius;
  padding-left: 32px;
}

.iconLarge {
  left: 4px;
}
