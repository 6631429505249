@import "../shared/vars";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.container {
  @include secondary-content-background;

  display: flex;
  flex: 1;
  min-height: 0;
  height: 100%;
}

.form {
  padding: 6px $unit * 2 $unit $unit * 2;
}

.defaultForm {
  padding: $unit * 1.5 $unit * 2;

  @media screen and (min-width: $breakpoint-tablet) {
    padding: $unit * 1.5 $unit * 2 0;
  }
}

.scrollButton {
  position: absolute;
  right: 0;
  left: 50%;
  height: 24px;
  width: max-content;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2;
}

.scrollButtonTop {
  top: $unit * 1.5;
}

.scrollButtonBottom {
  bottom: $unit * 1.5;
}

.scrollButtonLoggedOut {
  bottom: 176px;
}

.icon {
  margin-right: 2px;
}

.title {
  @include font-s;
  @include secondary-text-color;
  @include quick-transition;

  padding: $unit * 1.5;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  opacity: 1;
}

.titleInner {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;

  span {
    @include ellipsis;
  }
}

.titleLink {
  cursor: pointer;
  text-decoration: none;

  &:active {
    .titleInner {
      @include text-color;
    }
  }
}

.titleLoading {
  opacity: 0.5;
}

.time {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-right: $unit * 0.5;
}

.dot {
  padding: 0 $unit * 0.5;
}

.overlay {
  @include quick-transition(background-color);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  text-decoration: none;
  background-color: transparent;

  &.center {
    .buttonContainer {
      height: 100%;
      position: relative;
      transform: translateY(-50%);
    }
  }

  &.auto {
    .buttonContainer {
      height: $unit * 6;
      position: sticky;
    }
  }
}

.buttonContainer {
  @include quick-transition(height, 200ms);

  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boundary {
  height: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlayButton {
  @include quick-transition(opacity);

  opacity: 0;
}

.root:hover {
  .titleLink {
    @include link-color-hover;

    .titleInner {
      text-decoration: underline;
    }
  }

  .group.disabled {
    cursor: pointer;

    .overlay {
      background-color: $black-a05;
    }

    .overlayButton {
      opacity: 1;
    }
  }
}

.group {
  @include content-background;

  box-shadow: 0 0 0 1px $black-a10;
  display: flex;
  flex-direction: column;
  padding: $unit 0;
  position: relative;
  text-decoration: none;
}

.boxShadow {
  box-shadow: 0 0 0 1px $black-a10;
  z-index: 1;

  &.mobileCommentsList {
    display: block;
    overflow: auto;
  }
}

.pinnedCommentsContainer {
  @include content-background;

  box-shadow: 0 0 0 1px $black-a10;
}

@include dark-theme {
  .group {
    box-shadow: 0 0 0 1px $white-a15;
  }

  .boxShadow {
    box-shadow: 0 0 0 1px $white-a15;
  }
}

.layerComments {
  @include secondary-content-background;

  display: flex;
  width: 100%;
  flex-direction: column;
}

.shareLinkSignin {
  flex-grow: 0 !important;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $unit * 2 $unit * 2 $unit;
  height: $unit * 3.75;
}

.sectionTitle {
  @include secondary-text-color;
  @include multiline-ellipsis(1);
}

.sectionTitleName,
.sectionTitleTime {
  @include font-s;

  display: inline-block;
  white-space: nowrap;
}

.sectionTitleItem {
  @include font-s;

  margin-right: $unit * 0.5;
}

.sectionTitleTime {
  @include font-s;
}

.buttonHidden {
  @media (hover) {
    display: none;
  }
}

.toggleResolvedCommentsButtonContainer {
  box-sizing: content-box;
  position: absolute;
  left: 50%;
  bottom: $unit * 1.5;
  transform: translate(-50%, 0);
  transition: transform 500ms $super-ease-out;
  z-index: 2;
}

.toggleResolvedCommentsButton {
  width: max-content;
}

.toggleResolvedCommentsButtonStacked {
  transform: translate(0, -36px);
}

.toggleResolvedCommentsSpacing {
  padding-bottom: $unit * 6;
}
