.flex {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.reverse {
  flex-direction: row-reverse;
}

.column {
  flex-direction: column;

  &.reverse {
    flex-direction: column-reverse;
  }
}

.inline {
  display: inline-flex;
}

.wrap {
  flex-wrap: wrap;
}

.noShrink {
  flex-shrink: 0;
}

.noGrow {
  flex-grow: 0;
}

.justifyCenter {
  justify-content: center;
}

.justifySpaceAround {
  justify-content: space-around;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.justifyFlexStart {
  justify-content: flex-start;
}

.justifyFlexEnd {
  justify-content: flex-end;
}

.alignStretch {
  align-items: stretch;
}

.alignCenter {
  align-items: center;
}

.alignBaseline {
  align-items: baseline;
}

.alignFlexStart {
  align-items: flex-start;
}

.alignFlexEnd {
  align-items: flex-end;
}
