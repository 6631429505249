@import "../shared/vars";
@import "../shared/mixins";

.placeholderIcon {
  @include shimmerBackground;

  width: 16px;
  height: 20px;
  margin: 2px 8px;
  border-radius: 2px;
}

.medium {
  width: 20px;
  height: 24px;
  margin: 4px 6px;
  border-radius: 3px;
}

.large {
  width: 30px;
  height: 34px;
  margin: 7px 8px;
  border-radius: 4px;
}

.xl {
  width: 56px;
  height: 64px;
  margin: 16px 20px;
  border-radius: 8px;
}
