@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/theme";

// Note: Depending on direction of design,
// either remove or update so that this isn't needed
// in so many places
@mixin reset-padding() {
  @media (max-width: $breakpoint-tablet) {
    padding-left: 0;
    padding-right: 0;
  }
}

.heading {
  padding: $unit 0;

  @media (min-width: $breakpoint-mobile) {
    padding-bottom: $unit * 2;
  }
}

.reviewerItem {
  overflow: hidden;
}

.reviewerName {
  @include font-m;
  @include ellipsis;

  line-height: $unit * 3;
  padding-left: $unit;
  width: 100%;
  margin: 0;
}

.reviewerNote {
  @include font-s;
  @include secondary-text-color;

  font-weight: $font-regular;
}

.reviewButton {
  margin-left: $unit;
}

.section {
  @include dynamic-padding(right left);
  @include reset-padding;

  padding-left: $unit;
  padding-top: $unit * 0.5;
  padding-bottom: $unit * 0.5;

  @media (max-width: $breakpoint-tablet) {
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.empty {
  @include reset-padding;
  @include secondary-text-color;
  @include dynamic-padding(right left);

  padding-left: $unit;
}

.statusIcon {
  margin-right: -$unit * 0.5;
}

.mobileWrapper {
  @media (max-width: $breakpoint-mobile) {
    padding: 0 ($unit * 2) ($unit * 2);
    margin: (-$unit * 2) 0 ($unit * 2);
  }

  @media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    @include border-bottom;

    padding: 0 ($unit * 4) ($unit * 3);
    margin-bottom: $unit * 3;
  }

  @media (max-width: $breakpoint-tablet) {
    @include border-bottom;
  }
}
