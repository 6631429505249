@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";

.note {
  @include font-s;
  @include secondary-text-color;

  margin: 0;
  padding: 0;
}
