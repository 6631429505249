@import "../shared/vars";

.flexFiller {
  flex: 1;
}

.note {
  flex: 1;
  margin-top: -$unit;
  padding-left: $unit * 3.5;
}

@media screen and (max-width: $breakpoint-mobile) {
  .flexFiller {
    display: none;
  }

  .note {
    padding-left: 0;
  }
}
