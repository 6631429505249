@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.listWrapper {
  flex-grow: 1;
  position: relative;
}

.innerListElement {
  border-left: $unit * 4 solid transparent;
  border-right: $unit * 4 solid transparent;
}

.listItem {
  @include virtualized-card-wrapper;

  &::after {
    @include border-bottom;

    position: absolute;
    content: "";
    top: 0;
    left: $unit * 2;
    right: $unit * 2;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 0;
    border-left: none;
    border-right: none;

    &::after {
      left: 0;
      right: 0;
    }
  }

  &.firstListRow {
    &::after {
      border: none;
    }

    @include border-top;

    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    @media (max-width: $breakpoint-mobile) {
      border-radius: 0;
      border-top: none;
    }
  }

  &.lastListRow {
    @include border-bottom;

    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    @media (max-width: $breakpoint-mobile) {
      border-radius: 0;
    }
  }
}

.waypoint {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.emptyAddMemberCta {
  margin: $unit * 2 0 0;
}

@media screen and (min-width: $breakpoint-mobile) {
  .dialog {
    width: $invite-dialog-width;
  }
}
