@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.addEmailLabel {
  display: flex;
  align-items: center;
  font-weight: $font-medium;
}

.separator {
  background: $blurple-30;
  margin: $unit * 4 0;
}

.inputWithButton {
  margin-bottom: 0;
}

.inviteLinkInput {
  margin: 0;
}

.copyButton {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: $unit;
}

.skip {
  margin-top: $unit * 10;
  text-align: center;
}
