@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.loading {
  width: 100%;
  height: 100%;
}

.name {
  font-weight: $font-medium;
}

.versionsCopy {
  margin-top: $unit * 8;
}

.buttons {
  margin-top: $unit * 4;
}

.launchButton {
  margin-bottom: $unit * 4;
}
