@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.goBack {
  margin-top: $unit * 4;
}

.tryAgain {
  margin-top: $unit * 8;
}

.mobileRecaptcha {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
