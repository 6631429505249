@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";

.track {
  height: 24px;

  &::before,
  &::after {
    content: $empty;
    position: absolute;
    left: 50%;
    height: 19px;
    width: 2px;
    transform: translateX(-50%);
    background: $black-a15;
  }

  &::before {
    top: -12px;
  }

  &::after {
    bottom: -12px;
  }
}

@include dark-theme {
  .track {
    &::before,
    &::after {
      background: $white-a15;
    }
  }
}

.trackSelected {
  &::before,
  &::after {
    background: $white-a50;
  }
}

.trackFirst {
  &::before {
    display: none;
  }
}

.trackCurrent {
  &::after {
    height: 16px;
  }

  &.trackLastOfGroup {
    &::after {
      height: 5px;
    }
  }
}

.trackFirstOfGroup {
  &::before {
    top: -1px;
    height: $unit;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }
}

.trackLast {
  &::after {
    display: none;
  }
}

.trackLastOfGroup {
  &::after {
    bottom: -1px;
    height: $unit;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
  }
}

.trackCircle {
  border-radius: 50%;
  background-color: $green-50;

  &.trackSelected {
    background-color: $white;
  }

  &::before,
  &::after {
    height: 12px;
  }

  &.trackFirstOfGroup {
    &::before {
      display: none;
    }
  }

  &.trackLastOfGroup {
    &::after {
      display: none;
    }
  }
}

.trackRestore {
  background-color: $blurple-60;
}

.trackPushing {
  background-color: transparent;

  &::before,
  &::after {
    height: 12px;
  }

  &.trackFirstOfGroup {
    &::before {
      display: none;
    }
  }

  &.trackLastOfGroup {
    &::after {
      display: none;
    }
  }
}
