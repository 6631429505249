@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.picker {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 298px;
}

.scrollable {
  display: flex;
  flex-direction: column;
}

.projectName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.projectIcon {
  margin-right: $unit * 0.5;
  flex-shrink: 0;
}

.searchWrapper {
  padding: $unit * 1.5 $unit * 1.5 $unit;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $unit * 0.5 $unit * 1.5 $unit * 0.5 $unit * 1.5;
  min-height: $unit * 4;

  &.disabled {
    opacity: 0.5;
  }

  &.enabled {
    &:hover,
    &:active {
      @include cell-hover-background;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: $white;
      background-color: $blurple-60;

      .fileName {
        color: $white;
      }

      .fileExtension {
        color: $white-a50;
      }
    }
  }
}

.list {
  user-select: none;
  list-style: none;
  margin: $unit 0;
  padding: 0;

  &.withFilter {
    margin-top: 0;
  }
}

.column {
  @include border-right;

  display: flex;
  flex-direction: column;
  width: 50%;

  &:last-child {
    border-right: 0;
  }
}
