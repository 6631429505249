@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";

.error {
  @include font-s;

  color: $red-60;
  padding: 7px 0;
  font-weight: $font-medium;
}

@include dark-theme {
  .error {
    color: $red-30;
  }
}

.errorList {
  margin: 0;
  padding: 0;
  list-style: none;
}
