.screen {
  position: relative;
  opacity: 0;

  &.animate {
    animation-name: blink;
    animation-duration: 700ms;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }

    29% {
      opacity: 1;
    }

    72% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
