@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  &.columnFooter {
    flex-direction: column;
  }
}

.submitButton {
  flex-grow: 1;
  text-align: right;

  &.fullContainer {
    width: 100%;
  }
}

.error {
  margin-bottom: 2 * $unit;
}
