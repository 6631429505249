@import "../shared/vars";
@import "../shared/theme";

.wrapper {
  border-radius: 3px;
  align-items: center;
  justify-content: space-between;
}

.wrapBorder {
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 -1px 0 0 rgba(0, 0, 0, 0.1);
  padding: $unit * 0.75;
}

.avatar {
  flex: 0 0 auto;
  margin: 0 $unit;
}

.selector {
  flex-grow: 1;
}

@include dark-theme {
  .wrapBorder {
    @include box-shadow;
    @include input-background-disabled;
  }
}
