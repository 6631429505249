@import "../shared/colors";
@import "../shared/typography";
@import "../shared/vars";

.stickyHeader {
  @include font-s;

  background-color: var(--content-background);
  color: var(--text-color);
  font-weight: $font-medium;
  padding: $unit $unit * 2;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  z-index: 2;
}
