@import "../shared/colors";
@import "../shared/vars";
@import "../shared/theme";

.spinner {
  text-align: center;

  ul {
    width: $unit * 4;
    height: $unit * 4;
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      position: absolute;
      list-style: none;
      top: 50%;
      left: 0;

      span {
        background-color: $gray-60;
        position: absolute;
        top: 0;
        left: 0;
        width: 28%;
        height: 2px;
        border-radius: 2px;
        transform: translate3d(0, -50%, 0);
        opacity: 0;
      }

      &:nth-child(1) {
        transform: rotate(0deg);

        span {
          animation: blades 1s linear -0.913s infinite;
        }
      }

      &:nth-child(2) {
        transform: rotate(30deg);

        span {
          animation: blades 1s linear -0.83s infinite;
        }
      }

      &:nth-child(3) {
        transform: rotate(60deg);

        span {
          animation: blades 1s linear -0.747s infinite;
        }
      }

      &:nth-child(4) {
        transform: rotate(90deg);

        span {
          animation: blades 1s linear -0.664s infinite;
        }
      }

      &:nth-child(5) {
        transform: rotate(120deg);

        span {
          animation: blades 1s linear -0.581s infinite;
        }
      }

      &:nth-child(6) {
        transform: rotate(150deg);

        span {
          animation: blades 1s linear -0.498s infinite;
        }
      }

      &:nth-child(7) {
        transform: rotate(180deg);

        span {
          animation: blades 1s linear -0.415s infinite;
        }
      }

      &:nth-child(8) {
        transform: rotate(210deg);

        span {
          animation: blades 1s linear -0.332s infinite;
        }
      }

      &:nth-child(9) {
        transform: rotate(240deg);

        span {
          animation: blades 1s linear -0.249s infinite;
        }
      }

      &:nth-child(10) {
        transform: rotate(270deg);

        span {
          animation: blades 1s linear -0.166s infinite;
        }
      }

      &:nth-child(11) {
        transform: rotate(300deg);

        span {
          animation: blades 1s linear -0.083s infinite;
        }
      }

      &:nth-child(12) {
        transform: rotate(330deg);

        span {
          animation: blades 1s linear 0s infinite;
        }
      }
    }
  }
}

.light {
  ul {
    li {
      span {
        background: $white;
      }
    }
  }
}

.tint {
  ul {
    li {
      span {
        background: $blurple-60;
      }
    }
  }
}

@include dark-theme {
  .tint {
    ul {
      li {
        span {
          background: $blurple-45;
        }
      }
    }
  }
}

.small {
  width: 16px;
  height: 18px;

  ul {
    width: 14px;
    height: 14px;

    li {
      span {
        width: 31%;
        height: 2px;
        border-radius: 1px;
      }

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(9),
      &:nth-child(11) {
        display: none;
      }
    }
  }
}

@keyframes blades {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include dark-theme {
  .spinner:not(.tint) ul li span {
    background-color: $gray-30;
  }
}
