@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.container {
  @include font-s;

  will-change: transform;
  display: inline;
  padding: $unit $unit * 1.5;
  border-radius: 9px;
  background-color: $gray-70;
  text-align: center;
  color: $white;
  text-transform: none;
  pointer-events: none;
  touch-action: none;
}

@include dark-theme {
  .container {
    background-color: $white;
    color: $gray-70;
  }
}

.label {
  max-width: 200px;
}

.arrowContainer {
  position: absolute;
  pointer-events: none;
}

.arrow {
  width: 0;
  height: 0;
  border: 12px solid transparent;
}

.top,
.top-start,
.top-end {
  margin-top: -$unit;

  .arrowContainer {
    bottom: -18px;
  }

  .arrow {
    border-top-color: $gray-70;
  }
}

.right,
.right-start,
.right-end {
  margin-left: $unit;

  .arrowContainer {
    left: -18px;
  }

  .arrow {
    border-right-color: $gray-70;
  }
}

.bottom,
.bottom-start,
.bottom-end {
  margin-top: $unit;

  .arrowContainer {
    top: -18px;
  }

  .arrow {
    border-bottom-color: $gray-70;
  }
}

.left,
.left-start,
.left-end {
  margin-left: -$unit;

  .arrowContainer {
    right: -18px;
  }

  .arrow {
    border-left-color: $gray-70;
  }
}

@include dark-theme {
  .top,
  .top-start,
  .top-end {
    .arrow {
      border-top-color: $white;
    }
  }

  .right,
  .right-start,
  .right-end {
    .arrow {
      border-right-color: $white;
    }
  }

  .left,
  .left-start,
  .left-end {
    .arrow {
      border-left-color: $white;
    }
  }

  .bottom,
  .bottom-start,
  .bottom-end {
    .arrow {
      border-bottom-color: $white;
    }
  }
}
