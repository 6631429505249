@import "../shared/mixins";
@import "../shared/vars";

$buttonHeight: 24px;

.wrapper {
  position: relative;
  display: flex;
}

.flyoverContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: $depth-notifications;
  top: $buttonHeight;
  width: 320px;
  transform-origin: 100% 0;
}

@include dark-theme {
  .flyoverContainer {
    // FIXME: picker position in dark mode
    // this "fixes" an issue where the modal-outline applied to
    // <Floating /> in dark mode also applies position: relative
    // which breaks the flyover positioning.
    position: absolute !important;
  }
}

.left {
  transform-origin: 0 0;
  left: 0;
}

.right {
  right: 0;
}

.center {
  transform: translate3d(-50%, 0, 0);
  transform-origin: 50% 0;
  margin-left: 50%;
  left: 0;
  right: 0;
}

.topLeft {
  transform-origin: 0 100%;
  top: unset;
  bottom: calc(100% + #{$unit});
  left: 0;
}

.animatedFlyoverContainer {
  z-index: $depth-notifications;
}

.animatedFlyoverLeftContainer {
  position: absolute;
}

.animatedFlyoverEnterActive,
.animatedFlyoverCenterEnterActive {
  @include quick-transition(opacity);
  @include quick-transition(transform);
}

.animatedFlyoverEnter {
  opacity: 0;
  transform: scale3d(0.9, 0.9, 0.9);

  &.animatedFlyoverEnterActive {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animatedFlyoverLeave {
  opacity: 1;
  transform: scale3d(1, 1, 1);

  &.animatedFlyoverLeaveActive {
    opacity: 0;
    transform: translateY(0) scale3d(0.9, 0.9, 0.9);
  }
}

.animatedFlyoverCenterEnter {
  opacity: 0;
  transform: scale3d(0.9, 0.9, 0.9);

  &.animatedFlyoverCenterEnterActive {
    opacity: 1;
    margin-left: -50%;
    transform: scale3d(1, 1, 1);
  }
}

.animatedFlyoverCenterLeave {
  opacity: 1;
  transform: scale3d(1, 1, 1);

  &.animatedFlyoverCenterLeaveActive {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
}
