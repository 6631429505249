@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: $unit * 6;
  background-color: $gray-70;
}

.menuButton {
  margin-left: $unit * 1.5;
}

.notificationsButton {
  margin-right: $unit * 2;
}

@include dark-theme {
  .header {
    background-color: #171818;
    box-shadow: inset 0 -1px 0 $black;
  }
}
