@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.button {
  @include font-m;
  @include text-color;
  @include outer-keyboard-focus;

  margin: 0;
  padding: 0 $unit;
  border: none;
  background-color: transparent;
  display: inline-block;
  border-radius: 6px;
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  text-decoration: none;
  font-weight: $font-medium;
  position: relative;
  // ensure buttons never truncate if placed within flexbox
  flex-shrink: 0;
}

.buttonFullWidth {
  width: 100%;
}

.buttonSmall {
  border-radius: 3px;
  min-width: 16px;
  min-height: 16px;
  line-height: 16px;
}

.buttonLarge {
  border-radius: 8px;
  min-width: 32px;
  min-height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}

.buttonHasOnlyIcon {
  padding-left: 0;
  padding-right: 0;
}

.buttonHasLeftIcon {
  padding-left: 2px;
}

.buttonLargeHasLeftIcon {
  padding-left: 4px;
}

.buttonHasRightIcon {
  padding-right: 0;
}

.buttonLargeHasRightIcon {
  padding-right: 2px;
}

.iconRight {
  margin-left: -2px;
}

.label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  user-select: none;
}

.nude {
  @include text-color;

  cursor: pointer;

  &:disabled {
    @include placeholder-text-color;

    cursor: default;
  }

  &:hover:not(:disabled) {
    @include text-color;

    background-color: $black-a05;
  }

  &:active:not(:disabled),
  &.active:not(:disabled) {
    background-color: $black-a10;
  }
}

@include dark-theme {
  .nude {
    &:hover:not(:disabled) {
      background-color: $black-a25;
    }

    &:active:not(:disabled),
    &.active:not(:disabled) {
      background-color: $black-a50;
    }
  }
}

.nudeDanger {
  color: $orange-60;

  &.active:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    color: $orange-60;
  }
}

.nudeDark {
  @include secondary-text-color;
}

.nudeLight {
  color: $white;

  &:hover:not(:disabled) {
    background-color: none;
    color: $white-a75;
  }

  &:active:not(:disabled),
  &.active:not(:disabled) {
    background-color: none;
    color: $white-a50;
  }
}

.nudeTint {
  @include link-color;

  &:disabled {
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    background-color: none;

    @include link-color-hover;
  }

  &:active:not(:disabled),
  &.active:not(:disabled) {
    background-color: none;

    @include link-color-active;
  }
}

.nudeTintHover {
  &:hover,
  &:focus {
    &:not(:disabled) {
      background-color: $black-a05;
    }
  }

  &:active:not(:disabled),
  &.active:not(:disabled) {
    background-color: $black-a10;
  }
}

@include dark-theme {
  .nudeTintHover {
    &:hover,
    &:focus {
      &:not(:disabled) {
        background-color: $black-a25;
      }
    }

    &.active:not(:disabled),
    &:active:not(:disabled) {
      background-color: $black-a50;
    }
  }
}

.primary {
  @include button-color-variant(
    $bg: $blurple-60,
    $bg-hover: $blurple-70,
    $bg-active: $blurple-80
  );
}

.allgood {
  @include button-color-variant(
    $bg: $green-50,
    $bg-hover: $green-60,
    $bg-active: $green-70
  );
}

.danger {
  @include button-color-variant(
    $bg: $cherry-60,
    $bg-hover: $cherry-70,
    $bg-active: $cherry-80
  );
}

.warning {
  @include button-color-variant(
    $bg: $orange-50,
    $bg-hover: $orange-60,
    $bg-active: $orange-70
  );
}

.dark {
  @include button-color-variant(
    $bg: $gray-60,
    $bg-hover: darken($gray-60, 5%),
    $bg-active: darken($gray-60, 15%)
  );
}

.neutral {
  @include text-color;

  background-color: $gray-10;

  &:disabled {
    @include placeholder-text-color;

    background-color: $gray-10;
    cursor: default;
  }

  &:hover:not(:disabled) {
    @include text-color;

    background-color: $gray-20;
  }

  &.active:not(:disabled),
  &:active:not(:disabled) {
    @include text-color;

    background-color: darken($gray-20, 5%);
  }
}

@include dark-theme {
  .neutral {
    @include button-color-variant(
      $bg: $gray-60,
      $bg-hover: darken($gray-60, 5%),
      $bg-active: darken($gray-60, 15%)
    );
  }
}
