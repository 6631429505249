@import "../../shared/vars";
@import "../../shared/typography";
@import "../../shared/theme";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $unit * 2;

  h3 {
    @include font-m;

    margin: 0;
  }
}

.content {
  margin: $unit * 3 $unit * 4 $unit * 2;
}

.note {
  p {
    margin-bottom: $unit * 2;
  }

  a {
    @include link;

    text-decoration: underline;
  }
}

.input {
  margin-bottom: $unit;
}

.footer {
  @include border-top;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $unit * 2;
  width: 100%;

  button:only-child {
    margin-left: auto;
  }

  div:only-child {
    margin-left: auto;
  }
}

.inputWithButton {
  margin-bottom: $unit;
  width: 75%;
  display: inline-block;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.textarea {
  resize: vertical;
}

.copyButton {
  margin-bottom: $unit;
}

.labels {
  display: flex;
  justify-content: space-between;
}

.error {
  @include font-s;

  color: $red-50;
}

.seatsAlertText {
  color: $red-50;
}

.seatsAlertLink {
  text-decoration: none;
}

.inputLabelHelper {
  font-weight: $font-regular;
}
