@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black;
  width: $unit * 8;
  height: $unit * 8;
  border-radius: 50%;
}

.checkmark {
  background: $sage;
}

.download {
  background: $oat;
}

.mail {
  background: $blueprint;
}

.organization {
  background: $blueprint;
}

.search-default {
  background: $gold;
}
