@import "../shared/vars";

.modal {
  @media (min-width: 640px) {
    width: $unit * 44;
  }
}

.removeScroll {
  overflow: hidden;
}
