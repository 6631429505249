@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.center {
  margin-left: $unit;
  min-width: 0;
  align-items: center;
}

.info {
  min-width: 0;
}

.teamName {
  font-weight: $font-medium;

  @include ellipsis;
}

.meta {
  @include font-s;
  @include ellipsis;
  @include secondary-text-color;
}
