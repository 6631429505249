@import "../shared/colors";

.gridContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.layout {
  position: absolute;
}

.row {
  position: absolute;
  width: 100%;
  background-color: rgba($pure-red, 0.2);
}

.column {
  position: absolute;
  height: 100%;
  background-color: rgba($pure-red, 0.2);
}

.horizontalLine {
  border-top: 1px solid rgba($pure-red, 0.5);
  position: absolute;
  width: 100%;
}
