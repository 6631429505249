@import "../shared/mixins";

.toggle {
  display: flex;
  align-items: center;
}

.button {
  $default-opacity: 0.5;
  $active-opacity: 1;

  opacity: $default-opacity;
  flex-shrink: 0;

  &:active {
    background: none !important;
  }

  &:hover,
  &.active {
    opacity: $active-opacity;
  }

  @include focus-visible using ($slot) {
    @if $slot == focus {
      opacity: $active-opacity;
    } @else if $slot == reset {
      opacity: $default-opacity;
    }
  }
}
