@import "../shared/colors";
@import "../shared/typography";
@import "../shared/vars";

.overflow {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  flex-grow: 1;
}

.wrapper {
  height: 100%;
  flex-shrink: 0;
  z-index: 1;
  overflow-y: hidden;

  @media screen and (min-width: $breakpoint-mobile) {
    min-width: $branch-list-min-width;
  }
}

.zoom {
  display: none;
  z-index: 10;
  position: absolute !important;

  @media screen and (min-width: $breakpoint-mobile) {
    display: flex;
    position: absolute;
    bottom: $vpadding;
    right: $hpadding;
  }
}
