@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";

.transparency {
  flex: 1;
  display: flex;

  @include content-background;

  background-size: $unit * 2 $unit * 2;
  background-position:
    0 0,
    $unit $unit;
  background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23EDEEEF" x="0" y="0" width="8" height="8"></rect><rect fill="%23EDEEEF" x="8" y="8" width="8" height="8"></rect></g></svg>');
}

.fixed {
  background-attachment: fixed;
}

@include dark-theme {
  .transparency {
    background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23222324" x="0" y="0" width="8" height="8"></rect><rect fill="%23222324" x="8" y="8" width="8" height="8"></rect></g></svg>');
  }
}

.forceDark {
  background-color: $gray-70;
  background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23222324" x="0" y="0" width="8" height="8"></rect><rect fill="%23222324" x="8" y="8" width="8" height="8"></rect></g></svg>') !important;
}

.forceLight {
  background-color: $white;
  background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="%23EDEEEF" x="0" y="0" width="8" height="8"></rect><rect fill="%23EDEEEF" x="8" y="8" width="8" height="8"></rect></g></svg>') !important;
}

.forceSolidLight {
  background-color: $gray-10;
  background-image: none !important;
}

.forceSolidDark {
  background-color: $black;
  background-image: none !important;
}
