@import "../shared/mixins";

.container {
  width: 100%;
  height: 100%;
  min-width: 0;
}

.main {
  min-width: 0;
  min-height: 0;
  height: 100%;
  position: relative;
}
