@import "../shared/typography";
@import "../shared/vars";

.layerSetControl {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: $unit * 1.5;
}

.layerSetLabel {
  @include font-xs;

  padding: 0 $unit * 0.5;
  font-feature-settings: "tnum";
}
