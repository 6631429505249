@import "../shared/colors";
@import "../shared/vars";

.previewWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: $unit;

  // ensures a square aspect ratio
  padding-bottom: 100%;
}

.previewInner {
  position: absolute;
  top: $unit;
  left: $unit;
  right: $unit;
  bottom: $unit;
  width: auto;
  height: auto;
}

.bgGray {
  background: $gray-10;
}

.borderLarge {
  border-radius: $unit;
}

.borderSmall {
  border-radius: 3px;
}
