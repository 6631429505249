@import "../../../shared/vars";

.team {
  margin: 0;
  padding: ($unit * 0.5) ($unit * 2);
}

.actions {
  margin-left: $unit;
}
