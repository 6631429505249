@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.tabContainer {
  border-bottom: 1px solid var(--border-color);
  height: $unit * 6;
  justify-content: center;
  position: relative;
}

.footer {
  align-items: center;
  border-top: 1px solid var(--border-color);
  height: $unit * 6;
  padding: 0 $unit * 2;
}

.deliveryId {
  @include font-s;
  @include font-mono;

  margin: 0 $unit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timestamp {
  flex: 1;
  padding-left: $unit;
  text-align: right;
  white-space: nowrap;
}

.body {
  display: block;
  height: 100%;
  overflow: auto;
  padding: $unit * 4;
}

.close {
  left: $unit * 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.redeliver {
  height: $unit * 3;
  position: absolute;
  right: $unit * 2;
  top: 50%;
  transform: translateY(-50%);
}

.badge {
  flex-grow: 0;
  flex-shrink: 0;
}

@media (max-width: $breakpoint-mobile) {
  .tabContainer {
    height: 86px;
    justify-content: flex-start;
    padding: 0 $unit * 2;
  }

  .tabs {
    margin-top: $unit * 6;
  }

  .close,
  .redeliver {
    top: $unit * 2;
    transform: none;
  }
}
