@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.container {
  @include secondary-content-background;

  position: relative;
  width: 100%;
  padding: ($unit * 3) 0 ($unit * 1.5);
  text-align: center;

  @include jagged-edge(
    top,
    var(--secondary-content-background),
    var(--content-background)
  );
}

.icon {
  margin: -($unit);
}

.secondary {
  @include font-s;
  @include secondary-text-color;
}

.tryAgain {
  margin-top: $unit * 0.5;
}
