@import "../shared/vars";
@import "../shared/typography";
@import "../shared/mixins";
@import "../shared/theme";

.file {
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  label {
    display: flex;
    flex-shrink: 1;
    justify-content: space-between;
    width: 100%;
  }
}

.icon {
  margin-left: -$unit;
}

.content {
  flex-grow: 1;
  margin-top: $unit * 0.5;
  padding-right: $unit;
  min-width: 0;

  p {
    line-height: 1.4;
  }

  .fileName {
    @include ellipsis;
    @include text-color;

    font-weight: $font-medium;
    margin-bottom: 0;
  }

  .meta {
    @include font-s;
    @include secondary-text-color;

    margin-bottom: 0;
  }
}

.fileExtension {
  @include secondary-text-color;

  font-weight: $font-regular;
}

.checkbox {
  flex-grow: 0;
  margin-top: $unit * 1.5;
  margin-right: $unit * 0.5;
  font-size: 18px;
}
