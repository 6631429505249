@import "../../shared/typography";
@import "../../shared/theme";
@import "../../shared/vars";
@import "../../shared/colors";
@import "../../shared/mixins";

.checkbox {
  margin-bottom: 0;
}

.label {
  font-weight: $font-medium;
  margin-bottom: $unit;
  margin-top: $unit;
}

.libraryLabel {
  margin-left: $unit;
}

.infoLink {
  @include font-s;
  @include link-color;

  text-decoration: underline;
}

.fileButton {
  @include border;
  @include content-background;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  width: 50%;
  max-width: 50%;
  height: $unit * 15.5;
  outline: none;
  padding-top: $unit * 2;
  padding-bottom: $unit * 3;

  &:first-child {
    border-right-color: transparent;
    border-top-left-radius: $unit * 0.5;
    border-bottom-left-radius: $unit * 0.5;
  }

  &:last-child {
    border-left-color: transparent;
    border-top-right-radius: $unit * 0.5;
    border-bottom-right-radius: $unit * 0.5;
  }

  &:focus {
    @include border-focus;

    outline: none;
  }
}

.fileButtonName {
  @include font-m;
  @include ellipsis;
  @include text-color;

  font-weight: $font-medium;
  max-width: $unit * 22;
}

.fileButtonType {
  @include font-s;
  @include secondary-text-color;
}

.fileButtonSelected {
  background-color: $blurple-60;
  border-color: $blurple-60;

  .fileButtonName {
    color: $white;
  }

  .fileButtonType {
    color: $white-a50;
  }
}
