@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.card {
  z-index: 2;
  margin: 0;
  border-radius: $unit;
}

.innerBox {
  line-height: 11px;
  padding: $unit * 0.5;
  display: flex;
}
