@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";

$step-size: $unit * 1.5;
$line-width: $unit * 6;

.step {
  position: relative;
  width: $step-size;
  height: $step-size;
  border-radius: 50%;
  background: $blurple-30;
}

.currentStep {
  background: $blurple-60;
}

.hasLineAfter {
  margin-right: $line-width;
}

.hasLineAfter::after {
  content: "";
  position: absolute;
  width: $line-width;
  height: 0;
  border-bottom: 2px solid $blurple-30;
  top: ($step-size * 0.5) - 1;
  left: $step-size;
}

.dotted::after {
  border-bottom-style: dotted;
}

@include dark-theme {
  .step {
    background: $blurple-80;
  }

  .currentStep {
    background: $blurple-60;
  }

  .hasLineAfter::after {
    border-bottom-color: $blurple-80;
  }
}
