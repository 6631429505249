@import "../../shared/colors";

.layerChild {
  position: absolute;
  box-sizing: border-box;

  &.showHotspots {
    border: none;
    box-shadow:
      0 0 0 1px #e86e2c,
      inset 0 0 0 1px #e86e2c;
    background-color: rgba(232, 110, 44, 0.15);
  }

  &.targeted {
    border: 1px solid $pink-40;
  }

  &.selected {
    border: none;
    box-shadow:
      0 0 0 1px $pink-40,
      inset 0 0 0 1px $pink-40;
  }

  &.noPointerEvents {
    pointer-events: none;
  }
}
