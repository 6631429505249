@import "../shared/colors";
@import "../shared/vars";
@import "../shared/animations";

.wrapper {
  pointer-events: all;
}

.annotation {
  width: 100%;
  height: 100%;
  border: 2px dashed $blurple-60;
  cursor: pointer;

  &:hover {
    .bubble {
      @include bounce;

      animation-duration: 350ms;
    }
  }
}

.bottomRightHandler {
  z-index: 2;
}

.moving {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.selected {
  .bubble {
    @include bounce;

    animation-duration: 350ms;
  }
}

.editing {
  cursor: move;
}

.selected,
.editing {
  border-style: solid;
}

.grip {
  @include fade-in;

  animation-duration: 150ms;
  width: $unit;
  height: $unit;
  background: $white;
  box-shadow: 0 0 0 1px $black-a10;
  border-radius: 1.5px;
  position: absolute;

  &.top {
    top: -3px;
  }

  &.bottom {
    bottom: -3px;
  }

  &.left {
    left: -3px;
  }

  &.right {
    right: -3px;
  }

  &.top,
  &.bottom {
    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.left,
  &.right {
    &.center {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.bubble {
  position: absolute;
  bottom: -10px;
  right: -10px;
}

.borderHidden {
  border: 0;

  .handle {
    display: none;
  }
}
