@import "../shared/mixins";
@import "../shared/theme";

.changeset {
  position: relative;
  height: 100%;
}

.hasFooter {
  @include secondary-content-background;
}
