@import "../../shared/vars";
@import "../../shared/typography";

.label {
  margin-left: $unit;
  font-weight: $font-regular;
}

.checkbox {
  margin-bottom: -10px !important;
}

.branch {
  font-weight: $font-medium;
}

.notice {
  margin-top: $unit * 2;
  margin-bottom: $unit * 2;
}
