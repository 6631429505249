@import "../shared/typography";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";

.sidebarSubSection {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: $unit * 5;
  padding: $unit * 0.75 $unit * 2;
  z-index: 1;
  background: var(--content-background);
}

.label {
  @include ellipsis;
  @include font-m;
  @include quick-transition(color, 250ms);

  font-weight: $font-medium;

  .changed & {
    @include link-color;
  }
}

.children {
  display: flex;
  flex-direction: column;
  flex: 1;
}
