@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";
@import "../shared/theme";

.link {
  @include outer-keyboard-focus;

  border-radius: $unit;
  color: inherit;
  display: block;
  position: relative;
  text-decoration: none;

  &:hover {
    @include text-color;

    .line1Text {
      @include text-color;
    }
  }
}

.hasHover {
  cursor: pointer;

  &:hover {
    .previewWrapper {
      background: $gray-10;
      border-color: $gray-20;
      text-decoration: underline;
    }

    .line1Text {
      text-decoration: underline;
    }
  }
}

.line1,
.line1 .link {
  display: flex;
  align-items: center;
  height: $unit * 3.5; // If this changes, update VirtualizedList
}

.line1 .link {
  max-width: 100%;
}

.line1Icon {
  margin-left: -$unit * 0.5;
  margin-right: $unit * 0.5;
}

.line1Text {
  @include ellipsis;

  flex-shrink: 1;
  flex-grow: 1;
}

.line2 {
  color: $gray-50;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 0;
  height: 18px; // If this changes, update VirtualizedList
}

.statusBadge {
  display: flex;
  margin-right: 6px;
}

.comments {
  display: flex;
  align-items: center;
}

.dot {
  margin-right: 6px;
}

.commentsIcon {
  margin-left: -6px;
}

.commentCount {
  @include font-xs;

  margin-left: -2px;
  font-weight: $font-medium;
}

.wrapper {
  border-radius: $unit;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  &:not(.selecting) .selectButton {
    display: none;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    &:hover {
      .menuButton,
      .selectButton {
        display: inline-block;
      }
    }
  }
}

.inner {
  width: 100%;
  position: relative;
}

.menuButton,
.selectButton {
  position: absolute;
  top: $unit * 2;
  z-index: 1;
}

.menuButton {
  right: $unit * 2;
  width: $unit * 3;
  height: $unit * 3;
  cursor: pointer;
}

.selectButton {
  left: $unit * 2;
}

.preview {
  @include quick-transition;

  box-shadow: 0 0 0 1px $black-a15;
}

@include dark-theme {
  .preview {
    box-shadow: 0 0 0 1px $black;
  }
}

.selecting {
  &:not(.selected) {
    .preview {
      box-shadow: 0 0 0 1px $black-a25;

      &::before {
        position: absolute;
        border-radius: $unit;
        content: "";
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }
}

@include dark-theme {
  .selecting {
    &:not(.selected) {
      .preview {
        box-shadow: 0 0 0 1px $black;
      }
    }
  }
}

.selected {
  .preview {
    box-shadow: 0 0 0 2px $blurple-60;
  }
}

.removed {
  cursor: default;

  * {
    cursor: default;
  }

  .line1 {
    @include text-color;

    text-decoration: line-through;
  }
}

.extras {
  &:not(.safari) {
    opacity: 0;
  }
  // Workaround for an issue with opacity causing hover to stick on Safari
  &.safari {
    filter: opacity(0%);
  }
}

.alwaysShowExtras,
.focused .extras,
.hasHover:hover .extras {
  &:not(.safari) {
    opacity: 1;
  }
  // Workaround for an issue with opacity causing hover to stick on Safari
  &.safari {
    filter: opacity(100%);
  }
}
