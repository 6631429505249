@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.content {
  width: 100%;
  max-width: 480px;
  margin: 100px $unit 0;
  text-align: center;
}

.illustration {
  @include asset(message);

  width: 120px;
  height: 120px;
  background-position: center;
  background-size: contain;
}

.heading {
  margin-top: $unit * 2;
}

.note {
  @include secondary-text-color;

  margin-top: $unit * 2;
}

.buttonWrapper {
  margin-top: $unit * 4;
}
