@import "../../shared/typography";
@import "../../shared/mixins";
@import "../../shared/vars";
@import "../../shared/theme";

.item {
  @include content-background;

  > div {
    display: flex;
    flex-wrap: nowrap;
    padding: $unit * 1.5 $unit * 2;
  }
}

.center {
  padding-top: 2px;
  margin-right: $unit * 1.5;
}

.icon {
  margin-left: -$unit * 0.5;
  margin-right: $unit;
}

.badge {
  height: $unit * 3;
  display: flex;
  align-items: center;
}

.secondaryLabel {
  @include secondary-text-color;
  @include font-s;

  font-weight: $font-medium;
  display: flex;
  align-items: center;
}
