@import "../shared/mixins";
@import "../shared/vars";

.wrapper {
  overflow: hidden;
}

.skipNavLink {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;

  &:focus {
    @include content-background;
    @include outer-keyboard-focus;

    line-height: $unit * 4;
    border-radius: $unit;
    padding: 0 $unit;
    position: fixed;
    top: 12px;
    left: 12px;
    z-index: 3;
    height: auto;
    width: auto;
    clip: auto;
  }
}

.skipNavLinkDesktop:focus {
  top: 12px + $title-bar-height;
}

.topSectionWrapper {
  padding: $unit * 3 $unit * 2;
  border-bottom: 1px solid transparent;
  transition: border-bottom 200ms ease-in-out;

  &.isScrolled {
    @include border-bottom;
  }
}

.organizationWrapper {
  margin: 0 $unit;
}

.organizationSwitcherWrapper {
  min-width: 0;
}

.organizationSwitcher {
  flex-shrink: 1;
  margin: -$unit;
}

.notificationsButton {
  flex-shrink: 0;
  margin-left: $unit * 2;
}

.closeButton {
  flex-shrink: 0;
  margin-left: $unit * 2;
}

.quickJumpTeaser {
  margin-top: $unit * 3;
}

.accountSection {
  border-top: 1px solid transparent;
  transition: border-top 200ms ease-in-out;

  &.menuIsOverflowing {
    @include border-top;
  }
}

.accountButtonsWrapper {
  margin: $unit * 3;
  min-width: 0;
}

.supportButton {
  margin-left: $unit * 2;
}
