@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.selection {
  flex: 1;
}

.selectionGroup {
  margin-bottom: 2 * $unit;
}

.radio {
  margin: 0;
}

.icon {
  vertical-align: middle;
  margin: 0 $unit * 0.5;
}

.list {
  padding: 0; // reset padding
  margin-bottom: 14px;
}

.branchReviewNote {
  @include font-m;

  font-weight: $font-regular;
}

.reviewerNote {
  @include font-s;
  @include secondary-text-color;

  font-weight: $font-regular;
}

.sectionHeader {
  @include font-m;

  font-weight: $font-medium;
  margin-bottom: $unit;
}

.sectionText {
  margin-bottom: 2.5 * $unit;
}

.actions {
  margin-left: $unit;
}

// Edit Reviewers

.itemUpdating {
  opacity: 0.5;
}

.loadingDialog {
  overflow: hidden;
}
