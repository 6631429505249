@import "../shared/mixins";
@import "../shared/theme";
@import "../shared/vars";

.organizationButton {
  height: $unit * 5;
  padding-left: $unit;
  text-transform: none;

  &:hover {
    color: inherit !important;
  }
}

.innerButton {
  padding: 1px 0;
}

.organizationNameWrapper {
  flex: 0;
}

.organizationName {
  @include ellipsis;

  margin-left: $unit;
  text-align: left;
}

.floating {
  padding: 0;
}

.organizationButtonPlaceholder {
  padding: $unit;
}

.organizationAvatarPlaceholder {
  @include shimmerBackground;

  width: $unit * 3;
  height: $unit * 3;
  border-radius: 25%;
}

.organizationNamePlaceholder {
  margin-left: $unit;
}

.organizationsList {
  margin: 0;
  padding: $unit * 0.5 0;
  list-style: none;
  min-width: 300px;
  max-height: 75vh;
  overflow: auto;

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.separator {
  margin: $unit 0;
}

.requestButton {
  width: calc(100% - #{$unit * 4});
  margin: $unit * 1.5 $unit * 2;
}

.createButton {
  @include button-reset(false);
  @include inner-keyboard-focus;

  display: flex;
  align-items: center;
  padding: $unit * 1.5 $unit * 2;
  width: 100%;
  cursor: pointer;

  &:hover {
    @include cell-hover-background;
  }
}

.createIcon {
  @include border;

  margin-right: $unit * 1.5;
  border-radius: $unit * 0.5;
  height: $unit * 4;
  width: $unit * 4;
}

.createLabel {
  @include link-color;

  text-decoration: underline;
  text-align: left;
}

.contactSupportLink {
  @include link-inverse;
}
