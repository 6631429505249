@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.collectionInfo {
  @include secondary-content-background;

  border-radius: $unit * 1.5;
  padding: $unit * 2;
  margin-bottom: $unit;
  width: 100%;
}

.collectionName {
  @include font-ml;

  font-weight: $font-medium;
  margin-bottom: $unit * 0.5;
}

.projectName {
  @include font-m;

  margin-bottom: $unit * 0.5;
}

.collectionAuthor {
  @include secondary-text-color;
}

.authorAvatar {
  width: $unit * 2;
  height: $unit * 2;
}
