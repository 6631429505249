@import "../shared/vars";
@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/theme";

.empty {
  display: flex;
  flex-direction: column;
  max-width: 512px;
  padding: $unit * 2.5;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;

  img {
    margin: 0 auto;
    width: 120px;
    height: 120px;
    -webkit-user-drag: none;
  }

  h2 {
    @include text-color;

    margin-top: 0;
    margin-bottom: $unit;
    font-size: 18px;
    font-weight: $font-medium;
    text-align: center;
    word-break: break-word;
  }

  p {
    @include secondary-text-color;

    font-size: 14px;
    margin-top: 0;
    margin-bottom: $unit * 1.5;
  }

  .button,
  button {
    margin: 0 $unit * 0.5;
  }

  .buttonMargin {
    margin-top: $unit * 1.5;
  }
}

.icon {
  margin: -($unit);
}

.title {
  margin-bottom: $unit;
}

@include dark-theme {
  .empty {
    img {
      opacity: 0.5;
    }
  }
}

.description {
  @include secondary-text-color;

  font-size: 14px;
  margin-top: 0;
  margin-bottom: $unit * 1.5;

  a:not(.button) {
    @include link-color;

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.small {
  margin-bottom: $unit * 2;
  padding-bottom: 0;

  img,
  h2 {
    display: none;
  }

  .description {
    margin-top: $unit * 2;
    margin-bottom: 0;
  }

  .children {
    margin-top: $unit;
  }
}

.mini {
  padding: $unit * 0.5 $unit * 0.5 $unit * 2;

  img {
    width: $unit * 6;
    height: $unit * 6;
  }

  h2 {
    font-size: 14px;
  }

  .description {
    @include secondary-text-color;

    max-width: 240px;
    margin: 0 auto;
  }
}

.layerSyncing {
  background: $white-a50;

  h2 {
    margin-top: $unit * 2;
  }
}

@include dark-theme {
  .layerSyncing {
    background: $black-a50;
  }
}

.miniNoComments {
  padding: $unit * 1.25 $unit * 2 $unit * 1.5;
  height: auto !important;

  .description {
    padding-top: 6px;
  }
}

.miniNoCollections,
.miniNoResults {
  padding: 20px;
  margin-bottom: -20px;

  img {
    width: $unit * 9;
    height: $unit * 9;
    margin-bottom: $unit * 0.5;
  }
}

.fakeButton {
  @include border;

  overflow: hidden;
  white-space: nowrap;
  border-radius: 6px;
  height: $unit * 3;
  width: $unit * 3;
  margin-top: 2px;
  margin-bottom: -6px;
  margin-left: 2px;
  margin-right: 2px;
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-block;

  .fakeButtonIcon {
    margin-left: -1px;
  }
}

.projectImage {
  @include asset(create-project);

  width: 338px;
  height: 230px;
  background-position: center;
  background-size: contain;
  margin-bottom: $unit * 2.5;
}

.noSectionProjects {
  @include cell-hover-background;

  padding: $unit * 1.5 $unit * 3;
  text-align: center;
  border-radius: 6px;
}

.nonMacImage {
  @include asset(nonmac-platform);

  background-position: center;
  background-size: contain;
  width: 90vw;
  height: 30vh;
  max-width: 325px;
  max-height: 204px;
  margin: 0 auto $unit * 4.5 auto;
}
