@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.item {
  position: relative;
}

.actionedAt {
  color: $gray-50;
  text-align: right;

  @include font-s;
}

.wrap {
  flex-wrap: wrap;
}

.headingWrap {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonWrap {
  display: flex;
  margin-left: auto;
  margin-top: -$unit * 2;
}

@media screen and (min-width: $breakpoint-mobile) {
  .wrap {
    flex-wrap: nowrap;
  }

  .actionedAt {
    flex-shrink: 0;
    margin-left: $unit * 2;
  }

  .buttonWrap {
    margin-left: $unit * 2;
    margin-top: 0;
  }
}
