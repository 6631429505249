@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.section {
  @include border-bottom;
  @include content-background;
}

.top {
  padding: $unit * 2;

  @media screen and (min-width: $breakpoint-mobile) {
    padding: $unit * 3 $unit * 4;
  }
}

.header {
  margin-bottom: $unit * 2;
}

.actions,
.sha {
  float: right;
}

.actions {
  display: flex;
  margin-top: -2px;
  margin-left: $unit * 1.5;
}

.title,
.description {
  max-width: 50em;
  user-select: text;
  cursor: text;

  * {
    cursor: text;
  }
}

.title {
  display: inline;
}

.description {
  margin-bottom: $unit * 2;
}

.footer {
  @include font-s;
  @include secondary-text-color;
}

.footerItem,
.jumpButtonMobile {
  display: flex;
  align-items: center;
}

.jumpButtonMobile {
  .separator {
    margin-right: -$unit;
  }
}

.footerItemIcon {
  margin-right: $unit;
}

.footerItemJumpButton {
  display: none;
  margin-left: $unit;
}

.commentsLink {
  color: inherit;
  white-space: nowrap;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.dot {
  display: none;
}

.children {
  @include border-top;

  min-height: $unit * 12;
  padding: $unit $unit * 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

@media (min-width: $breakpoint-mobile) {
  .commentsLink {
    margin-left: $unit * 0.5;
  }

  .jumpButtonMobile {
    display: none;
  }

  .children {
    padding: $unit * 1.5 $unit * 4;
  }

  .footer {
    display: flex;
  }

  .footerItemUser {
    @include ellipsis;

    a {
      @include ellipsis;
    }
  }

  .dot {
    display: flex;
    align-items: center;
    margin: 0 $unit * 0.5;
  }

  .footerItemTime {
    @include ellipsis;
  }

  .footerItemComments {
    flex-grow: 1;
    justify-content: flex-end;
  }

  .footerItemIconComments {
    margin-right: 0;
  }

  .footerItemIconTime {
    display: none;
  }

  .footerItemJumpButton {
    display: flex;
  }
}
