@import "../../shared/colors";
@import "../../shared/typography";
@import "../../shared/mixins";
@import "../../shared/vars";
@import "../../shared/theme";

.row {
  @media screen and (min-width: $breakpoint-mobile) {
    padding: 0 $unit * 4;
  }
}

.gridRow {
  padding-left: $unit * 4;
  padding-right: $unit * 2;
}

.noProjects {
  @include quick-transition(background);

  margin-bottom: $unit * 1.5;

  @media screen and (max-width: $breakpoint-mobile) {
    @include inset-border-bottom;

    border-radius: 0;
  }
}

.showBottomBorder {
  @include border-bottom;
}

.list {
  &:hover + .list {
    .project::after {
      left: 0;
      right: 0;
    }
  }

  .project {
    @include content-background;
    @include border-left;
    @include border-right;
  }

  &.firstListRow {
    .project {
      @include border-top;

      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      &::after {
        border: none;
      }
    }
  }

  &.lastListRow {
    .project {
      @include border-bottom;

      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}

.mobile {
  .project {
    @include content-background;

    margin-top: -1px;
  }

  &.firstNonSectionRow {
    .project::after {
      border: none;
    }
  }

  &.lastListRow {
    .project {
      @include border-bottom;
    }
  }
}

.hoverable {
  @media screen and (min-width: $breakpoint-mobile) {
    &::after {
      @include quick-transition(background);

      background: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      right: $unit * 2.5;
      left: $unit * 2.5;
      content: "";
      z-index: -1;
    }
  }

  &:first-child::after {
    border-top-right-radius: $unit * 1.5;
    border-top-left-radius: $unit * 1.5;
  }

  &:last-child::after {
    border-bottom-right-radius: $unit * 1.5;
    border-bottom-left-radius: $unit * 1.5;
  }

  &.lastListRow::after {
    bottom: -$unit * 1.5;
  }
}

.section:hover {
  @include cell-hover-background;

  cursor: pointer;

  &::after,
  & ~ .hoverable::after {
    @include cell-hover-background;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    background: inherit;

    & + .row {
      .noProjects {
        @include secondary-content-background;
      }
    }
  }
}

@include dark-theme {
  .noProjects,
  .section:hover,
  .section:hover::after,
  .section:hover ~ .hoverable::after {
    background: $black-a25;
  }

  .section:hover {
    @media screen and (min-width: $breakpoint-mobile) {
      background: inherit;

      & + .row {
        .noProjects {
          @include cell-hover-background;
        }
      }
    }
  }
}
