.main {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.tab {
  position: relative;
  height: 100%;
}

.bannerContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.contents {
  flex-grow: 1;
  position: relative;
  height: 100%;
}
