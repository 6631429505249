@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/mixins";

.author {
  @include ellipsis;
  @include font-s;
  @include secondary-text-color;

  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.avatar {
  margin-right: $unit;
}

.date {
  @include font-s;
}

.dot {
  padding: 0 $unit * 0.5;
}
