@import "../shared/vars";
@import "../shared/theme";
@import "../shared/mixins";

.collapsible {
  background: $white !important;
  margin-bottom: $unit * 2 !important;
  flex-grow: 0 !important;
  min-height: $unit * 6 !important;
}

.header {
  margin-left: -$unit * 0.5;
}

.box {
  &.mobile {
    padding: 0 !important;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;

      @include border-bottom;
    }
  }

  &.collapsed {
    height: 0;
    padding: 0;
    overflow: hidden;
  }
}

.button {
  @include quick-transition(transform);

  margin-right: $unit;

  &.rotated {
    transform: rotate(-90deg);
  }
}

@include dark-theme {
  .collapsible {
    background: $gray-70 !important;
  }
}
