@import "../../shared/mixins";
@import "../../shared/theme";
@import "../../shared/vars";

.padding {
  padding: $unit * 2;
}

.noPadding {
  padding: 0;
}

.headingLink {
  @include text-color;
  @include outer-keyboard-focus;

  border-radius: 3px;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    @include text-color;

    text-decoration: underline;
  }
}
