@import "../../../shared/mixins";
@import "../../../shared/vars";

$childBranchPadding: $unit * 3;

.nestedLevel3 {
  padding-left: $childBranchPadding;
}

.nestedLevel4 {
  padding-left: $childBranchPadding * 2;
}

.nestedLevel5 {
  padding-left: $childBranchPadding * 3;
}

.nestedLevel6 {
  padding-left: $childBranchPadding * 4;
}

.nestedLevel7 {
  padding-left: $childBranchPadding * 5;
}

.nestedLevel8 {
  padding-left: $childBranchPadding * 6;
}

.progress {
  margin-right: $unit;
}

.icon {
  margin-left: -($unit * 0.5);
  margin-right: $unit * 0.5;
}

.avatar {
  margin: $unit * 0.5 $unit * 1.5 $unit * 0.5 0;
}

.branchName {
  @include ellipsis;

  flex-grow: 1;
  font-weight: $font-regular;
}

.branchStatus {
  flex-grow: 0;
  margin-right: $unit * -0.5;
  margin-left: $unit * 0.5;
}

.expandButton {
  padding: $unit * 0.5 $unit * 1.5 $unit * 0.5 $unit;
  border-radius: 5px;
}

.contextMenuRef {
  width: 1px;
}
