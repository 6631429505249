@import "../../shared/colors";
@import "../../shared/mixins";
@import "../../shared/typography";
@import "../../shared/vars";

.sidebar {
  margin-bottom: 2 * $unit;
}

.file {
  @include dynamic-padding(right left);

  margin-left: -$unit;
  margin-right: -$unit;
  padding-bottom: $unit * 0.5;
  padding-top: $unit * 0.5;
  width: calc(100% + #{$unit * 2});
  cursor: pointer;
}

.noChangedFiles {
  @include dynamic-padding(right left);
  @include secondary-text-color;

  padding-bottom: $unit * 3;
}
