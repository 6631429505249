@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";

$positioner-z-index: 2;
$collapse-control-z-index: $positioner-z-index + 1;
$collapsible-sidebar-collapsed-but-open-width: calc(
  var(--collapsible-sidebar-width) - var(--collapsible-sidebar-collapsed-width)
);

@include light-theme {
  // --sidebar-background: #e6e1e6; // window color in Electron prior v10
  --sidebar-background: #{$white};
}

@include dark-theme {
  --sidebar-background: #{$gray-70};
}

.outerContainer {
  position: relative;
  background-color: var(--sidebar-background);
}

.floatingItemsPositioner {
  position: relative;
  height: 100%;
  will-change: width;
  transition: width 200ms $super-ease-out;
  z-index: $positioner-z-index;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -1px;
    display: block;
    width: 2px;
    height: 100%;
    background: $blurple-60;
    opacity: 0;
    z-index: $collapse-control-z-index;
    pointer-events: none;
    will-change: opacity;
    transition: opacity 200ms $super-ease-out;
  }
}

.activeBorder {
  &::before {
    opacity: 1;
  }
}

.sidebar {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  will-change: width;
  transition: width 200ms $super-ease-out;

  &.opaque {
    @include secondary-content-background;
  }

  &::after {
    @include inset-border-right;

    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
}

.resizableWrapper {
  height: 100%;
  overflow: hidden;
}

.resizable {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  will-change: background-color;
  transition: background-color 200ms $super-ease-out;
}

.collapseButton {
  @include button-reset(false);
  @include content-background;

  display: flex;
  position: absolute;
  top: $unit * 3;
  right: $unit * -1.5;
  color: $blurple-60;
  border-radius: 6px;
  box-shadow:
    0 1px 2px $black-a10,
    0 0 1px $black-a15;
  opacity: 0;
  will-change: opacity, background-color;
  transition:
    opacity 200ms $super-ease-out,
    background-color 200ms $super-ease-out;
  z-index: $collapse-control-z-index;
  transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    color: $white;
    background: $blurple-60;
    opacity: 1;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -$unit;
    left: -$unit;
    width: $unit * 5;
    height: $unit * 5;
  }
}

@include dark-theme {
  .collapseButton {
    color: $white;
    box-shadow:
      0 0 0 1px $black,
      0 0 0 1px $white-a15 inset;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.outerContainer.isHoveringOverSidebar {
  .collapseButton {
    opacity: 1;
  }
}

.outerContainer.collapsed {
  width: var(--collapsible-sidebar-collapsed-width);

  .floatingItemsPositioner {
    width: var(--collapsible-sidebar-collapsed-width);
  }

  .sidebar {
    @include secondary-content-background;

    position: absolute;
    top: 0;
    left: 0;
    width: var(--collapsible-sidebar-collapsed-width);
  }

  .resizableWrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
  }
}

.outerContainer.collapsedButOpen {
  .floatingItemsPositioner {
    width: var(--collapsible-sidebar-width);
  }

  .sidebar {
    width: var(--collapsible-sidebar-width);
  }

  .resizableWrapper {
    display: block;
    width: var(--collapsible-sidebar-width);
  }

  .collapseButton {
    opacity: 1;
  }
}

.right {
  .floatingItemsPositioner {
    &::before {
      left: -1px;
    }
  }

  .sidebar {
    &::after {
      @include inset-border-left;
    }
  }

  .collapseButton {
    right: var(--collapsible-sidebar-width) - $unit * 3;
    left: $unit * -1.5;
  }
}

.right.collapsedButOpen {
  .floatingItemsPositioner,
  .sidebar {
    @keyframes slide-in {
      from {
        right: 0;
      }

      to {
        right: $collapsible-sidebar-collapsed-but-open-width;
      }
    }

    animation: slide-in 200ms $super-ease-out;
    width: var(--collapsible-sidebar-width);
    right: $collapsible-sidebar-collapsed-but-open-width;
  }

  .sidebar {
    width: var(--collapsible-sidebar-width);
    right: $collapsible-sidebar-collapsed-but-open-width;
  }
}
