@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr max-content;
  align-items: end;

  // Specific selector to take precedence over Input.text
  .input.focused {
    @include box-shadow-focus;
    @include input-background;

    &.disabled {
      @include box-shadow;
      @include input-background-disabled;
      @include placeholder-text-color;
    }
  }

  &.focus {
    @include border-focus;
  }

  .input {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.disabled {
  background-color: $black-a05;
}

.flat {
  flex-wrap: nowrap;

  .inputWrapper,
  .actions {
    width: auto;
  }

  .inputWrapper {
    min-width: 0;
  }

  .input {
    padding: $unit * 2 0;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  &.disabled {
    @include border-color;
    @include input-background-disabled;
    @include placeholder-text-color;

    cursor: default;
  }
}

@include dark-theme {
  .flat {
    .input {
      box-shadow: none;
    }

    &.focus {
      .input {
        background: none;
        box-shadow: none;
      }
    }
  }
}

.collapsible {
  .actions {
    height: auto;
    margin-top: $unit * 1.5;
  }
}

.collapsed {
  .input,
  .inputWrapper {
    position: relative;
    min-height: 0;
    height: $unit * 4;
  }

  .input {
    overflow-y: hidden !important;
  }

  .actions {
    display: none;
  }

  .annotation {
    position: absolute;
    right: $unit * 0.5;
    top: $unit * 0.5;
    z-index: 1;
  }
}

@include dark-theme {
  .flat {
    &.focus {
      .input {
        background: none;
      }
    }
  }
}

.input {
  padding: $unit * 0.5 $unit;
  overflow-y: auto !important;
  max-height: 40vw;
  min-height: $unit * 6;
}

.inputWrapper {
  cursor: text;
  max-width: 100%;
  grid-row: 2;
  grid-column: 1 / span 2;
}

.inputWrapperAlignedWithActions {
  grid-column: 1;
}

.actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  grid-row: 3;
  grid-column: 1;
  position: relative;
  height: $unit * 6;
  width: 100%;

  @media screen and (min-width: $breakpoint-mobile) {
    flex-grow: 1;
  }

  button:not(.cancel) {
    margin-left: $unit;
  }

  .cancel {
    margin-right: $unit;
  }

  .annotation {
    order: 2;
    display: inline-flex; // to avoid line-height

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    button {
      margin-left: -0.25 * $unit;
      margin-right: $unit;
    }
  }

  .annotationBubble {
    order: 2;
  }
}

.actionsInsideForm {
  grid-row: 2;
  grid-column: 2;
}

.indicator {
  @include font-s;

  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: start;
  grid-column: 1 / -1;
  grid-row: 1;
  margin-top: -$unit * 0.5;
  margin-bottom: $unit;
  margin-left: -6px;
  margin-right: -6px;
  width: 100%;
  color: $black-a50;
}

.indicatorIcon {
  height: $unit * 2.5; // The line-height of .indicatorText + its top margin
}

.indicatorText {
  @include secondary-text-color;

  margin-top: 2px; // to align with top of icon
}

.inline {
  .actions {
    width: auto;
  }
}

.submitComment {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: $unit;
  grid-column: 1;
  grid-row: 2;
}
