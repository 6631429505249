@import "../../components/shared/colors";
@import "../../components/shared/vars";
@import "../../components/shared/mixins";
@import "../../components/shared/theme";

.dateHeader {
  font-weight: $font-medium;
  text-transform: uppercase;
}

.activityTitle {
  @include dynamic-padding(right left);

  margin-bottom: 12px;

  @media (min-width: $breakpoint-mobile) {
    margin-bottom: $unit * 2;
  }
}

.spinner {
  @include dynamic-padding;

  flex-shrink: 0;
  margin-bottom: $unit * 2;
  height: $unit * 8 !important;
}

.form,
.list {
  margin: 0 auto;
  width: 100%;
}

.form {
  @include input-background;
  @include border;

  padding: 0 $unit * 2;
  border-radius: 12px;

  &:focus,
  &.focus {
    @include border-focus;
  }

  &.disabled {
    @include border-color;
    @include input-background-disabled;
    @include placeholder-text-color;
  }
}

.formWrap {
  @include dynamic-padding(right left bottom);
}

.list {
  @include dynamic-padding(right left bottom);

  flex-grow: 1;
  margin: auto;
}
