@import "../shared/vars";

.files {
  margin-bottom: $unit * 2;

  > li {
    padding-top: $unit;
    padding-bottom: $unit;
  }
}
