@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.center {
  text-align: center;
}

.backLink {
  display: inline-block;
  margin-top: $unit * 2;
}

.grey {
  @include font-s;

  color: $gray-50;
}

.greyLink {
  @include font-s;

  color: $gray-50;

  &:hover {
    color: darken($gray-50, 10%);
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .signIn {
    max-width: none;
  }
}

@include media(desktop) {
  .withArrows {
    position: relative;

    &::after {
      @include asset(onboarding-arrows);

      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -137px;
      margin: auto;
      margin-right: -$unit * 3.5;
      background-position: center;
      background-size: contain;
      width: 137px;
      height: 147px;
      pointer-events: none;
    }
  }
}
