@import "../../shared/mixins";

.welcomeCard {
  @include secondary-content-background;

  display: block;
  border-radius: 6px;
  margin-bottom: $unit * 2;
  padding: $unit * 2;
}

.stickers {
  @include asset(branch-stickers);

  width: 400px;
  max-width: 90%;
  height: 100px;
  margin-bottom: $unit * 2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.welcomeMessage {
  margin-top: $unit * 2;
  text-align: center;
}
