@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/theme";

.rowContainer {
  transform: translate3d(0, 0, 0);

  &:hover + .rowContainer .listRow::after {
    left: 0;
    right: 0;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .rowContainer {
    @include dynamic-padding(left right);
  }
}

.listRow {
  @include border-right;
  @include border-left;
  @include content-background;

  overflow: hidden;
}

.firstRow {
  @include border-top;

  border-top-right-radius: $unit;
  border-top-left-radius: $unit;

  &::after {
    border-bottom: none;
  }
}

.lastRow {
  @include border-bottom;

  border-bottom-right-radius: $unit;
  border-bottom-left-radius: $unit;
}

.mobileListRow {
  border: 0;
  border-radius: 0;
}

.waypoint {
  position: absolute;
  bottom: 0;
  width: 100%;
}
