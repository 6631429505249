@import "../shared/vars";
@import "../shared/mixins";

.badges {
  display: inline-flex;
  max-width: 100%;
  flex-shrink: 0;
}

.badge {
  flex: 0 0 auto;
  align-items: center;
  display: flex;

  & + & {
    margin-left: $unit * 0.5;
  }
}
