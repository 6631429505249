@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/animations";
@import "../shared/theme";

$select-menu-max-height: 144px;

.option,
.option * {
  cursor: pointer;
}

.option:first-child,
.option:last-child {
  border-radius: 0;
}

.spinner {
  position: absolute;
  right: $unit;
  top: $unit;
}

.wrapper {
  // NOTE - Overrides for react-select
  :global .Select_container {
    .Select__control {
      @include box-shadow;

      border-radius: 8px;
      border: none;
      color: $gray-70;

      @include input-background;

      height: $unit * 4;
    }

    .Select__control--is-focused {
      @include box-shadow-focus;

      outline: 0;
    }

    .Select__control--is-disabled {
      background-color: $gray-10;

      &:hover {
        @include box-shadow;
      }
    }

    .Select__menu {
      @include content-background;

      :local {
        @include scale-in-down;
      }

      overflow: hidden;
      border-radius: $unit * 0.5;
      font-size: 13px;
      border: none;
      position: absolute !important;
      top: $unit * 4.4;
      z-index: 5;
      box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        0 4px 8px 0 rgba(0, 0, 0, 0.1);
      max-height: $select-menu-max-height;

      .Select__menu-list {
        padding-top: $unit;
        padding-bottom: $unit;
        max-height: ($select-menu-max-height - 2px);
      }
    }

    // hide the built-in spinner so we can display our own
    .Select__indicators {
      display: none;
    }
  }
}

@include dark-theme {
  .wrapper {
    // NOTE - Overrides for react-select
    :global .Select_container {
      .Select__control--is-focused {
        @include box-shadow-focus;
      }

      .Select__control--is-disabled {
        opacity: 0.5;
      }

      .Select__control {
        @include input-background;
        @include text-color;
        @include box-shadow;

        input {
          @include text-color;
        }
      }

      .Select__placeholder {
        @include placeholder-text-color;
      }

      .Select__menu {
        @include modal-outline;
      }
    }
  }
}
