@import "../shared/vars";
@import "../shared/theme";

.fullScreen {
  @include content-background;

  height: 100%;
  width: 100%;
}

.successText {
  @include secondary-text-color;
}
