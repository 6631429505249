@import "../shared/vars";

.pie {
  width: $unit * 3;
  height: $unit * 3;
  position: relative;
  color: $gray-30;

  img,
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.allgood {
  color: $green-50;
}
