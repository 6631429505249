@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.title {
  @media screen and (min-width: $breakpoint-mobile) {
    margin-left: -$unit * 0.5;
  }
}

.header {
  flex-direction: column;
  align-items: flex-start;
}
