@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.organizationCardWrapper {
  width: 100%;
  max-width: 480px;
  margin-bottom: $unit * 2;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.organizationCard {
  width: 100%;
  margin: 0 auto;
}

.organizationCardInner {
  padding: $unit * 2;
}

.organizationLogo {
  flex-grow: 0;
  flex-shrink: 0;
}

.organizationName {
  @include ellipsis;

  flex-grow: 1;
  margin-left: $unit * 2;
}

.requestAccess {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: $unit * 2;
}
