@import "../shared/mixins";
@import "../shared/colors";

.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.placeholder,
.preview {
  @include quick-transition(opacity, 75ms);

  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.preview {
  opacity: 0;
}

.noAnimation {
  transition: none;
}

.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.cover {
  background-size: cover;

  &.smallerThanContainer {
    background-size: initial;
  }
}

.errorText {
  color: $gray-60;
  text-align: center;
  padding: 8px;
}
