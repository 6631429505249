@import "~@elasticprojects/abstract-core/src/components/shared/theme";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.note {
  margin-top: $unit * 0.5;
  font-weight: $font-regular;
}

.disableSSOModal {
  max-width: 600px;
}

.disableSSOModalContent {
  padding: 0 $unit * 6 18px;
}

.disableSSOModalButtons {
  box-shadow: 0 -1px 0 0 var(--border-color);
  padding: $unit * 2;
}

.disableSSOModalCancelButton {
  margin-right: $unit * 2;
}

.ssoActivationError {
  padding-top: 0;
}

.ssoInfoIcon {
  margin-left: $unit * -0.5;
}

.ssoInfoText {
  margin-left: $unit;

  @media screen and (min-width: $breakpoint-mobile) {
    margin-left: $unit * 0.5;
  }
}

.ssoUnavailable {
  padding: $unit * 6;
}

.submit {
  justify-content: flex-end;
}

.testButton {
  margin-bottom: $unit * 2;
}
