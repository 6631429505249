@import "~@elasticprojects/abstract-core/src/components/shared/colors";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";
@import "~@elasticprojects/abstract-core/src/components/shared/typography";
@import "~@elasticprojects/abstract-core/src/components/shared/vars";

.content {
  min-width: 0;
  max-width: 800px;
}

.skipLink {
  margin-top: $unit;
}

.options {
  margin-top: $unit * 4;
}

.joinSheet {
  text-align: center;
  padding: $unit * 5;
}

.createSheet {
  margin-left: $unit * 4;
  text-align: center;
  padding: $unit * 5;
}

.illustration {
  width: 120px;
  height: 120px;
  background-size: contain;
  background-position: center;
}

.puzzle {
  @include asset(puzzle);
}

.plus {
  @include asset(plus);
}

.optionHeading {
  margin-top: $unit * 2;
}

.sheetCopy {
  margin-top: $unit * 2;
}

.button {
  align-self: stretch;
  margin: $unit * 2 $unit * 4 0;
}

.trialBadge {
  @include font-m;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $unit * 4;
  color: $blurple-60;
  background: $blurple-20;
  border-radius: $unit * 2;
  padding: 0 $unit * 1.5;
  margin-top: $unit * 3;
  text-transform: uppercase;
}

@include media(mobile) {
  .content {
    margin-bottom: $unit * 4;
  }

  .topCopy {
    margin: 0 $unit * 2;
  }

  .options {
    width: 100%;
    margin-right: $unit * 2;
  }

  .createSheet {
    margin-top: $unit * 3;
    margin-left: 0;
  }

  .illustration {
    width: 64px;
    height: 64px;
  }

  .button {
    align-self: auto;
  }
}
