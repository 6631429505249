@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";

.wrapper {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 0;
  overflow: hidden;
}

.inner {
  display: flex;
  position: relative;
  padding: $unit 0 $unit $unit * 6;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.group,
.meta {
  @include text-color;

  display: flex;
  align-items: center;
  min-width: 0;
  text-decoration: none;
}

.meta {
  flex-shrink: 0;
}

.time {
  @include font-s;
  @include secondary-text-color;

  margin-left: $unit * 1.5;
}

.heading {
  @include ellipsis;

  font-weight: $font-regular;
  margin-bottom: 0;
  padding-right: 2px; // prevents cropping of emoji
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &:hover {
    text-decoration: underline;
  }
}

.commitTrack {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
