@import "../shared/colors";
@import "../shared/vars";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/theme";

.container {
  @include border-bottom;

  padding-bottom: $unit * 2;
  margin-bottom: $unit * 2;

  @media (min-width: $breakpoint-mobile) {
    padding-bottom: $unit * 3;
    margin-bottom: $unit * 3;
  }
}

.descriptionTitle {
  @include font-ml;

  font-weight: $font-medium;
}

.descriptionHeader {
  margin-bottom: $unit;
  align-items: end;

  @media (min-width: $breakpoint-mobile) {
    margin-bottom: $unit * 2;
  }
}

.description {
  @include text-color;
  @include font-m;
}

.branchedFrom {
  text-align: left;
  margin: $unit * 2 0 0 0;
}

.noDescription {
  @include placeholder-text-color;
}

.buttonRow {
  .button + .button {
    margin-left: $unit;
  }
}

.focusedDescription {
  @include box-shadow-focus;
}

@include dark-theme {
  .focusedDescription {
    @include box-shadow-focus;
    @include input-background;
  }
}
