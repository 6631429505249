@import "../shared/animations";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";

.badge {
  @include fade-in;
  @include font-xs;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $red-50;
  font-weight: $font-medium;
  border-radius: $unit * 2;
  padding: 0 $unit * 0.5;
  color: $white;
  cursor: default;
  min-width: $unit * 2;
}
