@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";
@import "../shared/theme";

.section {
  @include dynamic-padding(right left);

  padding-top: $unit * 0.5;
  padding-bottom: $unit * 0.5;
}

.collectionItem {
  @include dynamic-padding(right left);

  padding-left: $unit;
  padding-top: $unit * 0.5;
  padding-bottom: 2 * $unit;

  &:hover {
    .collectionItemTitle {
      text-decoration: underline;
    }
  }
}

.empty {
  @include dynamic-padding(left right);
  @include secondary-text-color;
}

.sidebar {
  overflow-x: auto;
}
