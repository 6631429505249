@import "../shared/mixins";
@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";

.item {
  padding: $unit $unit * 2 !important;

  &:last-child {
    margin-bottom: $unit;
  }
}

.search {
  padding: $unit * 2;
}

.emptyExplanation {
  padding: $unit * 2;
  padding-top: 0;
}

.searchTerm {
  font-weight: $font-medium;
}

.hasSelectedItem {
  .search {
    padding-bottom: $unit;
  }

  .item {
    &:first-child {
      margin-top: $unit;
    }
  }

  .emptyExplanation {
    padding-top: $unit;
  }
}

.selectorContainer {
  width: $unit * 40;
  max-height: 400px;
  overflow: hidden;
}

.itemExtraInfo {
  @include font-s;
  @include secondary-text-color;

  padding-left: $unit * 0.5;
}

.menuItemName {
  @include ellipsis;
}

.disclaimer {
  @include font-s;

  font-weight: $font-regular;
  text-align: left;
  padding: $unit $unit * 2;
  border-radius: $unit * 0.5;
  color: $gray-60;
  background-color: $gray-10;
  margin: $unit $unit * 2;
  margin-bottom: $unit * 2;
}

@include dark-theme {
  .disclaimer {
    @include secondary-content-background;
    @include secondary-text-color;
  }
}

.divider {
  width: 95%;
  margin-bottom: 0 !important;
  margin-top: $unit !important;
}

.closeIcon {
  display: none;
}

.mobileDeselectableItem {
  .closeIcon {
    display: inherit;
  }

  .checkmarkIcon {
    display: none;
  }
}

@media (min-width: $breakpoint-mobile) {
  .deselectableItem {
    &:hover {
      .closeIcon {
        display: inherit;
      }

      .checkmarkIcon {
        display: none;
      }
    }
  }
}
