@import "../shared/vars";
@import "../shared/theme";

.callout {
  font-weight: $font-medium;

  @include text-color;
}

.linkStyle {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}
