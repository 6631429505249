@import "../../shared/mixins";
@import "../../shared/typography";
@import "../../shared/vars";

.placeholder {
  padding: $unit $unit * 1.5 $unit $unit;
}

.colorPlaceholder {
  width: $unit * 3;
  height: $unit * 3;
  margin-right: $unit;
}

.projectName {
  @include ellipsis;
  @include display-l;
}
