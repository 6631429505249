@import "../../shared/vars";
@import "../../shared/colors";
@import "../../shared/theme";
@import "../../shared/mixins";

.container {
  &:hover {
    .gripIcon,
    .shareLink {
      opacity: 1;
    }
  }
}

.containerInnerFocused {
  .gripIcon,
  .shareLink {
    opacity: 1;
  }
}

.inputWrapper {
  margin: 0;
}

.maxWidth {
  position: relative;
  max-width: 652px; // 676px - left and right offset of ($unit * 1.5)
  width: 100%;
}

.input {
  height: auto;
  width: 100%;
  border-radius: $unit;
  box-shadow: none !important; // For Input.style > dark-theme .text
  padding: $unit $unit * 2 $unit 0;
  margin-top: 0;
  outline: none;
  line-height: 20px;
}

.editable {
  position: relative;

  &::before {
    @include secondary-content-background;
    @include quick-transition(background-color, 200ms);

    content: "";
    position: absolute;
    top: 0;
    left: -$unit * 1.5;
    right: -$unit * 1.5;
    bottom: 0;
    border-radius: $unit;
    opacity: 0;
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }
  }
}

.forceShowBackground {
  .editable {
    &::before {
      opacity: 1;
    }
  }
}

.focused {
  &::before {
    opacity: 1;
  }
}

.large {
  min-height: 56px;
}

.hidden {
  display: none;
}

.noPointer {
  pointer-events: none;
  touch-action: none;
}

.showDragHandle {
  &::before {
    left: -$unit * 4;
  }
}

.gripIcon {
  position: absolute;
  top: $unit * 1.25;
  left: -$unit * 3;
  z-index: 1;
  cursor: grab;
  opacity: 0;
}

.shareLink {
  position: absolute;
  top: $unit * 0.5;
  right: -$unit;
  z-index: 1;
  opacity: 0;

  @media (max-width: $breakpoint-tablet) {
    opacity: 1;
  }
}

.textMarkdown {
  position: relative;
  z-index: 1;
}
