@import "../shared/vars";
@import "../shared/colors";
@import "../shared/typography";
@import "../shared/theme";

.scrollWrapper {
  overflow-x: auto;
  text-align: center;
  width: 100%;
  // VirtualizedList styles add an extra margin to all elements for isList.
  // This negative margin is a workaround to display the filters at fullwidth.
  &.listFilter {
    margin: 0 $unit * -4;
  }
}

.filters {
  padding: 0 $unit * 4;
  white-space: nowrap;
  overflow-y: hide;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  display: inline-flex;
  flex-wrap: nowrap;
  height: $unit * 10;
  align-items: center;
}

.divider {
  height: $unit * 3;
  width: 0;
  margin: 0 $unit * 3;
  border-right: solid 1px $black-a10;
}

.label {
  @include font-xs;

  color: $gray-60;
  margin-right: $unit * 2;
  flex-shrink: 0;
}

.link {
  & + & {
    margin-left: $unit * 0.5;
  }
}

@media (max-width: $breakpoint-mobile) {
  .filters {
    padding: 0 $unit;
    height: $unit * 7;
  }
}

@include dark-theme {
  .label {
    color: $gray-50;
  }

  .divider {
    border-right: solid 1px $white-a10;
  }
}
