@import "~@elasticprojects/abstract-core/src/components/shared/vars";
@import "~@elasticprojects/abstract-core/src/components/shared/mixins";

.headerLink,
.headerLink:hover {
  color: inherit;
  text-decoration: none;
}

.text {
  @include ellipsis;
}

.textContainer {
  @include flex;

  max-width: 300px;

  button {
    margin-right: 25px;
  }
}

.deleteDialogMessage {
  margin-bottom: $unit * 3 - 7px; // -7px for input helper padding-top
}

.section {
  padding: 0 $unit * 4 $unit * 3;
  max-width: $max-form-width;
}

.pullRight {
  margin-left: auto;
}

.showPassword {
  position: absolute;
  width: 24px;
  height: 24px;
}
