@import "../shared/colors";
@import "../shared/mixins";
@import "../shared/typography";
@import "../shared/vars";

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  margin-bottom: $unit;
  margin-right: -$unit * 2;
}

.thumbnail {
  padding-right: $unit * 2;
  padding-bottom: $unit * 2;
  width: 50%;

  @media (min-width: 900px) {
    width: 33.3%;
  }

  @media (min-width: 1500px) {
    width: 25%;
  }

  @media (min-width: 1900px) {
    width: 20%;
  }

  @media screen and (min-width: 900px) {
    .zoom1 & {
      width: 25%;
    }

    .zoom2 & {
      width: 33.3%;
    }

    .zoom3 & {
      width: 50%;
    }

    .zoom4 & {
      width: 100%;
    }
  }

  @media screen and (min-width: 1500px) {
    .zoom1 & {
      width: 20%;
    }

    .zoom2 & {
      width: 25%;
    }

    .zoom3 & {
      width: 33.3%;
    }

    .zoom4 & {
      width: 50%;
    }
  }
}
