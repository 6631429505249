@import "../shared/colors";
@import "../shared/vars";
@import "../shared/typography";
@import "../shared/theme";
@import "../shared/mixins";

.descriptionHeader {
  display: flex;
  margin-bottom: $unit;
}

.descriptionLabel {
  @include text-color;
  @include font-m;

  flex: 1;
  font-weight: $font-medium;
}

.componentWrapper {
  margin-bottom: $unit * 2;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

// MarkdownPreviewHelper
.container {
  display: inline-flex;
  align-items: center;
}

.markdownIcon {
  margin-right: $unit * 1.5;
}

.button {
  @include font-xs;
  @include secondary-text-color;

  text-transform: uppercase;
  cursor: pointer;

  &.active {
    @include text-color;
  }

  & + & {
    margin-left: $unit * 2;
  }
}
